<div align="right">
  <p><img src="/assets/images/unit-2/conclusion.gif" alt="" height="37" width="189" align="left" border="0"><a href="u_2_7.html" target="_blank">Printable Page</a></p>
</div>
<h3><br>
  Threads of History</h3>
<p>A calico printer in England by the name of John Mercer discovered mercerization in 1844.<sup>11</sup> While printing he noticed that the cotton filter cloth became stronger, more lustrous and more absorbent after caustic soda in a hydroxide solution was filtered through the cloth during the dyeing process.<sup>9</sup> Mercer patented his discovery in 1850 but never realized a profit because of the high cost of caustic soda.<sup>12</sup> Mercerization also caused fabrics to shrink, which resulted in a 20 to 25 percent yardage loss. However, in 1897, H. Lowe of England discovered that tension applied during the mercerization process could reduce the shrinkage of the fabric while increasing the luster and silkiness.<sup>9</sup></p>
<p>The process of mercerization consists of applying the yarn or fabric with a
cold, strong solution of sodium hydroxide while applying tension for ten minutes
and then rinsing off the alkali residue.<sup>1</sup> The hydroxide solution
causes the fibers to swell. The swollen fibers gain a rounder diameter and smoother
surface. The smoother fiber surface reflects more light, resulting in a higher
luster.<sup>4</sup> Mercerization also greatly increases the strength and absorbency
of the fabric. </p>
<p>Although mercerization is not limited to cotton, many familiar cotton wovens
are mercerized, such as poplins, oxford shirtings, madras, broadcloth and marquisette.<sup>11</sup>
Today, mercerization is widely used because it is a relatively inexpensive and
permanent means of enhancing the luster, absorption and strength of fabrics
.<sup>2</sup>&nbsp;</p>
<h3>Summary Questions</h3>
<ol>
  <li>Why is a fiber with a high score on a fiber property not necessarily the best fiber for all applications?
  <li>What applications would be most affected by the fact that some fibers show differences in their tensile strength when they are wet verses when they are dry?
  <li>What applications would require the greatest degree of colorfastness in textiles? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <li>For what applications would a fabric that is dimensionally stable in one direction, but dimensionally unstable in another, still be suitable?&nbsp;
  <li>In general, do natural fibers or man-made fibers possess greater absorbency properties?
  <li>While in a store, how might one test to see if a product is made from resilient fibers?
  <li>What trade-offs might a product developer need to make when combining properties of a given fabric?</ol>
