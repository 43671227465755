<div align="right">
  <p><img src="/assets/images/unit-9/cleaning.gif" alt="" height="37" width="162" align="left" border="0"><a href="u_9_2.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  As textiles are used, or merely exposed to the elements, they will soil, become dingy, take on odors, become a haven for insects and bacteria, and ultimately, begin to deteriorate. Their beauty and luster can be restored by cleaning. Laundering and dry cleaning provide general cleaning for portable items.  However, special cleaning techniques are required for larger or permanently installed items. Special stain removal processes may be required for stains, spots or excessive soiling. Many fabrics will also require ironing, pressing or steaming to reduce the wrinkling effect caused by general use or cleaning. </p>
<h3>Stain Removal</h3>
<p>Stains, spots and heavy soiling are best treated if the fiber content of the product and the source of the stain are known and if the soiling is treated as soon as possible with appropriate measures. Most stains should be removed prior to thorough cleaning of the product. A variety of special cleaning agents have been developed that can be used on certain fibers for certain types of stains. Some spots or stains require agitation or scraping to remove ground-in or sticky substances. Warm water soaking or washing may also be used to effectively remove water soluble stains. However, as much as these methods may remove the stain from one fabric, they may severely damage other fabrics. A number of stain removal guides and charts are available which are helpful for identifying procedures for removing specific stains on washable and non-washable fabrics. The following stain removal chart is supplied by the Soaps and Detergents Association and will be helpful in determining appropriate measures for removing stains in washable fabrics.</p>
<table border=1 width=95% cellspacing="0" cellpadding=5>
  <tr>
    <th>STAIN</th>
    <th>TREATMENT</th>
  </tr>
  <tr>
    <td valign=top>Adhesive tape, chewing gum, rubber cement</td>
    <td valign=top>
      <ol>
        <li>Apply ice or cold water to harden surface; scrape with a dull knife.
        <li>Saturate with prewash stain remover or cleaning fluid.
        <li>Rinse, then launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Baby formula</td>
    <td valign=top>
      <ol>
        <li>Pretreat or soak stains using a product containing enzymes.
        <li>Soak for at least 30 minutes or several hours for aged stains
        <li>Launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Beverages (coffee, tea, soft drinks, wine, alcoholic beverages)</td>
    <td valign=top>
      <ol>
        <li>Sponge or soak stain in cool water.
        <li>Pretreat with prewash stain remover, liquid laundry detergent, liquid detergent booster or paste of powder laundry product and water.
        <li>Launder using sodium hypochlorite bleach, if safe for fabric, or oxygen bleach.
      </ol>
      <p><b>NOTE:</b> Older stains may respond to pretreating or soaking in a product containing enzymes, then laundering.</p>
    </td>
  </tr>
  <tr>
    <td valign=top>Blood</td>
    <td valign=top><i>Fresh Stains</i>
      <blockquote>
        <p>Soak in cold water (do not use hot water as it will set blood stains). Launder.</p>
      </blockquote>
      <p><i>Dried Stains</i></p>
      <blockquote>
        <p>Pretreat or soak in warm water with a product containing enzymes. Launder.</p>
      </blockquote>
      <p><b>NOTE:</b> If stain remains, rewash using a bleach safe for fabric.</p>
    </td>
  </tr>
  <tr>
    <td valign=top>Bodily fluids</td>
    <td valign=top>
      <ol>
        <li>Pretreat or soak in a product containing enzymes.
        <li>Launder using sodium hypochlorite bleach, if safe for fabric, or oxygen bleach.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Brown or yellow discoloration from iron, rust, manganese</td>
    <td valign=top>
      <blockquote>
        Use a rust remover recommended for fabrics; launder.</blockquote>
      <p><b>NOTE:</b> Do <b>not</b> use a sodium hypochlorite bleach to remove rust stains because it may intensify discoloration</p>
    </td>
  </tr>
  <tr>
    <td valign=top>Candle wax</td>
    <td valign=top>
      <ol>
        <li>Scrape off surface wax with a dull knife.
        <li>Place stain between clean paper towels and press with a warm iron. Replace paper towels frequently to absorb more wax and to avoid transferring stains.
        <li>Place stain facedown on clean paper towels. Sponge remaining stain with prewash stain remover or cleaning fluid; blot with paper towels. Let dry.
        <li>Launder.
      </ol>
      <p><b>NOTE:</b> If any color remains, rewash using sodium hypochlorite bleach, if safe for fabric, or oxygen bleach.</p>
    </td>
  </tr>
  <tr>
    <td valign=top>Chocolate</td>
    <td valign=top>
      <ol>
        <li>Pretreat or prewash in warm water with a product containing enzymes or treat with a prewash stain remover.
        <li>Launder.
      </ol>
      <p><b>NOTE:</b> If stain remains, rewash using a bleach safe for fabric.</p>
    </td>
  </tr>
  <tr>
    <td valign=top>Collar, cuff soil</td>
    <td valign=top>
      <ol>
        <li>Pretreat with prewash stain remover, liquid laundry detergent or paste of powder detergent and water.
        <li>Launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Cosmetics</td>
    <td valign=top>
      <ol>
        <li>Pretreat with prewash stain remover, liquid laundry detergent, paste of powder detergent or laundry additive and water or rub with bar soap.
        <li>Launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Crayon</td>
    <td valign=top>
      <p><i>For a Few Spots</i>:</p>
      <ol>
        <li>Treat the same as candle wax or dampen the stain and rub with bar soap,
        <li>Launder using hottest water safe for fabric.
      </ol>
      <p><i>For a Whole Load of Clothes</i>:</p>
      <ol>
        <li>Wash with hot water using a laundry soap and 1 cup (212 g) baking soda.
      </ol>
      <p><b>NOTE:</b> If color remains, launder using sodium hypochlorite bleach, if safe for fabric. Otherwise, pretreat or soak in a product containing enzymes or an oxygen bleach using hottest water safe for fabric, then launder.</p>
    </td>
  </tr>
  <tr>
    <td valign=top>Dairy products</td>
    <td valign=top>
      <ol>
        <li>Pretreat or soak stains using a product containing enzymes.
        <li>Soak for at least 30 minutes or several hours for aged stains.
        <li>Launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Deodorants, antiperspirants</td>
    <td valign=top><i>Light Stains</i>:
      <ol>
        <li>Pretreat with liquid laundry detergent. Launder.
      </ol>
      <p><i>Heavy Stains</i>:</p>
      <ol>
        <li>Pretreat with prewash stain remover. Allow to stand for 5 to 10 minutes.
        <li>Launder using an oxygen bleach.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Dye transfer</td>
    <td valign=top>
      <ol>
        <li>Attempt restoration of white fabrics that have picked up color from other fabrics by using a packaged color remover, following label directions.
        <li>Launder.
      </ol>
      <p><b>NOTE:</b> If dye remains, launder again using sodium hypochlorite bleach, if safe for fabric. For non-colorfast fabrics, soak in oxygen bleach, then launder.</p>
      <p><b>NOTE:</b> This type of stain may be prevented if proper sorting and laundering procedures are followed.</p>
    </td>
  </tr>
  <tr>
    <td valign=top>Egg</td>
    <td valign=top>
      <ol>
        <li>Pretreat or soak stains using a product containing enzymes.
        <li>Soak for at least 30 minutes or several hours for aged stains.
        <li>Launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Fabric softener</td>
    <td valign=top>
      <ol>
        <li>Dampen the stain and rub with bar soap.
        <li>Rinse out, then launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Fruit, juices</td>
    <td valign=top>
      <ol>
        <li>Wash with bleach safe for fabric.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Grass</td>
    <td valign=top>
      <ol>
        <li>Pretreat or soak in a product containing enzymes.
      </ol>
      <p><b>NOTE:</b> If stain persists, launder using sodium hypochlorite bleach, if safe for fabric, or oxygen bleach.</p>
    </td>
  </tr>
  <tr>
    <td valign=top>Grease, oil</td>
    <td valign=top><i>Light Stains</i>:
      <ol>
        <li>Pretreat with prewash stain remover, liquid laundry detergent or liquid detergent booster.
        <li>Launder using hottest water safe for fabric.
      </ol>
      <p><i>Heavy Stains</i>:</p>
      <ol>
        <li>Place stain facedown on clean paper towels. Apply cleaning fluid to back of stain.
        <li>Replace paper towels under stain frequently.
        <li>Let dry, rinse. Launder using hottest water safe for fabric.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Ink</td>
    <td valign=top><b>NOTE:</b> Some inks in each of the following categories&#151;ballpoint, felt tip, liquid&#151;may be impossible to remove, Laundering may set some types of ink. Try pretreating using one of the following methods:
      <p><i>Prewash Stain Remover</i>:</p>
      <ol>
        <li>Pretreat using a prewash stain remover. Launder.
      </ol>
      <p><i>Denatured Alcohol or Cleaning Fluid</i>:</p>
      <ol>
        <li>Sponge the area around the stain with the alcohol or cleaning fluid before applying it directly on the stain.
        <li>Place stain facedown on clean paper towels. Apply alcohol or cleaning fluid to back of stain. Replace paper towels frequently.
        <li>Rinse thoroughly. Launder.
      </ol>
      <p><i>Alternate Method for Denatured Alcohol or Cleaning Fluid</i>:</p>
      <ol>
        <li>Place stain over mouth of a jar or glass; hold fabric taut.
        <li>Drip the alcohol or cleaning fluid through the stain so ink will drop into the container as it is being removed.
        <li>Rinse thoroughly. Launder.

      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Mildew</td>
    <td valign=top><b>NOTE:</b> Badly mildewed fabrics may be damaged beyond repair.
      <ol>
        <li>Launder stained items using a bleach safe for fabric and hottest water recommended for fabric.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Mud</td>
    <td valign=top>
      <ol>
        <li>When dry, brush off as much mud as possible.
      </ol>
      <p><i>Light Stains</i>:</p>
      <ol>
        <li>Pretreat with a paste of powder detergent and water, liquid laundry detergent or a liquid detergent booster. Launder.
      </ol>
      <p><i>Heavy Stains</i>:</p>
      <ol>
        <li>Pretreat or presoak with a laundry detergent or a product containing enzymes. Launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Mustard</td>
    <td valign=top>
      <ol>
        <li>Pretreat with prewash stain remover.
        <li>Launder using sodium hypochlorite bleach, if safe for fabric, or oxygen bleach.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Nail polish</td>
    <td valign=top><b>NOTE:</b> Nail polish may be impossible to remove.
      <ol>
        <li>Try nail polish remover but do not use on acetate or triacetate fabrics.
        <li>Place stain facedown on clean paper towels. Apply nail polish remover to back of stain. Replace paper towels frequently.
        <li>Repeat until stain disappears, if it does.
        <li>Rinse and launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Paint</td>
    <td valign=top><i>Water-based Paint</i>:
      <ol>
        <li>Rinse fabric in warm water while stains are still wet.
        <li>Launder.
      </ol>
      <p><b>NOTE:</b> Once paint is dry, it cannot be removed.</p>
      <p><i>Oil-based Paint and Varnish</i>:</p>
      <ol>
        <li>Use the same solvent the label on the can advises for a thinner.
        <li>If not available, use turpentine.
        <li>Rinse.
        <li>Pretreat with prewash stain remover, bar soap or laundry detergent.
        <li>Rinse and launder.
      </ol>
    </td>
    </tr>
  <tr>
    <td valign=top>Perfume</td>
    <td valign=top>
      <ol>
        <li>Pretreat with prewash stain remover or liquid laundry detergent.
        <li>Launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Perspiration</td>
    <td valign=top>
      <ol>
        <li>1.Use a prewash stain remover or rub with bar soap.<br>
          <b>NOTE:</b> If perspiration has changed the color of the fabric, apply ammonia to fresh stains or white vinegar to old stains; rinse.
        <li>Launder using hottest water safe for fabric.<br>
          <b>NOTE:</b> Stubborn stains may respond to washing in a product containing enzymes or oxygen bleach in hottest water safe for fabric.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Pine resin</td>
    <td valign=top>
      <ol>
        <li>Sponge cleaning fluid into the stain; let dry.
        <li>Mix liquid laundry detergent and ammonia; soak stain in the solution.
        <li>Launder using liquid laundry detergent.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Scorch</td>
    <td valign=top><b>NOTE:</b> Badly scorched fabrics may be damaged beyond repair.
      <ol>
        <li>Launder using sodium hypochlorite bleach, if safe for fabric, or soak in oxygen bleach and hot water, then launder.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Shoe polish</td>
    <td valign=top><i>Liquid Shoe Polish</i>:
      <ol>
        <li>Pretreat with a paste of powder detergent and water.
        <li>Launder.
      </ol>
      <p><i>Paste Shoe Polish</i>:</p>
      <ol>
        <li>Scrape residue from fabric with a dull knife.
        <li>Pretreat with a prewash stain remover or cleaning fluid; rinse.
        <li>Rub detergent into dampened area.
        <li>Launder using a bleach safe for fabric.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Tar</td>
    <td valign=top>
      <ol>
        <li>Scrape residue from fabric.
        <li>Place stain facedown on paper towels. Sponge with cleaning fluid. Replace paper towels frequently to absorb more tar and to avoid transferring stains.
        <li>Launder in hottest water safe for fabric.
      </ol>
    </td>
  </tr>
  <tr>
    <td valign=top>Tobacco</td>
    <td valign=top>
      <ol>
        <li>Dampen stain and rub with bar soap; rinse.
        <li>Pretreat or soak in a product containing enzymes.
        <li>Launder.
      </ol>
      <p><b>NOTE:</b> If stain remains, launder again using a bleach safe for fabric.</p>
    </td>
  </tr>
  <tr>
    <td valign=top>Typewriter correction fluid</td>
    <td valign=top>
      <ol>
        <li>Let stain dry thoroughly, then gently brush excess off with a clothes brush.
        <li>Send to professional drycleaner and mention the type of stain.
      </ol>
    </td>
  </tr>
</table>
<h3>Laundering</h3>
<p>One of the most common means of cleaning textiles is through the laundering process.  Laundering involves the use of water and cleaning agents to clean textiles.   The water loosens the soil and the soaps or detergents suspend the soil to avoid redeposit onto the textiles.  Additional laundry additives may be used to perform special functions in the laundering process.  Once the laundry has been thoroughly washed it must then be dried to remove residual water.    </p>
<p><i><b>Laundry Additives</b></i></p>
<p>A variety of laundry additives are available in the marketplace. Laundry additives are chemical agents that have been designed to perform specific functions in the laundry process. Some of the more common laundry additives and their functions are listed in the following table.</p>
<table width="95%" border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <tr valign="top">
    <th nowrap>LAUNDRY ADDITIVES</th>
    <th colspan="2">FUNCTION</th>
  </tr>
  <tr valign="top">
    <td nowrap>Detergent</td>
    <td colspan="2">A mixture of synthetic and natural ingredients that cleans laundry without leaving a soapy residue.  Detergents are specially formulated for specific types of conditions and applications.</td>
  </tr>
  <tr valign="top">
    <td nowrap>Soap</td>
    <td colspan="2">A fatty acid and alkaline substance that is effective in removing oily, greasy stains but may leave a soap film on fabrics and basins</td>
  </tr>
  <tr valign="top">
    <td rowspan="2" nowrap>Bleach</td>
    <td width="66">Chlorine</td>
    <td>A bleach, generally in a liquid form, that is used to whiten and disinfect</td>
  </tr>
  <tr valign="top">
    <td width="66">Perborate</td>
    <td>A powdered bleach that uses oxygen to mildly whiten fabrics</td>
  </tr>
  <tr valign="top">
    <td nowrap>Water Softener</td>
    <td colspan="2">A powdered substance that increases the efficiency of detergents by tying up minerals in hard water.   Water softeners come in precipitating and non-precipitating varieties.</td>
  </tr>
  <tr valign="top">
    <td nowrap>Fabric Softener</td>
    <td colspan="2">A substance that coats the surface of the fabric, making it softer and more resistant to static charge. Fabric softeners may be added in a liquid form during the wash cycle or on a sheet in the drying cycle.</td>
  </tr>
  <tr valign="top">
    <td nowrap>Disinfectant	</td>
    <td colspan="2">Chemicals including pine oil, phenolic and chlorine bleach added to wash water that destroy bacteria</td>
  </tr>
  <tr valign="top">
    <td nowrap>Enzyme Cleaners</td>
    <td colspan="2">Enzymes commonly used when presoaking to remove difficult stains</td>
  </tr>
  <tr valign="top">
    <td nowrap>Brighteners</td>
    <td colspan="2">A weak, blue dye added to wash that diminishes yellowing</td>
  </tr>
  <tr valign="top">
    <td nowrap>Starches / Sizing</td>
    <td colspan="2">A solution applied during the laundry process, or more commonly in a spray solution during ironing, to add stiffness and body</td>
  </tr>
</table>
<h4>Washing</h4>
<p>Textiles that can be laundered should be washed by hand or in a machine. Delicate products are best washed by hand where agitation is reduced. Hand washing is generally accomplished in a sink or small basin. A delicate or light duty detergent added to the water prior to the product protects the textile against harsh chemical treatment. After soaking for 10 to 20 minutes, the textile should be gently squeezed and patted dry to remove excess water.</p>

<p>Most textiles that can be laundered can be successfully machine washed. The
effectiveness and efficiency of machine laundering depends on the laundry equipment,
laundry products, and the steps taken. Soiled textiles should be prepared and
sorted before washing. Preparation involves cleaning out pockets, closing fasteners,
shaking off excess dirt and debris, and turning knits inside out. Textiles should
then be sorted based on weight, color, soiling of the fabrics, whether they
are lint producers or lint absorbers, and whether they can withstand warmer
temperatures or not. When washing, laundry additives and wash settings should
be carefully selected for the specific load of laundry. Wash settings that may
be manipulated include water level, water temperature, wash cycle, rinse cycle
and spin dry. However, many machines have preset combinations of these settings.
Overloading the washing machine fails to allow textiles to be thoroughly cleaned
and may cause excess wrinkling.</p>
<h4>Drying</h4>
<p>A variety of methods may be used for drying laundered products. The fibers, yarns, fabrication, finishes and product construction all influence the appropriateness of the drying method.  Most textile goods that are laundered can be machine dried.  Like in the laundry process, care must be taken to use the proper temperature and tumbling settings to avoid damage.  Some items may not be machine dried.  If the items are particularly delicate, stretchy or require shaping, flat drying in a well ventilated area may be recommended.  If the items are relatively stable but not heat tolerant, hang or line drying may be the best choice.  Although outdoor line drying may yield a fresh smell, the sun and air pollutants may have harmful effects on fibers.     </p>
<h3>Dry Cleaning </h3>

<p>Some textiles and sewn products can be severely damaged if laundered. Dry cleaning
is generally recommended for these products. The dry cleaning process uses a
liquid solvent instead of water to remove soil and bacteria from the textile
product. All textile products can be dry cleaned unless otherwise indicated.
Dry cleaning is often recommended because it usually reduces shrinkage, preserves
finishes and maintains structural details of the finished product. Dry cleaning
can be done by commercial dry cleaners, at coin-operated facilities, or at home.</p>
<p>Commercial dry cleaners typically remove stains and spots from textile products. The product is sorted and placed in a dry cleaner resembling an oversized washing machine. In the dry cleaning process, the textile good is agitated with a cleaning solvent and later spun dry. Products are pressed, properly stored and are then ready for the consumer. Some dry cleaners will also add or restore finishes to preserve the shape or repellency of the product.</p>
<p>Coin-operated dry cleaners are often available in laundromats. Compared to commercial dry cleaners, they are less expensive and faster to operate. However, care must be taken to pretreat spots, follow the operating instructions, and press the cleaned products.</p>

<p>Today, products for dry cleaning at home are available in the marketplace.
The products use a residential clothes dryer along with the purchased dry cleaning
solvent to clean soiled textiles. Any stains must be pretreated and the cleaned
product must then be pressed or steamed to achieve the look of a commercially
dry cleaned product.</p>
<h3>Ironing, Pressing and Steaming</h3>
<p>Ironing, pressing and/or steaming may be necessary to remove wrinkles from a textile product and enhance the appearance. Care must be taken in selecting the appropriate method and temperature setting for the type of fibers in the fabric. By definition, ironing involves the back and forth motion of a heated iron on a fabric, while pressing includes the raising and lowering of a heated iron on a fabric. Items to be ironed or pressed must be able to withstand at least low levels of heat. Although steam may be applied in the ironing or pressing process, steam may be applied without ironing or pressing. Steaming includes the use of steam from a steamer to smooth wrinkles from a textile, and is particularly appropriate for large hanging objects such as draperies or shaped items that are difficult to iron or press. Textiles must be able to withstand both heat and water in order to avoid damage from steaming.</p>
<h3>Other Cleaning Methods</h3>
<p>Not all textile products can be easily laundered or dry cleaned.  Permanently installed items such as carpets, large items such as area rugs, and upholstered items such as chairs and sofas require special cleaning techniques.  Vacuuming is a common means for removing dust and loose debris from carpets and upholstery fabrics.  However, vacuuming does not remove stains.  Shampooing, hot water extraction and steam cleaning are all methods of removing soil from carpets and upholstery fabrics that can withstand water.  Alternatively, for textiles that are water sensitive, foam or powder cleaners may be applied to the textile to remove soiling.  Residue is later removed by vacuuming.  </p>
<p>The following exercises will provide a general comparison of stain removal and home laundering of various types of fibers.  After completing the exercises, you should be able to determine the best method of care for a textile product and actually perform the process.  </p>
<h3>Objectives</h3>
<ol>
  <li>To describe procedures for removing specific stains
  <li>To determine the best temperatures for washing, drying and ironing fabrics comprised of specific fibers
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you complete the exercises on cleaning textiles.</p>
<blockquote>
  <p><span class="boldital">Dry Cleaning:</span> Dry cleaning is the process of removing soil from fabrics or textile products with a liquid dry-cleaning solvent instead of water.</p>
  <p><span class="boldital">Ironing and Pressing: </span>Ironing and pressing involve the process of applying a heated iron to fabrics. In ironing, the iron is moved back and forth across the fabric, while in pressing the iron is raised and lowered.</p>
  <p><span class="boldital">Laundering: </span> Laundering is the process of removing soil from fabrics or textile products with the use of water, detergent or soap, and agitation.  </p>
  <p><span class="boldital">Stain Removal:</span>  Stain removal is the process of cleaning textiles by removing spots and stains from fabrics.</p>
</blockquote>
<h3>Observations</h3>
<p>This exercise is designed to provide a better understanding of the some of the processes involved in cleaning textiles. Before starting you may want to have access to several charts showing stain removal methods and laundry procedures. Information such as the Stain Removal Chart from the Soaps and Detergent Association (printed or on their websites <a title="www.cleaning101.com" href="http://www.cleaning101.com" target="_blank">www.cleaning101.com</a> or <a title="www.sdahq.org" href="http://www.sdahq.org" target="_blank">www.sdahq.org</a>) is particularly helpful.</p>
<p>In this exercise you will first be given a list of stains and asked to identify means for removing them from washable fabrics.  You will then be provided a list of fibers and asked to identify the appropriate washing, drying and ironing/pressing temperatures.  Carefully review any additional resources you have available.   Identify the procedure you would use to clean each of the particular stains and fibers.  Record your notes on the related worksheet.  How should the stains be most effectively removed?  Can the product be safely laundered in hot, warm or cool water?  What is the most appropriate drying temperature for each of the various fibers?  Can the product be ironed, pressed or steamed?  What is the best temperature for ironing or pressing each of the various fibers?  </p>
<h3>Exercise A</h3>
<p>A stain removal table has been included in your worksheet. Complete the table by indicating how you would remove the identified stains from washable textiles.<br>
</p>
<h3>Exercise B</h3>
<p>A laundering table has been included in your worksheet. Complete the table by indicating the best temperature for washing, drying, and ironing or pressing the indicated fibers.</p>
