<div align="right">
  <p><img src="/assets/images/unit-1/solubility_test.gif" alt="Solubility Test" height="37" width="208" align="left" border="0"><a href="u_1_5.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  The last basic method of fiber identification is the solubility test. Fibers react differently to various chemical solutions based on the chemical composition of the fiber. For example, wool and silk react to alkaline solutions such as sodium hypochlorite, the main ingredient in bleach. Cellulosic fibers such as cotton, linen and rayon, as well as manufactured fibers such as nylon and acetate, react to acidic solutions. Cellulosic fibers, particularly, react to sulfuric acid, while nylon reacts to hydrochloric acid. Acetate reacts to acetone or acidic acid. Other fibers such as polyester are relatively resistant to both alkalines and acids.</p>
<p>After viewing the solubility tests, you should have a better clue as to the classification of the sample fibers. &nbsp;A more detailed chemical analysis, as might be performed by a textile scientist, may still be necessary to finally identify by name the specific type of fiber being tested.</p>
<p>You will observe video clips of different fibers as they are placed in solutions of varying degrees of acidity and alkalinity. Note the reactions of each fiber in the various solutions. Observations should provide clues for identifying each of the fibers.&nbsp; </p>
    <h3>Objectives</h3>
    <ol>
      <li>To observe the characteristics exhibited by different sample fibers as they are placed in a chemical solution</li>
      <li>To identify the fiber samples based upon the observed results of the solubility tests</li>
    </ol>
    <h3>Laboratory Terminology</h3>
    <p>Be aware of the following terms as you watch the video clips showing the solubility tests.</p>

<blockquote>
<p><em><strong>In an acidic solution:</strong></em> What happens to the fiber
as it sits in the acidic solution? How has the fiber changed after remaining
in the acidic solution for five minutes? Are there any visible changes?</p>
<p><em><strong>In an alkaline solution:</strong></em> What happens to the fiber
as it sits in the alkaline solution? How has the fiber changed after remaining
in the alkaline solution for five minutes? Are there any visible changes?</p>
</blockquote>
    <h3>Procedures and Standards</h3>
    <p>This test is used to evaluate the composition of a sample of fabric as it is placed in different chemical solutions. Each of the seven fibers will be placed in four different chemical solutions: acetone, hydrochloric acid, sodium hypochlorite, and sulfuric acid. The reaction of the fibers after being in the solution for a duration of five minutes will be shown in a second set of videos. The data obtained are a measure of the degree of destruction of a material when exposed to the various chemical solutions for the controlled amount of time. For this exercise, record all observations, results and conclusions on the related worksheet. Carefully observe and record what happens to each of the test fabrics after they are left for five minutes in the:</p>
    <ol>
      <li>Acetone solution</li>
      <li>Hydrochloric acid solution</li>
      <li>Sodium hypochlorite solution </li>
      <li>Sulfuric acid solution</li>
    </ol>
    <p>Based on your observations, can you determine the fiber classification? Which fibers are natural and which are manufactured? </p>
    <p>With the knowledge gained from the previous three tests, along with the observations noted in this test, it should be possible to identify the fibers of each of the seven samples. Remember that all fibers were shown in the same order in each of the four fiber identification tests. </p>
    <blockquote>
      <p>Which test sample was acetate? &nbsp;<br>
      Which test sample was cotton?<br>
      Which test sample was nylon?<br>
      Which test sample was polyester?<br>
      Which test sample was rayon?<br>
      Which test sample was silk?<br>
      Which test sample was wool? </p>
    </blockquote>
    <h3>Test        </h3>
    <table border="0" cellspacing="0" cellpadding="10">
      <tr align="center">
        <td class="byline">Fiber 1</td>
        <td class="byline">Fiber 2 </td>
      </tr>
      <tr>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270" border ="3" cellspacing="0" cellpadding="0">
            <param name="movie" value="videos/1-60.swf">
            <param name="quality" value="high">
            <param name="BorderStyle" value="1">
            <param name="border-style" solid;border-color:#0000ff />
            <embed src="videos/1-60.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" border="3" height="270" border="3" cellspacing="0" cellpadding="0"></embed>
          </object> -->
          <video width="320" height="270" controls>
            <source src="/assets/videos/unit-1/1-60.mp4" type="video/mp4">
            Your browser does not support the video tag
          </video>
      </td>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" border="3" height="270" border="3">
          <param name="movie" value="videos/1-61.swf">
          <param name="quality" value="high">
          <embed src="videos/1-61.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270"></embed>
        </object> -->
        <video width="320" height="270" controls>
          <source src="/assets/videos/unit-1/1-61.mp4" type="video/mp4">
          Your browser does not support the video tag
        </video>
      </td>
      </tr>
      <tr align="center">
        <td class="byline"><br>
        Fiber 3 </td>
        <td class="byline"><br>
        Fiber 4 </td>
      </tr>
      <tr>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
          <param name="movie" value="videos/1-62.swf">
          <param name="quality" value="high">
          <embed src="videos/1-62.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270"></embed>
        </object> -->
        <video width="320" height="270" controls>
          <source src="/assets/videos/unit-1/1-62.mp4" type="video/mp4">
          Your browser does not support the video tag
        </video>
      </td>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
          <param name="movie" value="videos/1-63.swf">
          <param name="quality" value="high">
          <embed src="videos/1-63.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270"></embed>
        </object> -->
        <video width="320" height="270" controls>
          <source src="/assets/videos/unit-1/1-63.mp4" type="video/mp4">
          Your browser does not support the video tag
        </video>
      </td>
      </tr>
      <tr>
        <td align="center" class="byline"><br>
        Fiber 5 </td>
        <td align="center" class="byline"><br>
        Fiber 6 </td>
      </tr>
      <tr>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
          <param name="movie" value="videos/1-64.swf">
          <param name="quality" value="high">
          <embed src="videos/1-64.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270"></embed>
        </object> -->
        <video width="320" height="270" controls>
          <source src="/assets/videos/unit-1/1-64.mp4" type="video/mp4">
          Your browser does not support the video tag
        </video>
      </td>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
          <param name="movie" value="videos/1-65.swf">
          <param name="quality" value="high">
          <embed src="videos/1-65.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270"></embed>
        </object> -->
        <video width="320" height="270" controls>
          <source src="/assets/videos/unit-1/1-65.mp4" type="video/mp4">
          Your browser does not support the video tag
        </video>
      </td>
      </tr>
      <tr>
        <td align="center" class="byline"><br>
        Fiber 7 </td>
        <td></td>
      </tr>
      <tr>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
          <param name="movie" value="videos/1-66.swf">
          <param name="quality" value="high">
          <embed src="videos/1-66.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270"></embed>
        </object> -->
        <video width="320" height="270" controls>
          <source src="/assets/videos/unit-1/1-66.mp4" type="video/mp4">
          Your browser does not support the video tag
        </video>
      </td>
        <td>&nbsp;</td>
      </tr>
</table>
