import { Unit6ConclusionComponent } from './unit6-conclusion/unit6-conclusion.component';
import { Unit6IntroductionComponent } from './unit6-introduction/unit6-introduction.component';
import { AnimalProductsComponent } from './animal-products/animal-products.component';
import { CompositesComponent } from './composites/composites.component';
import { YarnBasedFabricsComponent } from './yarn-based-fabrics/yarn-based-fabrics.component';
import { FiberBasedFabricsComponent } from './fiber-based-fabrics/fiber-based-fabrics.component';
import { SolutionBasedFabricsComponent } from './solution-based-fabrics/solution-based-fabrics.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'solution-based-fabrics', component: SolutionBasedFabricsComponent },
  { path: 'fiber-based-fabrics', component: FiberBasedFabricsComponent },
  { path: 'yarn-based-fabrics', component: YarnBasedFabricsComponent },
  { path: 'composites', component: CompositesComponent },
  { path: 'animal-products', component: AnimalProductsComponent },
  { path: 'introduction', component: Unit6IntroductionComponent },
  { path: 'conclusion', component: Unit6ConclusionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class Unit6RoutingModule {
  static components = [ SolutionBasedFabricsComponent, FiberBasedFabricsComponent, YarnBasedFabricsComponent,
    CompositesComponent, AnimalProductsComponent, Unit6IntroductionComponent, Unit6ConclusionComponent ]
}
