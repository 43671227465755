<div align="right">
  <p><img src="/assets/images/unit-3/yarn_size.gif" alt="" height="37" width="430" align="left" border="0"><a href="u_3_4.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
Yarns may be classified by their size and their number of parts or components.
Although technical sizing systems have been developed for yarns of a variety
of fibers, some simple measures can be followed. A yarn number in denier or
tex indicates the size of the yarn by comparing the weight of equal lengths
of yarns. The higher numbers indicate coarser yarns, while lower numbers indicate
finer yarns. Size may, to some extent, be reflective of the fibers and number
of parts in the yarn.</p>
<p>Yarns may be produced from a single source or variety of components.  A single type of fiber may be used to make a filament or spun yarn.  Multiple varieties of fibers may also be blended before the yarn has been spun into a single strand known as a blend.  Both of these yarns are known as single yarns because they form a single strand of yarn.  Single yarns can be easily identified.  Loose fibers result when the yarn is unraveled.  </p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">
      Single strand with similar fibers</td>
    <td class="byline">
      Single strand with blended fibers</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-40.jpg" target="_blank"><img src="/assets/images/unit-3/3-40.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-41.jpg" target="_blank"><img src="/assets/images/unit-3/3-41.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<p>In other cases two or more yarns may be twisted together to form a new yarn
known as a ply yarn. In this case, the plies may be of the same fibers and same
types of yarns or they may be of different fibers and/or yarns producing what
is referred to as a combination.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Four ply yarn</td>
    <td class="byline">Three ply yarn</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-42.jpg" target="_blank"><img src="/assets/images/unit-3/3-42.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-43.jpg" target="_blank"><img src="/assets/images/unit-3/3-43.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><br>
          Combination yarn</td>
    <td></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-44.jpg" target="_blank"><img src="/assets/images/unit-3/3-44.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td></td>
  </tr>
</table>
<p>Sometimes ply yarns are combined to make a single yarn.  If a ply yarn is combined with one or more single or other ply yarns a cord yarn is produced.  For example, a ply yarn may be twisted with one or more single yarns or it may be twisted with other ply yarns to create a cord. </p>
<table width="64" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Cord</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-45.jpg" target="_blank"><img src="/assets/images/unit-3/3-45.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>By varying the number and types of fibers and number and types of yarns twisted together, an infinite number of yarns can be produced. The variations in each resultant yarn lead to a unique set of appearance and performance characteristics. As a result, textile and product developers have a myriad of choices to enhance their designs.</p>
<p>This segment is designed to provide a general comparison of single, ply and cord yarns.   After completing this segment, you should be able to assess the basic differences in the number of elements in the sample yarns.  </p>
<h3>Objectives</h3>
<ol>
  <li>To observe single yarns
  <li>To observe ply yarns
  <li>To observe cord yarns
  <li>To note differences between single, ply and cord yarns
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you view the photographs of the different yarns.</p>

<blockquote>
<p><span class="boldital">Blend:</span> A blend is a mix of different types
of fibers in a single yarn. Does the yarn unravel into two or more distinctly
different fibers?</p>
<p><span class="boldital">Combination:</span> A combination is the use of two
or more yarns, each of a different fiber, in a ply yarn. Do the plies of the
yarn appear to have different fiber content?</p>
<p><span class="boldital">Cord Yarn: </span>Cord yarns are formed from two or
more ply yarns. Does the yarn unravel into ply yarns before unraveling into
single yarns and later fibers? </p>
<p><span class="boldital">Denier:</span> Denier is a measure of the linear mass
density of fibers. A denier is the mass in grams of 9,000 meters of yarn.
Higher deniers reflect larger, coarser and heavier yarns, while lower deniers
reflect smaller, finer and lighter yarns.</p>
<p><span class="boldital">Ply Yarn:</span> Ply yarns are formed from two or
more single yarns. Does the yarn unravel into single yarns before unraveling
into fibers? </p>
<p><span class="boldital">Single Yarn:</span> Single yarns are filament or spun
yarns that are produced directly from fibers. Does the yarn unravel directly
into fibers?</p>
<p><span class="boldital">Tex:</span> Tex is also a measure of the linear mass
density of fibers. A tex is the mass in grams of 1,000 meters of yarn.</p>
<p>&nbsp;</p>
  </blockquote>
<h3>Observations</h3>
<p>This exercise is designed to illustrate the difference between single, ply and cord yarns. You will observe photos of a variety of different yarns. While viewing the yarns note the yarn qualities and describe the apparent differences. Classify the yarn as single, ply or cord based on your observation. Record your notes on the related worksheet. Which of the yarns are single? Which of the yarns are ply? Which of the yarns are cord yarns? Are any of the samples blended or combination yarns? How were you able to distinguish between the various examples?</p>
<h3>Tests</h3>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Yarn Components 1</td>
    <td class="byline">Yarn Components 2</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-3/u3large/3-46.jpg" target="_blank"><img src="/assets/images/unit-3/3-46.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-47.jpg" target="_blank"><img src="/assets/images/unit-3/3-47.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Yarn Components 3</td>
    <td class="byline"><br>
      Yarn Components 4</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-3/u3large/3-48.jpg" target="_blank"><img src="/assets/images/unit-3/3-48.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-49.jpg" target="_blank"><img src="/assets/images/unit-3/3-49.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">			      <br>
    Yarn Components 5</td>
    <td class="byline"><br>
      Yarn Components 6</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-3/u3large/3-50a.jpg" target="_blank"><img src="/assets/images/unit-3/3-50a.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-51.jpg" target="_blank"><img src="/assets/images/unit-3/3-51.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
