import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Unit8RoutingModule } from './unit-8-routing.module';


@NgModule({
  imports: [CommonModule, ReactiveFormsModule, Unit8RoutingModule],
  declarations: [Unit8RoutingModule.components]
})

export class Unit8Module { }
