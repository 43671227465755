import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visual-inspection',
  templateUrl: './visual-inspection.component.html',
  styleUrls: ['./visual-inspection.component.css']
})
export class VisualInspectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
