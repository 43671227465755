import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voluntary-standards',
  templateUrl: './voluntary-standards.component.html',
  styleUrls: ['./voluntary-standards.component.css']
})
export class VoluntaryStandardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
