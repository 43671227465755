<mat-toolbar color = "primary">
  TextileMania&nbsp;

      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <span class="toolbar-spacer"></span>
      Login
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav mode="side" #sidenav opened position="start">
    <mat-nav-list>
      <mat-list-item>
        <mat-icon mat-list-icon>person</mat-icon>
        <h4 mat-line>Unit 1: Fiber Identification</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-1/introduction']">Introduction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-1/visual-inspection']">Visual Inspection</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-1/burn-test']">Burn Test</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-1/microscopic-observation']">Microscopic Observation</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-1/solubility']">Solubility</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-1/conclusion']">Conclusion</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>assignment</mat-icon>
        <h4 mat-line>Unit 2: Textile Properties</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-2/introduction']">Introduction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-2/tenacity']">Tenacity</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-2/colorfastness']">Colorfastness</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-2/dimentional-stability']">Dimentional Stability</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-2/absorbency']">Absorbency</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-2/resiliency']">Resiliency</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-2/conclusion']">Conclusion</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>domain</mat-icon>
        <h4 mat-line>Unit 3: Yarns</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-3/introduction']">Introduction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-3/filaments-spurn-yarns']">Filaments & Spurn Yarns</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-3/yarn-twist']">Yarn Twist</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-3/yarn-size']">Yarn Size & Number of Parts</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-3/yarn-complexity']">Complexity of Yarns</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-3/conclusion']">Conclusion</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line>Unit 4: Weaves</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-4/introduction']">Introduction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-4/plain-weaves']">Plain Weaves</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-4/fancy-weaves']">Fancy Weaves</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-4/conclusion']">Conclusion</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line>Unit 5: Knits</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-5/introduction']">Introduction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-5/knitting-process']">Knitting Process</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-5/knit-stitches']">Knit Stitches</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-5/conclusion']">Conclusion</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line>Unit 6: Other Fabric Construction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-6/introduction']">Introduction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-6/solution-based-fabrics']">Solution Based Fabrics</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-6/fiber-based-fabrics']">Fiber Based Fabrics</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-6/yarn-based-fabrics']">Yarn Based Fabrics</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-6/composites']">Composites</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-6/animal-products']">Animal Products</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-6/conclusion']">Conclusion</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line>Unit 7: Dyeing and Printing</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-7/introduction']">Introduction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-7/dyeing']">Dyeing</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-7/printing']">Printing</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-7/conclusion']">Conclusion</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line>Unit 8: Fabric Finishes</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-8/introduction']">Introduction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-8/aesthetic-finishes']">Aesthetic Finishes</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-8/performance-finishes']">Performance Finishes</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-8/conclusion']">Conclusion</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line>Unit 9: Care of Textile Products</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-9/introduction']">Introduction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-9/cleaning']">Cleaning</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-9/care-labels']">Care Labels</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-9/conclusion']">Conclusion</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line>Unit 10: Textile Regulations</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-10/introduction']">Introduction</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-10/involuntary-regulations']">Involuntary Regulations</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-10/voluntary-standards']">Voluntary Standards</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line [routerLink]="['/unit-10/conclusion']">Conclusion</h4>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="main-content">
      <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> -->
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
<!--
<mat-sidenav-container fullscreen>

  <mat-sidenav mode="side" #sidenav opened position="start">
    left panel
  </mat-sidenav>
  <mat-sidenav-content>

    <mat-toolbar color="primary">

      TextileMania&nbsp;

      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <span class="toolbar-spacer"></span>
      Login
    </mat-toolbar>
    <div class="main-content">
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
    </div>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container> -->
