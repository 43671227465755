import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Unit4RoutingModule } from './unit-4-routing.module';


@NgModule({
  imports: [CommonModule, ReactiveFormsModule, Unit4RoutingModule],
  declarations: [Unit4RoutingModule.components]
})

export class Unit4Module { }
