<div align="right">
  <p><img src="/assets/images/unit-1/visual_inspection.gif" alt="Visual Inspection" height="37" width="259" align="left" border="0"><a href="u_1_2.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  The first method of fiber identification is simply the visual inspection. The appearance of a fiber, yarn or fabric depends greatly on the length, luster or shine, and texture of the fiber.</p>
<p>Fibers from natural sources, with the exception of silk, are generally short in length and are measured in centimeters or fractions of inches. Manufactured or man-made fibers often start as very long filament fibers and are measured in meters or yards. Manufactured fibers can be cut into shorter lengths to simulate the qualities of staple fibers.</p>
<p>Because of their long, smooth surface, filament yarns such as silk and many manufactured fibers tend to have a higher sheen than staple fibers. However, by carefully engineering man-made fibers, varying levels of sheen can be developed. Natural, staple fibers inherently tend to have a low sheen.</p>
    <p>Many manufactured fibers are generally regular in shape, yielding a relatively smooth texture. However, they can be engineered to have a highly textured surface. Natural fibers also vary in texture. Silk is very smooth, although most natural fibers tend to have an irregular or rough surface.&nbsp;</p>
    <table border="0" cellspacing="0" cellpadding="10">
  <tr>
    <td class="byline" align="center" valign="bottom">

        Natural staple fibers</td>
    <td class="byline" align="center" valign="bottom">

        Manufactured filament fibers</td>
  </tr>
  <tr>
        <td align="center" valign="bottom"><a href="/assets/images/unit-1/u1large/1-1.jpg" target="_blank"><img src="/assets/images/unit-1/1-1.jpg" width="250" height="188" border="0"></a></td>
        <td align="center" valign="top"><a href="/assets/images/unit-1/u1large/1-2.jpg" target="_blank"><img src="/assets/images/unit-1/1-2.jpg" width="250" height="175" border="0"></a></td>
      </tr>
  <tr>
    <td class="byline" align="center" valign="bottom"><br>
      Manufactured staple fibers</td>
    <td align="center" valign="bottom">
      <p class="byline"></p>
    </td>
  </tr>
  <tr>
        <td align="center" valign="bottom"><p class="byline"><a href="/assets/images/unit-1/u1large/1-3.jpg" target="_blank"><img src="/assets/images/unit-1/1-3.jpg" width="250" height="188" border="0"></a></p>
        </td>
        <td align="center" valign="bottom">&nbsp;</td>
      </tr>
</table>
    <p>After viewing various fabrics and fibers, you should be able to classify the sample fibers as either staple or filament, which will in turn give you a clue as to whether the fiber is more likely natural or manufactured. &nbsp;More detailed analysis, as will be shown in subsequent tests or as might be performed by a textile scientist, would normally be necessary to truly determine if the fiber is natural or manufactured, as well as to identify the specific type of fiber being observed.</p>
    <p>You will be shown photographs of seven different fibers. Note variations in fiber length, luster and texture. Record your observations. </p>
    <h3>&nbsp;Objectives</h3>
    <ol>
      <li>To observe the length, luster and texture of various fibers</li>
      <li>To distinguish between staple and filament fibers</li>
    </ol>
    <h3>Laboratory Terminology</h3>
    <p>Be aware of the following terms as you view the photographs. </p>

<blockquote>
<p><em><strong>Classification:</strong></em> A category into which a fiber may
be placed based on the qualities exhibited by that fiber when subjected to
testing and other evaluations.</p>
<p><em><strong>Fiber length:</strong></em> On average, how long are the fibers?
Is the fiber long and of an indefinite length? Is the fiber short, allowing
it to be measured in centimeters or a fraction of an inch?</p>

<p><em><strong>Filament:</strong></em> Filament fibers are very long fibers
that are measured in meters or yards. With the exception of silk, most filament
fibers are manufactured.</p>
      <p><em><strong>Luster:</strong></em> Does the fiber have sheen? Is it shiny? Does it have a matte or dull finish? </p>

<p><em><strong>Staple:</strong></em> Staple fibers are relatively short fibers
that can be measured in centimeters or fractions of an inch. With the exception
of silk, natural fibers are staple fibers. Manufactured fibers can also be
produced as staple fibers.</p>
<p><em><strong>Texture:</strong></em> How thick does the fiber appear? Is the
fiber smooth or rough? Does the fiber appear to be crimped, bent or twisted
in any way? Does the fiber seem to be soft or stiff? </p>
</blockquote>
    <h3>Procedures and Standards</h3>
    <p>This test is used to evaluate the simple observable characteristics of a fiber in a yarn and/or fabric. By merely viewing the length, luster and texture of the fiber, clues to the classification of that fiber can be surmised.</p>
<p>For this exercise, carefully view the photographs. Record all observations and conclusions on the related worksheet. Carefully observe and record the following with respect to the fiber:</p>
    <ol>
      <li> Length</li>
      <li>Luster</li>
      <li>Texture</li>
    </ol>
    <p>Also, determine if the fiber shown is more likely to be a natural or manufactured fiber, based on your observations.</p>
<h3>Sample Test</h3>
<p>
  <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="80" height="40">
        <param name="movie" value="videos/c1st.swf">
        <param name="quality" value="high">
        <embed src="videos/c1st.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="80" height="40"></embed>
    </object> -->
</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline" colspan="2">Linen</td>
  </tr>
  <tr align="center">
        <td><a href="/assets/images/unit-1/u1large/1-4.jpg" target="_blank"><img src="/assets/images/unit-1/1-4.jpg" width="250" height="188" border="0"></a></td>
        <td><a href="/assets/images/unit-1/u1large/1-5.jpg" target="_blank"><img src="/assets/images/unit-1/1-5.jpg" width="250" height="188" border="0"></a></td>
      </tr>
</table>
<p>&nbsp;</p>
<h3>Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center"><td colspan="2" class="byline">			  Fiber 1</td>
  </tr>
  <tr align="center">
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-6a.jpg" target="_blank"><img src="/assets/images/unit-1/1-6a.jpg" width="250" height="188" border="0"></a></p>
    </td>
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-6b.jpg" target="_blank"><img src="/assets/images/unit-1/1-6b.jpg" width="250" height="188" border="0"></a></p>
    </td>
      </tr>
  <tr align="center">
    <td class="byline">fabric<br>
      <br>
    </td>
    <td class="byline">yarn<br>
      <br>
    </td>
  </tr>
  <tr align="center">
    <td colspan="2" class="byline">
        Fiber 2</td>
  </tr>
  <tr align="center">
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-7a.jpg" target="_blank"><img src="/assets/images/unit-1/1-7a.jpg" width="250" height="188" border="0"></a></p>
    </td>
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-7b.jpg" target="_blank"><img src="/assets/images/unit-1/1-7b.jpg" width="250" height="188" border="0"></a></p>
    </td>
      </tr>
  <tr align="center">
    <td class="byline">fabric<br>
      <br>
    </td>
    <td class="byline">yarn<br>
      <br>
    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">
      <div align="center">
        Fiber 3</div>
    </td>
  </tr>
  <tr align="center">
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-8a.jpg" target="_blank"><img src="/assets/images/unit-1/1-8a.jpg" width="250" height="188" border="0"></a></p>
    </td>
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-8b.jpg" target="_blank"><img src="/assets/images/unit-1/1-8b.jpg" width="250" height="188" border="0"></a></p>
    </td>
      </tr>
  <tr align="center">
    <td class="byline">fabric<br>
      <br>
    </td>
    <td class="byline">yarn<br>
      <br>
    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">
      <div align="center">
        Fiber 4</div>
    </td>
  </tr>
  <tr align="center">
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-9a.jpg" target="_blank"><img src="/assets/images/unit-1/1-9a.jpg" width="250" height="188" border="0"></a></p>
    </td>
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-9b.jpg" target="_blank"><img src="/assets/images/unit-1/1-9b.jpg" width="250" height="188" border="0"></a></p>
    </td>
      </tr>
  <tr align="center">
    <td class="byline">fabric<br>
      <br>
    </td>
    <td class="byline">yarn<br>
      <br>
    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">
      <div align="center">
        Fiber 5</div>
    </td>
  </tr>
  <tr align="center">
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-10a.jpg" target="_blank"><img src="/assets/images/unit-1/1-10a.jpg" width="250" height="188" border="0"></a></p>
    </td>
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-10b.jpg" target="_blank"><img src="/assets/images/unit-1/1-10b.jpg" width="250" height="188" border="0"></a></p>
    </td>
      </tr>
  <tr align="center">
    <td class="byline">fabric<br>
      <br>
    </td>
    <td class="byline">yarn<br>
      <br>
    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">
      <div align="center">
        Fiber 6</div>
    </td>
  </tr>
  <tr align="center">
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-11a.jpg" target="_blank"><img src="/assets/images/unit-1/1-11a.jpg" width="250" height="188" border="0"></a></p>
    </td>
        <td>
      <p><a href="/assets/images/unit-1/u1large/1-11b.jpg" target="_blank"><img src="/assets/images/unit-1/1-11b.jpg" width="250" height="188" border="0"></a></p>
    </td>
      </tr>
  <tr align="center">
    <td class="byline">fabric</td>
    <td class="byline">yarn</td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">
      <div align="center">
        Fiber 7</div>
    </td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-1/u1large/1-12a1.jpg" target="_blank"><img src="/assets/images/unit-1/1-12a.jpg" width="250" height="188" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-1/u1large/1-12b1.jpg" target="_blank"><img src="/assets/images/unit-1/1-12b.jpg" width="250" height="188" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline">fabric</td>
    <td class="byline">yarn</td>
  </tr>
</table>
