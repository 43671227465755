<div align="right">

  <p><img src="/assets/images/unit-6/composite.gif" alt="" height="37" width="186" align="left" border="0"><a href="u_6_5.html" target="_blank">Printable Page</a></p>

</div>

<p><br>

  <br>

  In many cases, two or more fabric construction methods are combined to create a single fabric. By combining construction methods, the resultant fabric takes on a unique set of performance and aesthetic qualities. Although many methods are used to create a composite fabric, this section will limit the discussion to just a few common methods, including stitch-bonding, tufting, flocking, coating and laminating.</p>

<h3>Stitch Bonded</h3>

<p>Stitch-bonded fabrics secure layers of fabric by stitching, knitting, or chemically or thermally securing the fabrics. The result is a fabric with additional strength and dimensional stability. One form of stitch-bonded fabric is the quilted fabric which combines two surface fabrics with at least one filling fabric. Typical uses of stitch-bonded fabrics include blankets, tablecloths, window treatments and outerwear.</p>

<table border="0" cellspacing="0" cellpadding="10">

  <tr align="center" valign="top">

    <td class="byline" colspan="2">Stitch bonded fabric</td>

  </tr>

  <tr align="center" valign="top">

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-40.jpg" target="_blank"><img src="/assets/images/unit-6/6-40.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-41.jpg" target="_blank"><img src="/assets/images/unit-6/6-41.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline">

        face</td>

    <td class="byline">

        reverse</td>

  </tr>

  <tr align="center" valign="top">

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-42.jpg" target="_blank"><img src="/assets/images/unit-6/6-42.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

    <td></td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline">edge of fabric</td>

    <td></td>

  </tr>

</table>

<h3>Tufted</h3>

<p>Tufted fabrics are created by sewing and looping additional yarn into a base fabric, creating a three dimensional textured fabric. Usually needles carrying the additional yarn punch through the surface of the base fabric, leaving a loop of yarn on the surface. The loops may be of consistent height or vary; they may be left as loops, cut or partially cut to create a variety of effects. Tufting is most commonly used in the production of carpets, rugs, and upholstery fabrics.</p>

<table border="0" cellspacing="0" cellpadding="10">

  <tr align="center" valign="top">

    <td class="byline" colspan="2">Tufted rug</td>

  </tr>

  <tr align="center" valign="top">

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-43.jpg" target="_blank"><img src="/assets/images/unit-6/6-43.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-44.jpg" target="_blank"><img src="/assets/images/unit-6/6-44.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline">

        face</td>

    <td class="byline">

        reverse</td>

  </tr>

  <tr align="center" valign="top">

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-45.jpg" target="_blank"><img src="/assets/images/unit-6/6-45.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

    <td></td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline">

        base of tufts</td>

    <td></td>

  </tr>

</table>

<h3>Coated Fabrics</h3>

<p>Coated fabrics are wovens, knits or other fabrics that are covered or coated with a polymer solution. The vinyl, metallic, or other synthetic coating provides a protective layer for the base fabric. The coating may be embossed or imprinted to give the illusion of another material, such as leather. Common applications of coated fabrics include tablecloths, raincoats, shoes and wetsuits.</p>

<table border="0" cellspacing="0" cellpadding="10">

  <tr align="center" valign="top">

    <td class="byline" colspan="2">Fabric before and after coating is applied.</td>

  </tr>

  <tr align="center" valign="top">

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-46.jpg" target="_blank"><img src="/assets/images/unit-6/6-46.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-47.jpg" target="_blank"><img src="/assets/images/unit-6/6-47.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline">	face</td>

    <td class="byline">	reverse</td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline" colspan="2">View of coated fabric edge </td>

  </tr>

  <tr align="center" valign="top">

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-48.jpg" target="_blank"><img src="/assets/images/unit-6/6-48.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

    <td></td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline">edge</td>

    <td></td>

  </tr>

</table>

<h3>Laminates</h3>

<p>Laminated fabrics are two or more fabrics adhered together with a bonding agent. The laminating process is intended to improve the performance qualities of the resulting fabric. For example, many laminates combine a fabric with a foam base for the resultant cushiony and insulative qualities. Other laminates may combine loose weaves with a backing that provides strength and stability for the loosely woven fabric. Laminates are used for upholstery, apparel and other industrial purposes.</p>

<table border="0" cellspacing="0" cellpadding="10">

  <tr align="center" valign="top">

    <td class="byline" colspan="2">Laminated vinyl film</td>

  </tr>

  <tr align="center" valign="top">

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-49.jpg" target="_blank"><img src="/assets/images/unit-6/6-49.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-50.jpg" target="_blank"><img src="/assets/images/unit-6/6-50.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline">

        face</td>

    <td class="byline">

        reverse</td>

  </tr>

  <tr align="center" valign="top">

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-51.jpg" target="_blank"><img src="/assets/images/unit-6/6-51.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

    <td></td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline">edge of fabric</td>

    <td></td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline" colspan="2"><br>

      Laminated woven</td>

  </tr>

  <tr align="center" valign="top">

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-52.jpg" target="_blank"><img src="/assets/images/unit-6/6-52.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

    <td>

      <p><a href="/assets/images/unit-6/u6large/6-53.jpg" target="_blank"><img src="/assets/images/unit-6/6-53.jpg" alt="" height="188" width="250" border="0"></a></p>

    </td>

  </tr>

  <tr align="center" valign="top">

    <td class="byline">

        face</td>

    <td class="byline">

        reverse</td>

  </tr>

</table>

<h3>Objectives</h3>

<ol>

  <li>To observe the similarities and differences among various composite fabrics<li>To assess differences between stitch bonded, tufted, coated and laminate fabrics</ol>

<h3>Laboratory Terminology</h3>

<p>Be aware of the following terms as you observe the photographs showing different composite fabrics.</p>

<blockquote>

  <p><span class="boldital">Coated Fabrics:</span> Coated fabrics consist of a base fabric with a synthetic or metallic material added as a coating. Does the fabric have a base fabric different from the coating? Does the fabric appear to have a vinyl or synthetic coating?</p>

  <p><span class="boldital">Laminates:</span> Laminated fabrics include two or more fabrics adhered to each other with a foam, fabric or other bonding agent. Does the fabric appear different on the front and reverse side? Do the layers appear to be secured by a bonding agent?</p>

  <p><span class="boldital">Stitch-Bonded Fabrics:</span>  Stitch-bonded fabrics are layers of fabric stitched, knitted, or chemically or thermally secured together.  Does the fabric appear to have layers knitted, stitched or similarly bonded together?     </p>

  <p><span class="boldital">Tufted Fabrics:</span>   Tufted fabrics are textured fabrics created by sewing and looping additional yarn into a base fabric.  Does the fabric have a looped or pile surface?  </p>

</blockquote>

<h3>Observations</h3>

<p>This exercise is intended to provide an understanding of composite fabrics as well as some of their varieties. You will be shown a series of photographs of different composite fabrics. While viewing the fabrics note the qualities and describe the observable differences. Classify each fabric as stitch-bonded, tufted, coated or laminated based on your observations. Record your notes on the related worksheet.</p>

<h3>Tests</h3>



<table border="0" cellspacing="0" cellpadding="10">

<tr align="center" valign="top">

<td class="byline" colspan="2">Composite 1</td>

</tr>

<tr align="center" valign="top">

<td><a href="/assets/images/unit-6/u6large/6-57.jpg" target="_blank"><img src="/assets/images/unit-6/6-57.jpg" alt="" height="188" width="250" border="0"></a></td>

<td><a href="/assets/images/unit-6/u6large/6-58.jpg" target="_blank"><img src="/assets/images/unit-6/6-58.jpg" alt="" height="188" width="250" border="0"></a></td>

</tr>

<tr align="center" valign="top">

<td class="byline"> face</td>

<td class="byline"> reverse</td>

</tr>

<tr align="center" valign="top">

<td><a href="/assets/images/unit-6/u6large/6-59.jpg" target="_blank"><img src="/assets/images/unit-6/6-59.jpg" alt="" height="188" width="250" border="0"></a></td>

<td> </td>

</tr>

<tr align="center" valign="top">

<td class="byline">edge of fabric</td>

<td> </td>

</tr>

<tr align="center" valign="top">

<td class="byline" colspan="2"><br>

  Composite 2</td>

</tr>

<tr align="center" valign="top">

<td><a href="/assets/images/unit-6/u6large/6-60.jpg" target="_blank"><img src="/assets/images/unit-6/6-45.jpg" width="250" height="188"></a></td>

<td><a href="/assets/images/unit-6/u6large/6-61.jpg" target="_blank"><img src="/assets/images/unit-6/6-44.jpg" width="250" height="188"></a></td>

</tr>

<tr align="center" valign="top">

<td class="byline"> surface</td>

<td class="byline"> reverse</td>

</tr>

<tr align="center" valign="top">

<td class="byline" colspan="2"><br>

  Composite 3</td>

</tr>

<tr align="center" valign="top">

<td><a href="/assets/images/unit-6/u6large/6-63.jpg" target="_blank"><img src="/assets/images/unit-6/6-63.jpg" alt="" height="188" width="250" border="0"></a></td>

<td><a href="/assets/images/unit-6/u6large/6-64.jpg" target="_blank"><img src="/assets/images/unit-6/6-64.jpg" alt="" height="188" width="250" border="0"></a></td>

</tr>

<tr align="center" valign="top">

<td class="byline"> face</td>

<td class="byline"> reverse</td>

</tr>

<tr align="center" valign="top">

<td><a href="/assets/images/unit-6/u6large/6-65.jpg" target="_blank"><img src="/assets/images/unit-6/6-65.jpg" alt="" height="188" width="250" border="0"></a></td>

<td> </td>

</tr>

<tr align="center" valign="top">

<td class="byline">edge of fabric</td>

<td> </td>

</tr>

</table>
