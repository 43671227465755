<div align="right">
  <p><img src="/assets/images/unit-4/conclusion.gif" alt="" height="37" width="189" align="left" border="0"><a href="u_4_4.html" target="_blank">Printable Page</a></p>
</div>
<h3><br>
  Threads of History</h3>
<p>Many people say that the Egyptians were the first to perform the art of weaving, but earlier evidence has been found from the area known today as Turkey.  Evidence suggests that most early weavings were constructed on a two-bar loom known as the horizontal loom.  The horizontal loom consisted of warp stretched between two beams staked out by four pegs pounded into the ground.  The first record of this weaving device is depicted on a pottery dish and tomb paintings from the Middle Kingdom.  A similar loom is still used today by people in the deserts of Northeastern Africa.</p>
<p>The handloom was devised about 2,000 years ago and was brought to England by the Romans. For centuries handloom weaving had been unchanged and was carried out on the basis of the shuttle bearing the yarn being passed slowly and awkwardly from one hand to the other. In 1733, John Kay patented his flying shuttle that dramatically increased the speed of this process. Kay placed shuttle boxes on each side of the loom connected by a long board, known as a shuttle race. By means of cords attached to a picking peg, a single weaver, using one hand, could cause the shuttle to be knocked back and forth across the loom from one shuttle box to the other. The speed of the weaving was doubled and a single weaver could make wider cloth. After visiting a factory in 1784, Edmund Cartwright began working on a machine that would improve the speed and quality of weaving. In 1785 Cartwright took out a patent for his power loom. His first version of the loom was powered by an ox and capstan but later was adapted for steam automation.<sup>13</sup></p>
<h3>Summary Questions	</h3>
<ol>
  <li>What are some of the distinguishing characteristics of a woven fabric?
  <li>How are basic and fancy weaves different?
  <li>How could one determine if a fabric is a plain, twill or satin weave?
  <li>What applications would be most appropriate for twill weaves?
  <li>What characteristics distinguish each of the fancy weaves?
  <li>How could you identify if a fabric is a dobby or a jacquard weave?
  <li>If you were selecting a woven fabric for a particular design, what considerations about the weave would you want to take into consideration?</ol>
