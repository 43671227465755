<div align="right">
  <p><img src="/assets/images/unit-9/introduction.gif" alt="" height="37" width="204" align="left" border="0"><a href="u_9_1.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  The way people use, clean and store textile products influences the life and quality of fibers, yarns, fabrics and finishes. Proper use and care of textiles and textile products will preserve the quality and appearance of fibers, yarns, fabrics and finishes. However, improper use and care can damage the product and may render it unsightly or unsuitable for further use. Having an understanding of the processes used to appropriately care for and clean textile products will enable the owner to extend the life of their textile products and to protect their investment.</p>
<p>The first line of defense is proper care of textiles. Abrasion, tears and stretching of fabrics may not be reversible. Even with the best cleaning techniques, not all stains or soiling can be removed. Therefore, preventing excessive wear, tear and soiling should be a priority. Textiles such as decorative upholsteries and fancy apparel fabrics of considerable cost should be given special care. However, some items such as children&rsquo;s play clothes and carpets in major traffic paths are expected to receive considerable wear and tear and should be selected and used with their function in mind.</p>
<p>Textile products can be restored and preserved through proper cleaning techniques. Although many textile products have labels indicating proper procedures for cleaning, the consumer must use their best judgment in caring for their textile products. The appropriateness of the cleaning technique depends on the type of textile, the construction of the finished product, and the nature of soiling. The textile, including its fibers, yarns and fabric construction, influences the cleaning techniques that should be used. For example, cotton polyester blends in a rugged twill weave can generally be washed, whereas silk and wool blended fancy yarns in a loose weave will require special care. Likewise, products designed with intricate detail or loose stitching often require special care. In addition, the extent and type of soiling plays a major impact on the cleaning process that should be used.</p>
<p>In this unit, the focus is on the processes of stain removal, laundering and dry cleaning, as well as information provided on care labels. Each of the cleaning processes will be discussed and examples will be shown. Information on care labels will also be described and illustrated. The examples shown in this unit are basic applications. Yet, professional cleaners often apply more complex versions of these techniques in order to effectively and safely restore textiles. Their techniques would be specifically selected depending on the soiling, textile fibers or combination of fibers, and product construction. Textile historians would also use more advanced versions of these techniques to restore and preserve historic textiles.</p>
<h3>Terminology</h3>
<p>Be aware of the following terms used in this unit.</p>

<blockquote>
<p><span class="boldital">Care Labels:</span> Care labels are permanent labels
attached to textile products that provide instructions on appropriately caring
for the product.</p>
<p><span class="boldital">Cleaning:</span> Cleaning methods include laundering,
dry cleaning and other specialized methods for removing stains and soil from
textile goods. </p>
</blockquote>
<h3>Reference to Worksheets</h3>

<p>To complete this unit, refer to the worksheet for Unit 9: Care of Textile Products
to record observations.</p>
