import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'unit-1', loadChildren: './pages/unit_1/unit-1.module#Unit1Module' },
  { path: 'unit-2', loadChildren: './pages/unit_2/unit-2.module#Unit2Module' },
  { path: 'unit-3', loadChildren: './pages/unit_3/unit-3.module#Unit3Module' },
  { path: 'unit-4', loadChildren: './pages/unit_4/unit-4.module#Unit4Module' },
  { path: 'unit-5', loadChildren: './pages/unit_5/unit-5.module#Unit5Module' },
  { path: 'unit-6', loadChildren: './pages/unit_6/unit-6.module#Unit6Module' },
  { path: 'unit-7', loadChildren: './pages/unit_7/unit-7.module#Unit7Module' },
  { path: 'unit-8', loadChildren: './pages/unit_8/unit-8.module#Unit8Module' },
  { path: 'unit-9', loadChildren: './pages/unit_9/unit-9.module#Unit9Module' },
  { path: 'unit-10', loadChildren: './pages/unit_10/unit-10.module#Unit10Module' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
