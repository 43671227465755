import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Unit1RoutingModule } from './unit-1-routing.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, Unit1RoutingModule],
  declarations: [Unit1RoutingModule.components]
})

export class Unit1Module { }
