import { Unit4ConclusionComponent } from './unit4-conclusion/unit4-conclusion.component';
import { Unit4IntroductionComponent } from './unit4-introduction/unit4-introduction.component';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PlainWeavesComponent } from './plain-weaves/plain-weaves.component';
import { FancyWeavesComponent } from './fancy-weaves/fancy-weaves.component';

const routes: Routes = [
  { path: 'plain-weaves', component: PlainWeavesComponent },
  { path: 'fancy-weaves', component: FancyWeavesComponent },
  { path: 'introduction', component: Unit4IntroductionComponent },
  { path: 'conclusion', component: Unit4ConclusionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class Unit4RoutingModule {
  static components = [ PlainWeavesComponent, FancyWeavesComponent, Unit4IntroductionComponent, Unit4ConclusionComponent ]
}
