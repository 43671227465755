<div align="right">
  <p><img src="/assets/images/unit-10/involuntary.gif" alt="" height="37" width="338" align="left" border="0"><a href="u_10_2.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Congress has passed a number of statutes dealing with health, safety and environmental issues, as well as fair trade practices among businesses, which regulate the production, sale, care, and disposal of textiles and textile products. A number of federal agencies also have the power to create &ldquo;involuntary regulations&rdquo; through administrative action; these regulations have the same force of law as a statute passed by congress. Both legislation and administrative regulations constitute &ldquo;involuntary regulations&rdquo; in the sense that each requires mandatory compliance with their terms. Some of the regulations intended to protect consumers, as well as insure fair trade practices among textile producers and textile product manufacturers, are discussed below.</p>
<h3>Silk Labeling</h3>
<p>Silks are typically &ldquo;weighted&rdquo;, meaning that they are specially treated to increase the weight and improve the hand and dye characteristics. Weighting diminishes the durability of silk. Therefore, in the 1930s, the Federal Trade Commission (FTC) ruled that products labeled as pure or dyed silk could contain a maximum of 10 percent weighting, except for those dyed black which could contain up to 15 percent weighting. All other silks must be labeled as weighted silks.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Silk label</td>
    <td class="byline">Pure silk label</td>
  </tr>
  <tr align="center">
    <td>
      <div align="center">
        <p><a href="/assets/images/unit-10/u10large/10-1.jpg" target="_blank"><img src="/assets/images/unit-10/10-1.jpg" alt="" height="188" width="250" border="0"></a></p>
      </div>
    </td>
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-2.jpg" target="_blank"><img src="/assets/images/unit-10/10-2.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>
<h3>Wool Labeling</h3>

<p>Wools are often blended with other fibers to improve the fabric properties
and to reduce the cost. However, it was virtually impossible to know if a wool
was pure wool or had been blended. As a result, the Wool Products Labeling Act
was passed in 1939 and later amended in 1986. The Federal Trade Commission (FTC)
requires that wool products must carry labels with certain information. Information
must include the name or identification number of the manufacturer, the country
of origin, and the percentage of the fiber content. The terms wool, virgin wool
or new wool may be used for wool fibers that have not been used before. The
term lambswool is allowed to be used and refers to wool from the first shearing.
Lambswool is softer and finer than wool from subsequent shearings. Recycled
wool must be used to refer to wool that has been previously used. Rugs and carpets
as well as upholstery fabrics are exempt from the wool labeling requirements.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Wool label</td>
    <td class="byline">Pure wool label</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-3.jpg" target="_blank"><img src="/assets/images/unit-10/10-3.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-4.jpg" target="_blank"><img src="/assets/images/unit-10/10-4.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Virgin wool label</td>
    <td class="byline"><br>
      New wool label</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-5.jpg" target="_blank"><img src="/assets/images/unit-10/10-5.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-6.jpg" target="_blank"><img src="/assets/images/unit-10/10-6.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Lambswool label</td>
    <td></td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-7.jpg" target="_blank"><img src="/assets/images/unit-10/10-7.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td></td>
  </tr>
</table>
<h3>Fur Labeling</h3>
<p>Many furs were dyed, sheared or redesigned in some way to resemble more expensive furs. When consumers purchased furs, they didn&rsquo;t know if they were buying an authentic fur or simulated variety. In response, the Fur Products Labeling Act was passed in 1951 and later amended in 1980. The Federal Trade Commission (FTC) now requires the label to contain the name of the animal in English and the country of origin. If it has been dyed in any way, that fact must also be indicated on the label of all fur apparel products.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Fur label</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-8.jpg" target="_blank"><img src="/assets/images/unit-10/10-8.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>
<h3>Flammable Fabrics Act</h3>

<p>Some textiles create an undue fire hazard. It was found that various textiles
were involved in a number of deaths. As a result, the Flammable Fabrics Act
was passed in 1953, amended in 1967 and again in 1996. The original Flammable
Fabrics Act restricted the use of highly flammable fabrics in apparel. The Consumer
Product Safety Commission (CPSC) now restricts the use of flammable fabrics
for children&rsquo;s sleepwear (sizes infant through 14), mattresses, and most
carpets. Any carpets or rugs not meeting appropriate standards must be labeled
as not conforming to the promulgated flammability standards. Children&rsquo;s
sleepwear must give clear instructions for care on a permanently attached label.
The amended Flammable Fabrics Act also allows the CPSC to adjust the standards
as research uncovers and defines additional needs.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Product label indicates compliance with Flammable Fabrics Act</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-10.jpg" target="_blank"><img src="/assets/images/unit-10/10-10.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>
<h3>Textile Fiber Products Labeling</h3>

<p>As more synthetic fibers were developed, and as both natural and synthetic
fibers were blended to produce various characteristics, it became increasingly
difficult to identify the fiber content of a textile product. To allow consumers
to clearly identify the fibers in a textile product, the Textile Fiber Products
Identification Act (TFPIA) was enacted in 1960 and later amended in 1986. The
Federal Trade Commission (FTC) requires that textile products carry a permanent
label that clearly lists the generic names of fibers in order of their weight.
Labels must identify the percentage of each fiber that contributes more than
five percent of the total weight. The labels must also list the country of origin
and manufacturer&rsquo;s number or identification name.</p>
<table width="540" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Fiber content, manufacturer&rsquo;s number and country of origin must be listed on the label</td>
    <td class="byline">When fibers make up less than five percent of the total fiber content, they can be listed as &ldquo;other fibers&rdquo;</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-10/u10large/10-11.jpg" target="_blank"><img src="/assets/images/unit-10/10-11.jpg" alt="" height="333" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-10/u10large/10-12.jpg" target="_blank"><img src="/assets/images/unit-10/10-12.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Fibers in all components of a product must be labeled</td>
    <td>
      <p>
      </p>
    </td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-10/u10large/10-13.jpg" target="_blank"><img src="/assets/images/unit-10/10-13.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td></td>
  </tr>
</table>
<h3>Permanent Care Labeling</h3>
<p>With the variety of blended fibers in fabrics and the use of multiple fabrics in finished products, it&rsquo;s not surprising that consumers had a difficult time determining the appropriate type of care for textile products. Consequently, the Federal Trade Commission (FTC) promulgated the Care Labeling Regulation in 1972 and later amended it in 1984. As discussed in Unit 9, most apparel products and yard goods are now required to carry a permanent label that provides care instructions. Instructions can be indicated via written language, international care symbols or both.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Care label with symbolic and verbal instructions</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-14.jpg" target="_blank"><img src="/assets/images/unit-10/10-14.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>
<p>The following exercise will provide a general understanding of the application of involuntary regulations. After viewing the labels that might be found on textile products, you should be able to assess the information provided and identify which involuntary regulations have mandated the information provided.</p>
<h3>Objectives</h3>
<ol>
  <li>To observe examples of involuntary regulations on textiles and textile products
  <li>To identify and describe evidence of involuntary regulations
</ol>
<h3>Observations</h3>
<p>This exercise is intended to illustrate evidence of involuntary regulations on labels that might be seen on textile products. You will first be shown an example of a label on a textile product and provided with an explanation as to how involuntary regulations have influenced the information on the label. You will then be shown a series of photographs of textile product labels. Carefully view the labels and note your observations. Identify and describe how involuntary standards have influenced the information provided. What mandatory information is provided? What involuntary standards have influenced the provision of the information on the label? What additional information is provided on the label?</p>
<h3>Sample Test</h3>
<p>
  <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="80" height="40">
        <param name="movie" value="c10st.swf">
        <param name="quality" value="high">
        <embed src="c10st.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="80" height="40"></embed>
    </object> -->
    <video width="500" height="50" controls>
      <source src="/assets/videos/unit-10/c10st.mp4" type="video/mp4">
      Your browser does not support the video tag
    </video>
</p>
<table width="294" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-15.jpg" target="_blank"><img src="/assets/images/unit-10/10-15.jpg" alt="" height="188" width="250" border="0"></a>

      </p>
    </td>
    <td>
      <p><a href="/assets/images/unit-10/u10large/10-16.jpg" target="_blank"><img src="/assets/images/unit-10/10-16.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td><span class="byline">front</span></td>
    <td><span class="byline">reverse</span></td>
  </tr>
</table>
<h3>Tests</h3>
<table width="294" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Involuntary Regulations Label 1</td>
    <td class="byline">Involuntary Regulations Label 2</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-10/u10large/10-17.jpg" target="_blank"><img src="/assets/images/unit-10/10-17.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-10/u10large/10-18.jpg" target="_blank"><img src="/assets/images/unit-10/10-18.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Involuntary Regulations Label 3</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-10/u10large/10-19.jpg" target="_blank"><img src="/assets/images/unit-10/10-19.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-10/u10large/10-20.jpg" target="_blank"><img src="/assets/images/unit-10/10-20.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td><span class="byline">front</span></td>
    <td><span class="byline">reverse</span></td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Involuntary Regulations Label 4</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-10/u10large/10-21.jpg" target="_blank"><img src="/assets/images/unit-10/10-21.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-10/u10large/10-22.jpg" target="_blank"><img src="/assets/images/unit-10/10-22.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td><span class="byline">front</span></td>
    <td><span class="byline">reverse</span></td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Involuntary Regulations Label 5</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-10/u10large/10-23.JPG" target="_blank"><img src="/assets/images/unit-10/10-23.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-10/u10large/10-24.JPG"><img src="/assets/images/unit-10/10-24.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td><span class="byline">front</span></td>
    <td><span class="byline">reverse</span></td>
  </tr>
</table>
