<div align="right">
  <p><img src="/assets/images/unit-7/dyeing.gif" alt="" height="37" width="137" align="left" border="0"><a href="u_7_2.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Whether the designer is attempting to keep in tune with the latest fashion colors, trying to show association with a group using team or group colors, or trying to insure safety through bright and noticeable colors, the colors of textiles are important to the overall design of the product. Special care must be taken in the dyeing process to achieve the exact desired color. The process becomes even more complicated when items of various fibers in a collection, often produced around the world, need to match; or when items are expected to match after being cleaned or laundered; or even after exposure to the elements for a period of time. For example, when a manufacturer produces a line of towels, rugs, shower curtains, bed linens and other assorted pieces, the consumer expects to be able to purchase towels now, add a shower curtain later, and possibly add another towel or throw rugs sometime in the future. All the while, the consumer uses the textiles and launders them, but still expects that the existing and new pieces will always match reasonably well. Meeting these demands requires selective skills in the dyeing process.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Color variation when same dye is used on various fibers</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-7/u7large/7-1.jpg" target="_blank"><img src="/assets/images/unit-7/7-1.jpg" alt="" height="180" width="250" border="0"></a></td>
  </tr>
</table>

<p>A variety of factors in the dyeing process influence the finished good. The
fibers, the dyes, the stage in the development of the textile, and the methods
of dyeing all influence the result of the dyeing process.</p>
<h3>Dyes</h3>

<p>Textile color can be achieved using different coloring agents. Dyes penetrate
the fibers of the textiles leaving them colored from every angle. Dyes may be
natural or synthetic. Natural dyes have been successfully used for centuries.
Natural dyes, however, may not be appropriate for all types of fibers and often
lack the colorfastness qualities demanded by modern consumers. Synthetic dyes
have virtually replaced natural dyes in the mass production of textiles. Their
superior colorfastness qualities, infinite array of colors, and versatility
in dyeing assorted and combinations of fibers has made synthetic dyes irreplaceable.
A variety of synthetic dyes exists which are used for specialized color applications.
Dyes and the dyeing process have become very technical and scientific in order
to achieve the desired outcome in today&rsquo;s textile production. Pigments
are also used to add color to fabrics. Unlike dyes, they are insoluble. Pigments
impart color by adhering to the surface of a fabric. Consequently, pigments
are generally reserved for the printing of fabrics or used in the mass coloration
of man-made fibers.</p>

<p>Colorfastness, crocking and bleeding are common concerns associated with dyes,
but can be controlled through proper dyes and dyeing methods. Colorfastness
is the ability of a fabric to maintain its color throughout ordinary use and
care. Colorfastness is observed in swimwear or draperies that maintain their
color after extensive exposure to the sun as well as in children&rsquo;s apparel
or home linens that have undergone repetitive laundering. Crocking occurs when
the color or dye of one fabric rubs off onto another fabric or object. For example,
you may have worn a garment where the color appeared on your skin after the
garment was removed. Bleeding refers to the dye or color redistributing to other
products during the laundering process. An infamous example is the case of a
red towel being washed with white laundry and all the laundry turning shades
of pink.</p>
<h3>Stage in Textile Development</h3>
<p>Textiles can be dyed during any of the various stages of their processing. Synthetic textile solutions can be dyed before the solution is formed into fibers or yarns. A simple example of this can be observed in various colored filament fishing lines. Fibers, whether natural or synthetic, can be dyed. Dyeing during this stage allows yarns to be made with multiple colors of fibers, as in tweed yarns. Dyeing during this stage also allows for more uniform coloration in thick or hard to dye yarns.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Solution dyed filament yarns</td>
    <td class="byline">Dyed fibers</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-7/u7large/7-2.jpg" target="_blank"><img src="/assets/images/unit-7/7-2.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td class="byline"><a href="/assets/images/unit-7/u7large/7-3.jpg" target="_blank"><img src="/assets/images/unit-7/7-3.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      Dyed fibers spun into yarns and woven into fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-4.jpg" target="_blank"><img src="/assets/images/unit-7/7-4.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-5.jpg" target="_blank"><img src="/assets/images/unit-7/7-5.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2" class="byline"><br>
      Dyed fibers spun into yarns and knit into fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-6.jpg" target="_blank"><img src="/assets/images/unit-7/7-6.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-7.jpg" target="_blank"><img src="/assets/images/unit-7/7-7.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>If yarns are produced from undyed fibers, they can later be dyed. Dyeing yarns allows for an even or variegated color over the length of the yarn. Dyed yarns are often used in the production of woven stripes and plaids, such as those used in men&rsquo;s suiting.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr>
    <td class="byline" colspan="2" align="center" valign="top">Dyed yarns woven into fabrics</td>
  </tr>
  <tr>
    <td align="center" valign="top"><a href="/assets/images/unit-7/u7large/7-8.jpg" target="_blank"><img src="/assets/images/unit-7/7-8.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-9.jpg" target="_blank"><img src="/assets/images/unit-7/7-9.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>After the fabric has been constructed the entire piece may be dyed.  Piece dyeing is more efficient and less expensive than fiber or yarn dyeing if the fabric is intended to be a solid color.  </p>
<table width="540" border="0" cellspacing="0" cellpadding="10">
  <tr>
    <td class="byline" colspan="2" align="center" valign="top">Dyed fabric. Notice the slightly lighter areas on the exposed yarns where dye would not have penetrated through the fabric.</td>
  </tr>
  <tr>
    <td align="center" valign="top"><a href="/assets/images/unit-7/u7large/7-10.jpg" target="_blank"><img src="/assets/images/unit-7/7-10.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-11.jpg" target="_blank"><img src="/assets/images/unit-7/7-11.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>In some cases, the fabric may be cut, sewn and in its final form before it is dyed.  Garment or product dyeing is very efficient if all of the materials can take on the dye evenly.  Another benefit of product dyeing is that it allows manufacturers to quickly respond to changes in demand for fashion colors.       </p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Dyed garment.  Notice all components have been dyed shades of the same color.</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-7/u7large/7-12.jpg" target="_blank"><img src="/assets/images/unit-7/7-12.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Methods</h3>

<p>As previously indicated, the process of dyeing textiles is extremely technical
and scientific. However, the processes used are variations of a few basic methods.
In the batch method the textile is immersed into the dye, removed, and sent
to another stage of the production process. Batch dyeing can be successfully
used at any stage of the production cycle. Package dyeing is generally used
for dyeing yarns and is a process where the dye is forced through the yarn.
For larger quantities of fabric or yarns that can undergo tension while wet,
the continuous method is most frequently used. In the continuous method, the
textile is pulled through the dye, then through a process to remove the excess
dye, and later through a chamber to thermal-set the dye.</p>
<h3>Objectives</h3>
<ol>
  <li>To observe the results of dyeing
  <li>To note differences in results of textiles dyed in various stages of textile production
  <li>To observe effects of colorfastness, crocking and bleeding
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you observe the photographs showing different dyed textiles.</p>

<blockquote>
<p><span class="boldital">Bleeding:</span> Bleeding is the tendency of the dyes
of one fabric to be passed onto another fabric when wet or during the laundering
process. Does the fabric impart color on other objects or textiles when wet?
</p>
<p> <span class="boldital">Colorfastness:</span> Colorfastness is the ability
of a fabric to retain its color through ordinary use and care. Does the fabric
gradually change color over time? Does the textile resist fading or changing
color after laundering? </p>
<p><span class="boldital">Crocking: </span> Crocking is the tendency of the
dyes of a fabric to rub off onto another fabric or item. Does the color of
one object rub off onto another? Does an object take on the color of another
textile after direct contact? </p>
</blockquote>
<h3>Observations</h3>
<p>This exercise is designed to illustrate the effects of dyeing at various stages of textile production. You will first be shown an example of a textile and provided an explanation as to how one can determine where in production stage it was dyed. You will then be shown a series of photographs depicting textiles dyed at various stages of the textile production cycle. View the textiles and carefully note your observations. Determine when in the textile production cycle the dyeing most likely occurred and provide a justification for your determination. Record your notes on the related worksheet. At which stage of the production cycle was the textile most likely dyed? What clues enable you to make this determination? Could the textile have been dyed at more than one stage in the production cycle? At which stages could the textile have been dyed?</p>
<h3>Sample Test</h3>
<p>
  <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="80" height="40">
        <param name="movie" value="c7st.swf">
        <param name="quality" value="high">
        <embed src="c7st.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="80" height="40"></embed>
    </object> -->
    <video width="500" height="50" controls>
      <source src="/assets/videos/unit-7/c7st.mp4" type="video/mp4">
      Your browser does not support the video tag
    </video>
</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-13.jpg" target="_blank"><img src="/assets/images/unit-7/7-13.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-14.jpg" target="_blank"><img src="/assets/images/unit-7/7-14.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">

<td class="byline">yarns</td>
    <td class="byline">fabric</td>
  </tr>
</table>
<h3>Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline" colspan="2">Dyeing 1</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-15.jpg" target="_blank"><img src="/assets/images/unit-7/7-15.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-16.jpg" target="_blank"><img src="/assets/images/unit-7/7-16.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">yarns</td>
    <td class="byline">fabric</td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Dyeing 2</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-17.jpg" target="_blank"><img src="/assets/images/unit-7/7-17.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-18.jpg" target="_blank"><img src="/assets/images/unit-7/7-18.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">yarns</td>
    <td class="byline">fabric</td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Dyeing 3</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-19.jpg" target="_blank"><img src="/assets/images/unit-7/7-19.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-20.jpg" target="_blank"><img src="/assets/images/unit-7/7-20.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">yarns</td>
    <td class="byline">fabric</td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Dyeing 4</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-21.jpg" target="_blank"><img src="/assets/images/unit-7/7-21.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-22.jpg" target="_blank"><img src="/assets/images/unit-7/7-22.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
