<div align="right">
  <p><img src="/assets/images/unit-2/introduction.gif" alt="" height="37" width="206" align="left" border="0"><a href="u_2_1.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
You have already found that fibers can be identified using a variety of tests.
However, fibers also have varying degrees of inherent characteristics or properties
that differentiate them from each other. As fibers are formed into yarns and
fabrics, they acquire additional properties. Finishes also influence the properties
of fabrics. Consequently, yarns and fabrics from the same fibers may possess
very different properties. Some textiles are strong while others are not; some
stretch while others retain their shape; and some textiles are absorbent while
others seem to repel water. For some end uses, a certain set of properties is
highly desirable; yet for other end uses, the same set of properties may be
a detriment. For example, it is desirable for a bath towel to absorb water,
but it would not be advantageous for a raincoat to absorb water.&nbsp;</p>

<p>Textile products can be enhanced and made more serviceable by selecting textiles
that combine a certain set of desirable properties. Take for example jogging
apparel, where elasticity, absorbency, resistance to light and other natural
elements, and easy care are often important features. Or, the example of car
upholstery, where abrasion resistance, dimensional stability, heat retention,
and resistance to sunlight become major factors.&nbsp;</p>
<p>What are textile properties? Textile properties are characteristics inherent in the composition of the fiber, construction of the yarn and fabric, and finishing of the textile. The combination of characteristics gives the textile a unique set of properties. Several textile properties are listed in the table below. Each of these properties can be measured through a different scientific method.&nbsp;</p>
<p>&nbsp;</p>
<table border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <tr bgcolor="black">
    <th colspan="3" valign="top" width="479">
      <p align="center">TEXTILE PROPERTIES ASSESSED THROUGH TESTING METHODS</p>
    </th>
  </tr>
  <tr bgcolor="black">
    <td valign="top" width="158">
      <p>Abrasion Resistance</p>
    </td>
    <td valign="top" width="159">
      <p>Flame Resistance</p>
    </td>
    <td valign="top" width="158">
      <p>Shrinkage</p>
    </td>
  </tr>
  <tr bgcolor="black">
    <td valign="top" width="158">
      <p>Absorbency</p>
    </td>
    <td valign="top" width="159">
      <p>Heat Sensitivity</p>
    </td>
    <td valign="top" width="158">
      <p>Snag Resistance</p>
    </td>
  </tr>
  <tr bgcolor="black">
    <td valign="top" width="158">
      <p>Air Permeability</p>
    </td>
    <td valign="top" width="159">
      <p>Insect Resistance</p>
    </td>
    <td valign="top" width="158">
      <p>Specific Gravity</p>
    </td>
  </tr>
  <tr bgcolor="black">
    <td valign="top" width="158">
      <p>Colorfastness</p>
    </td>
    <td valign="top" width="159">
      <p>Mildew Resistance</p>
    </td>
    <td valign="top" width="158">
      <p>Stain Resistance</p>
    </td>
  </tr>
  <tr bgcolor="black">
    <td valign="top" width="158">
      <p>Crocking</p>
    </td>
    <td valign="top" width="159">
      <p>Piling</p>
    </td>
    <td valign="top" width="158">
      <p>Tenacity</p>
    </td>
  </tr>
  <tr bgcolor="black">
    <td valign="top" width="158">
      <p>Dimensional Stability</p>
    </td>
    <td valign="top" width="159">
      <p>Resiliency</p>
    </td>
    <td valign="top" width="158">
      <p>Ultraviolet Resistance</p>
    </td>
  </tr>
  <tr bgcolor="black">
    <td valign="top" width="158">
      <p>Durability</p>
    </td>
    <td valign="top" width="159">
      <p>&nbsp;</p>
    </td>
    <td valign="top" width="158">
      <p>Water Resistance</p>
    </td>
  </tr>
  <tr bgcolor="black">
    <td valign="top" width="158">
      <p>Elasticity</p>
    </td>
    <td valign="top" width="159">
      <p>&nbsp;</p>
    </td>
    <td valign="top" width="158">
      <p>Wind Resistance</p>
    </td>
  </tr>
  <tr bgcolor="black">
    <td valign="top" width="158">
      <p>Elongation</p>
    </td>
    <td valign="top" width="159">
      <p>&nbsp;</p>
    </td>
    <td valign="top" width="158">
      <p>Wrinkle Resistance</p>
    </td>
  </tr>
</table>
<p>Although some textile properties are easily identified, most textiles need to be tested in a more scientific setting to truly assess the degree of the various properties. In this unit, the focus is on the assessment of textile properties through standardized technical testing procedures and through basic non-technical methods.&nbsp;</p>

<p>Standardized, scientific tests for measuring the properties and performance
of textiles have been established by private and governmental agencies. The
testing procedures delineate various steps that often require highly sophisticated
technical testing equipment and specially trained technicians to effectively
test for slight variations in textiles. The capital equipment and overhead costs
associated with performing scientific, standardized testing is often prohibitive.
However, for companies producing fibers, yarns, fabrics and finished textile
products in large quantities, the costs of such testing may far outweigh the
ultimate cost of producing poorly performing products. Likewise, for specialized
applications, the ability to develop textiles and textile products with unique
properties is critical and the use of standardized testing procedures to ensure
the quality of these unique properties is essential.</p>
<p>Although technical, standardized tests are extremely valuable in assessing the qualities of textiles and textile products, the cost, time, and need for specialized equipment and personnel may prohibit their use in many practical applications. Fortunately, several simple, non-technical tests can be used by consumers, designers, buyers, or small businesses to assess the basic quality of a textile or textile product.&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>The tests shown in this unit are a small but representative sample of both standardized procedures and non-technical methods for assessing textile properties. Professional textile scientists often use additional tests, more complex versions of these same techniques, and note smaller tolerances when analyzing textile performance.</p>
<h3>Terminology</h3>
<p>Be aware of the following terms used in this unit.</p>

<blockquote>
<p><b><i>Absorbency: </i></b>Absorbency is the ability of a fiber to take in
and retain moisture.</p>
<p><b><i>Colorfastness:</i></b> Colorfastness is the ability of fibers or fabrics
to retain their color through use and care such as after exposure to elements
like sunlight, cleaning processes, or contact with skin or other materials.</p>
<p><b><i>Dimensional stability: </i></b>Dimensional stability is the ability
of fabrics to retain their shape after general use and care, including stretching
and laundering.</p>
<p><b><i>Non-technical textile assessment: </i></b>Using simple methods with
few scientific controls for examining textile properties.</p>
<p><b><i>Resiliency: </i></b>Resiliency is the ability of a fiber or fabric
to bounce back to its original position after being folded, bent, twisted,
crushed, creased or subjected to other means of deformation.</p>
<p><b><i>Standardized testing: </i></b>Using widely accepted, scientific, systematic
testing procedures to assess textile properties.</p>

<p><b><i>Tenacity</i></b><b>:</b> Tenacity refers to the strength of the textile
fibers. It is the ability of the fibers to withstand breakage.</p>
  </blockquote>
<h3>Reference to Worksheets</h3>

<p>To complete this unit, refer to the worksheet for Unit 2: Textile Properties to record observations. Textbooks and other reference materials may be helpful in completing this unit.</p>
