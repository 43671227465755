<div align="right">
  <p><img src="/assets/images/unit-3/conclusion.gif" alt="" height="37" width="189" align="left" border="0"><a href="u_3_6.html" target="_blank">Printable Page</a></p>
</div>
<h3><br>
  Threads of History</h3>
<p>Originally, the spinning of cotton, wool or flax was done by the distaff and spindle. The distaff, a 3-foot long stick, was held under the arm while fibers were twisted spirally between the fingers. In 1530, the first spinning wheel was invented in Nuremberg. This upright wheel was smaller in diameter and was operated with a treadle.<sup>1</sup></p>
<p>Lewis Paul invented the first mechanical spinning device in 1741. The device allowed for the feeding of long strands of fibers through a series of rollers set at different speeds. The fibers were then elongated and stretched to a bobbin-and-flyer twisting mechanism.<sup>2</sup> In 1769, Richard Arkwright invented the water frame which was very similar to Paul&rsquo;s device but was operated by water power. Shortly after the introduction of the water frame, a second spinning machine known as the spinning jenny was developed by James Hargreaves. The spinning jenny could spin up to eight yarns simultaneously by one person. The machine was later modified to spin up to one hundred yarns at a time. In 1779, Samuel Crompton combined features of the spinning jenny and water frame to produce his spinning mule. The mule used rotating rollers and a moving carriage to twist the fibers into fine, strong yarns. In the early 1800&rsquo;s a device was invented that could be attached to the spinning mule and make it completely automatic.<sup>1,2</sup> Paul, Arkwright, Hargreaves and Crompton&rsquo;s inventions are the foundation of all the spinning machines that are used today.</p>
<h3>Summary Questions	</h3>
<ol>
  <li>In what applications might a carded yarn be more desirable than a combed yarn?  In what applications might a combed yarn be more desirable than a carded yarn?
  <li>What are the benefits of loosely twisted yarns?   What are the benefits of tightly twisted yarns?
  <li>Why might a ply yarn be more advantageous than a single yarn? Why might a single yarn be more advantageous than a ply yarn?<li>What distinguishes fancy yarns from simple yarns?<li>What trade-offs might a product developer need to make when choosing among variations in yarns?

</ol>
