<div align="right">
  <p><img src="/assets/images/unit-10/introduction.gif" alt="" height="37" width="204" align="left" border="0"><a href="u_10_1.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Throughout the previous units you have observed the development of fibers, yarns and finished fabrics. By carefully manipulating the processes, the producer can create unique characteristics and properties in the resulting textile. In some cases, however, the results are not as they appear. Many textiles can be carefully engineered to mimic the qualities of more expensive, luxurious and desirable textiles. They may be passed on to consumers as the &ldquo;real thing&rdquo;. Others appear as a particular textile yet possess the undesirable characteristics of another textile.</p>
<p>Because of the complexity of today&rsquo;s textiles, consumers cannot readily identify certain fiber, yarn and fabric characteristics or their resulting properties. Therefore, without protection and clear information, consumers are at the mercy of the textile industry. Likewise, to stay competitive some textile manufacturers resort to producing and selling less costly imitations, making it difficult for producers selling higher priced, legitimate textiles to stay in business.</p>
<p>In order to protect consumers and producers alike, regulations and standards have been established. In some cases, federal, state and local governing bodies have established mandatory, involuntary regulations. In other cases, private organizations and associations have taken it upon themselves to research, test and establish voluntary standards intended for industry-wide adoption and use.</p>
<p>In this unit, the focus is on involuntary regulations and voluntary standards which have been tested, written and established for the textile industry. The basic regulations and standards discussed in this unit are described in fairly simple terms. Professional textile scientists would examine more scientific terms and means of research and testing when developing or adhering to the standards. Likewise, more complex legal versions would be carefully worded by legislative and administrative bodies when enacting statutes, regulations or other forms of law.</p>
<h3>Terminology</h3>
<p>Be aware of the following terms used in this unit.</p>
<blockquote>
  <p><span class="boldital">Involuntary Regulations:</span> Involuntary regulations are statutes and promulgated regulations enacted by a governmental body to regulate the textile industry and protect consumers.</p>
  <p><span class="boldital">Voluntary Standards:</span> Voluntary standards are those which are tested, established, approved and recommended by private associations to control the development, production and distribution of textiles and textile products and information relating thereto.</p>
</blockquote>
<h3>Reference to Worksheets</h3>

<p>To complete this unit, refer to the worksheet for Unit 10: Textile Regulations
to record observations. The textbook and other reference materials may be helpful
in completing this unit.</p>
