<div align="right">
  <p><img src="/assets/images/unit-2/absorbency.gif" alt="" height="37" width="206" align="left" border="0"><a href="u_2_5.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
The ability of a fiber to take in and retain moisture is known as its absorbency
properties. Absorbency is determined by measuring the amount of water, oil or
moisture taken on by various fibers in a set amount of time. Fibers considered
absorbent will quickly take on fluids and become heavier as they absorb the
additional moisture. Fibers that are not very absorbent will slowly take on
small amounts of moisture or even repel water, oils or other fluids and will
show little sign of weight gain. These fibers are said to be moisture repellant.</p>

<p>Absorbency qualities are logically most important in the effectiveness of bath
and kitchen towels. However, absorbency is also important in the development
of athletic apparel. In most cases it is desirable to have clothing that pulls
the moisture away from the body, leaving the athlete more comfortable. Yet,
in other instances it is important that the garment be water repellent and not
absorb or retain water, as in the case of swimwear or the outer layers of ski
wear. Lawn furniture, luggage, raincoats, and shower curtains also demand special
moisture repellent properties in fibers.</p>
<p>Several tests and testing equipment can be used to assess specific aspects of absorbency. The following tests will provide a general comparison of the absorbency rate of fabrics using scientific and non-technical measures. After viewing the tests, you should be able to assess the absorbency and moisture resistant properties of the sample fabrics. More detailed analysis, as might be performed by a textile scientist, would be necessary to measure smaller degrees of variation in absorbency and moisture resistance rates between various fibers, yarns and fabrics.&nbsp;</p>
<h3>Objectives</h3>
<ol>

<li>To observe the absorbance or repellency of sample fabrics
<li>To assess differences in the absorbency of various fabrics
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you watch the video clips showing different fabrics being tested for absorbency.</p>
<blockquote>

<p><b><i>Absorbency</i></b><b>:</b> Absorbency is the ability of fiber to take
in, absorb and retain moisture. Does the fabric soak up a lot of moisture?
Does the fabric soak up moisture quickly? Does the fabric appear dry even
after soaking up moisture?</p>

<p><b><i>Moisture Repellency:</i></b> Moisture repellency is the ability of
a fiber or fabric to not take in or absorb moisture. Does the fabric repel
moisture? &nbsp;Does the fabric repel moisture for a period of time before
starting to absorb the moisture? Does the fabric appear and feel dry after
exposure to moisture? &nbsp;</p>
</blockquote>
<h3>Standardized Procedures and Observations</h3>

<p>Several scientific tests can be used to measure the absorbency and moisture
repellency properties of fabrics. The examples shown in this section are typical
examples of absorbency and repellency tests. Both examples were conducted at
the J.C. Penney's Product Research and Technical Laboratories.&nbsp;</p>

<p>The first example is oil repellency examined by using the DuPont oil / water
repellency test kit. In this oil repellency portion of the test, six liquids
of incrementally thinner viscosities are consecutively dropped onto a test fabric.
Each of these six levels of oil is placed on the fabric in a row of five drops
and is left for 30 seconds. If the oil is not absorbed, the fabric passes the
test at that level. By identifying the level at which the consecutively placed
liquids are absorbed, the absorbency properties of a fabric can be determined.</p>
<p>The second example is the AATCC 22 spray test. In this test, a fabric sample is mounted on the testing apparatus and a specified measure of water is sprayed onto the fabric at a 45 degree angle. Excess water is tapped off the surface. Visually observable absorption of the water into fabric is then compared to a standardized rating chart. Ratings for absorbency range from 0 or virtually complete absorption to 100 with virtually no absorption.&nbsp;</p>
<p>While watching the videos note the procedures used to test for absorbency and oil repellency. In the second video, carefully note the rate of absorption. Does this fabric seem to be more absorbent or more repellent? What might be a good application for this particular fabric, based on its moisture repellency rating? What would not be a good application for this particular fabric? Record observations and conclusions on the related worksheet.</p>
<h3>Standardized Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">DuPont Oil/Water Test</td>
    <td class="byline">Spray Test</td>
  </tr>
  <tr align="center">
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-40.swf">
        <param name="quality" value="high">
        <embed src="videos/2-40.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
      </object> -->
      <video width="320" height="270" controls>
        <source src="/assets/videos/unit-2/2-40.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
      <br>
    </td>
        <td>
      <p>
        <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
          <param name="movie" value="videos/2-41.swf">
          <param name="quality" value="high">
          <embed src="videos/2-41.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
        </object> -->
        <video width="320" height="270" controls>
          <source src="/assets/videos/unit-2/2-41.mp4" type="video/mp4">
          Your browser does not support the video tag
        </video>
      </p>
    </td>
      </tr>
</table>
  <h3>Non-Technical Procedures </h3>
<p>When scientific testing facilities, equipment and methods are unavailable, some basic tests can be used to assess the absorbency and water resistance of fabrics. Although the results will be more generalized, the information may be sufficient to determine whether or not the use of a particular fabric is appropriate for a given application. In this section you will observe two of the several possible non-technical tests.</p>
<p>First observe a video clip of oil and water being carefully dropped onto three different fabrics. </p>
<p>As oil is dropped onto each of the samples, observe the extent to which the fabric absorbs the oil or the extent to which the fabric repels the oil. Does the oil spread out and absorb into the fabric? Does the oil sit atop the fabric as in a bead? If the oil is absorbed, how long does it take before all of the oil is absorbed?</p>
<p>As water is dropped onto each of the samples, observe the extent to which the fabric absorbs the water or the extent to which the fabric repels the water. Does the water spread out and absorb into the fabric? Does the water sit atop the fabric as in a bead? If the water is absorbed, how long does it take before all of the water is absorbed?</p>
<p>In the second video you will observe three strips of fabric as they are lowered into a dish of water. As the fabric is lowered into the water, note the absorption of water by each test strip. Carefully observe the extent of absorption and the degree of wicking or soaking up of water. Also, note the timing of the absorption. Which fabric appears to have absorbed the most water? Which fabric appears to have absorbed water most quickly? Which fabric appears to have absorbed the least amount? Note the conclusions that can be drawn from your observations on the applicable worksheet.</p>
<h3>Non-Technical Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Home Oil/Water Test</td>
    <td class="byline">Home Absorbency Test</td>
  </tr>
  <tr align="center">
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-45.swf">
        <param name="quality" value="high">
        <embed src="videos/2-45.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
      </object> -->
      <video width="320" height="270" controls>
        <source src="/assets/videos/unit-2/2-45.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-46.swf">
        <param name="quality" value="high">
        <embed src="videos/2-46.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
      </object> -->
      <video width="320" height="270" controls>
        <source src="/assets/videos/unit-2/2-46.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
      </tr>
</table>
