<div align="right">
  <p><img src="/assets/images/unit-10/conclusion.gif" alt="" height="37" width="189" align="left" border="0"><a href="u_10_4.html" target="_blank">Printable Page</a></p>
</div>
<h3><br>
  Fiber Power</h3>
<p>Flammability of fabrics and the results of tests in which fabrics were exposed to a flame or excessive heat have implications in a variety of areas. In some situations the relevance is so strong that laws have been enacted requiring minimum standards for fire retardation of fabrics such as children&rsquo;s sleepwear. In most situations, the appropriate level of flammability or fire retardation of fabrics is left to the discretion of the manufacturer or the consumer.</p>
<p>Much controversy has resulted from reports which show mattresses to be highly flammable, yielding little time for a sleeper to react if their bedding is exposed to a flame. What types of laws could be enacted to prevent this unfortunate circumstance? Short of enacting legislation, what types of fibers, fabrics and/or finishes should consumers look for in the construction of their mattresses in order to prevent or reduce this problem? Clearly, voluntary regulations should play a significant role in this area.</p>
<h3>Threads of History</h3>
<p>After World War II, manufacturers introduced many new fibers to the textile industry. Fiber manufacturers were not regulated in their use of new fibers and were not required to disclose the fiber content to the consumer. Consumers could not distinguish one fiber from another and they did not know if fibers were combined. This confusion led the United States Congress to pass the Textile Fiber Identification Act in the 1950s. The act regulates labeling of textiles in order to protect consumers and producers.</p>
<p>Most textile items, apparel and linens are required to list certain information in English on labels. The manufacturer must list the percentage of each fiber in order by weight. If the fiber is less than five percent, it may be listed as &ldquo;other fiber&rdquo;, unless the fiber serves an important purpose. The manufacturer&rsquo;s name or registered number must be listed and generic names are also listed with trademarks. The label must also state where the majority of the product was manufactured, processed or assembled. The country of origin for fabrics is where the fabric was finished.</p>
<h3>Textiles at Work</h3>
<p>The textile industry is one in which science and politics are involved in the many phases required to transform raw materials into a finished fabric or textile product. The Congress of the United States creates laws which set, for example, applicable standards for safety with respect to fabric items, as well as the amounts and kinds of foreign textile products which may enter into the United States. The Consumer Product Safety Commission (CPSC) is the governmental agency responsible for administering federal laws regulating product safety standards. Some states and cities also pass laws or ordinances which further define standards for consumer products.</p>
<p>In addition to the governmental bodies responsible for establishing standards for textiles, there are also private, or non-governmental organizations. You have already used the ASTM standards to evaluate the characteristics of several textiles as they burned. The American Association of Textile Chemists and Colorists is another entity interested in textiles production, particularly those aspects of production related to understanding the chemical composition of textiles and textile dyes.</p>
<p>In the workplace, many people in a variety of positions are involved with each of these groups. Each person must have a fundamental understanding of the processes involved in fiber identification. Likewise, designers and producers of fibers and fabrics must also be aware of fiber identification principles in order to effectively design and label products that meet the standards set by these groups. If you were in one of these positions, why might you need to know how to identify fibers? How would you use the tests in this lab?</p>
<h3>Summary Questions</h3>
<ol>
  <li>How might involuntary regulations influence the design of a textile product?
  <li>Why is it necessary for labels containing information mandated by involuntary regulations to be permanently affixed to textile products?<li>Why is it important for associations and agencies to establish standards?
  <li>How influential are voluntary standards?  Why might a manufacturer choose to comply with a voluntary standard?
</ol>
