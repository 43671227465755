import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { VisualInspectionComponent } from './visual-inspection/visual-inspection.component';
import { BurnTestComponent } from './burn-test/burn-test.component';
import { MicroscopicObservationComponent } from './microscopic-observation/microscopic-observation.component';
import { SolubilityComponent } from './solubility/solubility.component';
import { Unit1IntroductionComponent } from './unit1-introduction/unit1-introduction.component';
import { Unit1ConclusionComponent } from './unit1-conclusion/unit1-conclusion.component';

const routes: Routes = [
  { path: 'introduction', component: Unit1IntroductionComponent },
  { path: 'visual-inspection', component: VisualInspectionComponent },
  { path: 'burn-test', component: BurnTestComponent },
  { path: 'microscopic-observation', component: MicroscopicObservationComponent },
  { path: 'solubility', component: SolubilityComponent },
  { path: 'conclusion', component: Unit1ConclusionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class Unit1RoutingModule {
  static components = [ VisualInspectionComponent, BurnTestComponent, MicroscopicObservationComponent,
    SolubilityComponent, Unit1IntroductionComponent, Unit1ConclusionComponent ]
}
