<div align="right">
  <p><img src="/assets/images/unit-8/performance.gif" alt="" height="37" width="318" align="left" border="0"><a href="u_8_3.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  The ability of fabrics to perform certain functions, in part, depends on the type of fibers and the construction of the yarns and fabrics involved.  Yet, finishes can be applied to fabrics that enhance the basic performance characteristics or develop performance characteristics that otherwise would not exist in the fabric.  Finishes control shrinkage, maintain appearance, enhance comfort, and increase safety in fabrics as well as improve a host of other factors.  </p>
<h3>Shrinkage Control</h3>

<p>Shrinkage of fabrics diminishes the serviceability of fabrics and the fit of
the products produced from them. Some fabrics will shrink after their first
cleaning cycle while others will progressively continue to shrink over a number
of cleanings. A variety of finishing techniques can be used to prevent the shrinkage
of fabrics. Among the more common methods are compressive shrinkage processes,
heat seating and resins. Compressive shrinkage processes are commonly used on
natural-fiber wovens, and variations are used on knits. In the compressive shrinkage
process, a series of rollers moving at different rates compress the fabric and
prevent shrinkage. Heat setting is another means for controlling shrinkage.
By heating fibers to a certain temperature and then cooling them, shrinkage
in fabrics of thermoplastic fibers can be controlled. Resins or polymers may
be added to the fabric, as is often done with rayons and wools, to prevent shrinkage
by stabilizing the fibers.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating shrinkage control</td>
  </tr>
  <tr align="center">
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-50.jpg" target="_blank"><img src="/assets/images/unit-8/8-50.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Appearance Retention</h3>
<p>Consumers generally expect products to retain their appearance or be able to be easily restored to their original appearance after repeated use and care.  Yet, everyone has had a favorite shirt they pulled from the clothes dryer only to find that it has shrunk, has a mass of wrinkles, or is permanently stained.  Likewise, most people have seen  upholstery fabric that has faded after extended exposure to light.  A variety of finishes have been developed to help retain the appearance of fabrics.   </p>

<p>Some of the more common appearance retention finishes include wrinkle resistance,
stain and soil repellency, light resistant and antislip finishes. Wrinkle resistant,
durable press, permanent press, and wrinkle free finishes all prevent wrinkling
in fabrics through the application of a chemical treatment and curing process.
The chemical treatment is applied prior to product construction and is followed
by a heat curing process that may be completed before or after the product construction.
Curing the fabrics after construction allows design details such as pleats and
folds to be cured into place, whereas curing before construction will not preserve
such design details.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating wrinkle control</td>
  </tr>
  <tr align="center">
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-51.jpg" target="_blank"><img src="/assets/images/unit-8/8-51.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<p>Stain repellant finishes and soil release finishes help to prevent permanent
staining or soiling of fabrics. Stain repellant finishes add a chemical finish
to the fabric which prevents oil and water based solutions from penetrating
the fibers. Soil release finishes are also a chemical solution that is added
to the fabric. They allow the fabric to easily release stains or soiling when
laundered or cleaned. Stain repellant and soil resistant finishes are particularly
beneficial for items exposed to excessive or repetitive soiling such as carpeting,
table linens and children&rsquo;s play clothes.</p>
<table width="294" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline" colspan="2">Labels indicating soil and stain resistant finish</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-8/u8large/8-52.jpg" target="_blank"><img src="/assets/images/unit-8/8-52.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-8/u8large/8-52b.jpg" target="_blank"><img src="/assets/images/unit-8/8-52b.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>Light resistant finishes and fume fading resistant finishes have been developed to preserve fabrics from the harmful effects of exposure to high levels of light or air pollutants.   Fabrics used for outdoor applications such as lawn furniture upholstery, awnings, and boat and automobile upholstery, as well as fabrics used indoors in areas of bright light or pollutant exposure, can be protected from excessive fading with these finishes.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating ultraviolet light protection</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-8/u8large/8-53.jpg" target="_blank"><img src="/assets/images/unit-8/8-53.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>In smooth fabrics especially those made from filament yarns, seams often slip or migrate toward the edge of the fabric. If the edges of fabric also fray, the seam may ravel or become undone. Antislip finishes are applied to such fabrics to preserve the appearance of the finished product. They are resin based finishes that adhere to the yarns of the fabric, preventing seam slippage and fraying.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label from fray resistant finish</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-8/u8large/8-54.jpg" target="_blank"><img src="/assets/images/unit-8/8-54.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Comfort</h3>
<p>Fabrics can be made more comfortable through the application of select finishes. Controlling the absorption of water, flow of heat, softness, and static build-up in fabrics can yield a product that is both serviceable and comfortable for specific applications.</p>
<p>Rain gear, shower curtains and umbrellas are common items that are expected to shed water. Water repellant finishes are added to fabrics to enable them to shed water. Most water repellant finishes consist of chemical agents adhered to the surface of the fabric. Some water repellant finishes are permanent while others may be removed through use and care.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating water repellant finish</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-8/u8large/8-55.jpg" target="_blank"><img src="/assets/images/unit-8/8-55.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>Items such as diapers and athletic wear are more comfortable if they absorb rather than repel moisture and keep moisture away from the skin. Absorbency of fabrics can be increased by special solutions that, when applied to the surface of the fibers, change the molecular structure and thereby make the fabric more absorbent.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating moisture control</td>
  </tr>
  <tr align="center">
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-56.jpg" target="_blank"><img src="/assets/images/unit-8/8-56.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>Fabrics, especially those made from synthetic fibers, have a tendency to build up static electricity. Built-up static causes fabrics to cling and can result in mild electrical shocks. Anti-static finishes work by either coating the fabric with a conductive material or by increasing the moisture retention of the fibers.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating anti-static finish</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-8/u8large/8-57.jpg" target="_blank"><img src="/assets/images/unit-8/8-57.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>
<p>Thermal or temperature regulating finishes and heat reflectant finishes have been designed to control the transfer of heat, thereby regulating the temperature and comfort of the user. Thermal or temperature regulating finishes act as insulators by turning from a solid to a liquid as they absorb heat and reversing the cycle as they later release heat. Heat reflectant surfaces are metallic and resin finishes applied to one side of the fabric, reflecting heat in a single direction, either toward or away from the user.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating heat reflectant finish</td>
  </tr>
  <tr align="center">
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-58.jpg" target="_blank"><img src="/assets/images/unit-8/8-58.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Safety</h3>
<p>Although many finishes enhance the long term appeal and comfort of fabrics, some finishes are critically important in providing safety for the user. Fire retardant, flame resistant, liquid and chemical resistant, and light reflectant finishes have all been developed to protect the user.</p>

<p>Combustion and melting of fabrics can be prevented through a variety of means.
Fiber, fabric construction and finishes all contribute to the flammability of
a textile product. The application of flame resistant finishes are useful in
a number of situations. For example, fire retardant finishes in fire fighter&rsquo;s
gear, mattresses, and commercial carpets and upholstery, are extremely valuable.
Flame resistant finishes prevent or inhibit combustion of the fabric when exposed
to a flame, consequently preventing or reducing severe injury or property damage.</p>
<table width="294" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline" colspan="2">Labels indicating flame and fire retardant finishes</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-8/u8large/8-59a.jpg" target="_blank"><img src="/assets/images/unit-8/8-59a.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-8/u8large/8-59b.jpg" target="_blank"><img src="/assets/images/unit-8/8-59b.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>Liquid and chemical-resistant finishes create a barrier in fabrics, preventing the flow of liquids and chemicals through the fabric. People working in environments with potentially harmful agents such as fertilizers, pesticides, viruses, bacteria, and other chemicals can be protected with the use of effective liquid and chemical-resistant finishes.</p>

<p>Joggers easily recognize the importance of light reflectant finishes on jogging
apparel and footwear. Light reflectant finishes use either fluorescent dyes
and/or glass particles to reflect light. By reflecting, rather than absorbing
oncoming light, the fabric can easily be seen in dark settings.</p>
<table width="294" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating light reflectant finish</td>
    <td class="byline">Light reflectant finish</td>
  </tr>
  <tr align="center">
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-62a.jpg" target="_blank"><img src="/assets/images/unit-8/8-62a.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>
      <p><a href="/assets/images/unit-8/u8large/8-62b.jpg" target="_blank"><img src="/assets/images/unit-8/8-62b.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>
<h3>Other Specialized Finishes</h3>

<p>As particular needs arise, finishes are often developed to retain the appearance
and performance of fabrics as well as to prevent potential problems. A variety
of finishes have been produced for specific applications. Some of the more notable
finishes include moth and insect repellant finishes, mold, mildew and rot prevention
finishes and antimicrobial finishes. </p>
<p>Moths, carpet beetles, cockroaches, silverfish and crickets are all known to have damaging effects on textile products. Natural fibers such as wools are particularly susceptible to damage from moths. Much of the damage from moths and other insects can be prevented with a moth and insect-proof finish that is essentially an insecticide.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating moth-proof finish</td>
  </tr>
  <tr align="center">
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-63.jpg" target="_blank"><img src="/assets/images/unit-8/8-63.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<p>Mold and mildew thrive in damp conditions and have damaging effects on fabrics.
Rot, mold and mildew are particularly damaging to textiles that are used outdoors,
in basements, baths and laundries, where constant dampness is present. Rot-resistant
and mildew-resistant finishes include a chemical treatment on the textile to
inhibit the growth of molds and mildew.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating mildew-resistant finish</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-8/u8large/8-64.jpg" target="_blank"><img src="/assets/images/unit-8/8-64.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>
<p>Like molds and mildew, bacteria often grow in textiles, causing odors, damage to the fabric and spread of disease. Antimicrobial finishes can be added to fabrics to prevent or inhibit the growth of bacteria through chemical or gas treatments or irradiation processes. Antimicrobial finishes are particularly valuable in hospital linens, medical uniforms, cloth diapers, and commercial carpeting and wallcovering.</p>
<table width="270" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Label indicating antimicrobial finish</td>
  </tr>
  <tr align="center">
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-65.jpg" target="_blank"><img src="/assets/images/unit-8/8-65.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<p>Other highly specialized finishes exist and will continue to be developed.
By carefully controlling the fiber content, yarn and fabric construction, and
finishing techniques, fabrics can be created for very specific applications.
The following exercise will provide a general comparison of performance finishes.
You should be able to easily assess the various finishes and their characteristics.
More detailed observation and testing, as performed by a textile scientist,
could be conducted to measure and create more specific qualities of various
finishes or combinations of finishes. </p>
<h3>Objectives</h3>
<ol>
  <li>To observe performance finishes on fabrics
  <li>To assess differences in various performance finishes
</ol>
<h3>Observations</h3>
<p>This exercise is intended to illustrate the effects of various performance finishes. Because most finishes are difficult to detect by merely viewing a sample of fabric, you will be shown a series of photographs depicting various performance finishes as indicated on product labels, hangtags and packaging. View the examples and carefully note your observations. Identify what type of performance finish has been applied and record it on your laboratory worksheet. Briefly describe the finish and indicate appropriate end uses for this type of finish. At what stage of the production process was the finish most likely applied?</p>
<h3>Tests</h3>
<table width="294" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Performance Finish 1</td>
    <td class="byline">Performance Finish 2</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-8/u8large/8-66.jpg" target="_blank"><img src="/assets/images/unit-8/8-66.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-8/u8large/8-67.jpg" target="_blank"><img src="/assets/images/unit-8/8-67.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Performance Finish 3</td>
    <td class="byline"><br>
      Performance Finish 4</td>
  </tr>
  <tr align="center">
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-68.jpg" target="_blank"><img src="/assets/images/unit-8/8-68.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-69.jpg" target="_blank"><img src="/assets/images/unit-8/8-69.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">Performance Finish 5</td>
    <td class="byline"><br>
      Performance Finish 6</td>
  </tr>
  <tr align="center">
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-70.jpg" target="_blank"><img src="/assets/images/unit-8/8-70.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td class="byline"><a href="/assets/images/unit-8/u8large/8-71.jpg" target="_blank"><img src="/assets/images/unit-8/8-71.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
