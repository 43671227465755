<div align="right">
  <p><img src="/assets/images/unit-1/introduction.gif" alt="" height="37" width="206" align="left" border="0"> <a href="u_1_1.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Looking at, or touching a textile can be a good beginning toward learning the obvious physical characteristics of that textile. Properties such as finished appearance and feel, or &ldquo;hand&rdquo;, of that textile can be determined. In this unit, visual inspection is taken one step further. Here, observed clues regarding physical characteristics of fibers will be used to actually identify and categorize textile fibers.</p>
<p>Fibers are typically classified based on their origin. Natural fibers are derived from sources in nature, such as animals, plants or minerals. Manufactured or man-made fibers are produced using either natural or synthetic solutions that are extruded through spinnerets and solidified into a fiber. In the tests shown in this unit, seven common fibers will be illustrated. Examples will represent the following general classifications of fibers. The seven fibers indicated in parenthesis are the same fibers shown in all subsequent tests conducted in Unit 1.</p>
<table border="0" cellpadding="10" cellspacing="0">
  <tr>
    <th valign="top" scope="col">
      <div align="left">
        <strong>Natural Fibers</strong></div>
    </th>
    <th valign="top" scope="col">
      <div align="left">
        <strong>Manufactured Fibers</strong></div>
    </th>
  </tr>
  <tr>
    <td valign="top">
      <p>Cellulosic (Plant) Fibers<br>
        &nbsp;&nbsp;&nbsp;&nbsp; <em>(cotton)</em></p>
    </td>
    <td valign="top">
      <p>Cellulosic<br>
        &nbsp;&nbsp;&nbsp;&nbsp; <em>(rayon)</em></p>
    </td>
  </tr>
  <tr>
    <td valign="top">
      <p>Protein (Animal) Fibers<br>
        &nbsp;&nbsp;&nbsp;<em> (wool)<br>
        </em><em>&nbsp;&nbsp;&nbsp;&nbsp;(silk)</em></p>
    </td>
    <td valign="top">
      <p>Modified Cellulosic<br>
        &nbsp;&nbsp;&nbsp; <em>(acetate)</em></p>
    </td>
  </tr>
  <tr>
    <td valign="top">
      <p>Inorganic (Mineral) Fibers</p>
    </td>
    <td valign="top">
      <p>Synthetic<br>
        &nbsp;&nbsp;&nbsp; <em>(nylon)<br>
        </em><em>&nbsp;&nbsp;&nbsp; (polyester)</em></p>
    </td>
  </tr>
  <tr>
    <td valign="top">&nbsp;</td>
    <td valign="top">
      <p>Inorganic</p>
    </td>
  </tr>
</table>
<p>What is fiber identification? Fiber identification is the method by which the physical characteristics and components of a textile are revealed and determined. There are four ways that these characteristics may be identified in the student laboratory:</p>
<ol>
  <li>simple visual evaluation
  <li>controlled burning evaluation
  <li>microscope evaluation
  <li>solubility evaluation
</ol>
<p>This laboratory unit will feature four basic tests for identifying fibers. Visual inspection, controlled burn evaluation, microscopic evaluation, and solubility assessment are tests that are typically used to identify fibers. With the exception of linen used in the sample test, the seven fibers shown in parentheses in the chart above were tested in the same order in each laboratory test. By the time you finish viewing the fourth test, you should be able to identify each of the seven basic fibers.</p>
<p>The methods of fiber identification shown in this unit are fairly simple applications of the scientific method for fiber identification. When doing advanced research, professional fiber and textile scientists typically use complex versions of these techniques, such as electron microscopes, which magnify underlying images many thousands of times more than light reflecting microscopes.</p>
<h3>Reference to Worksheets</h3>

<p>To complete this unit, refer to the worksheet for Unit 1: Fiber Identification to record observations. The textbook and other reference materials may be helpful in completing this unit.</p>
