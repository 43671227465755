import { Unit8ConclusionComponent } from './unit8-conclusion/unit8-conclusion.component';
import { Unit8IntroductionComponent } from './unit8-introduction/unit8-introduction.component';
import { PerformanceFinishesComponent } from './performance-finishes/performance-finishes.component';
import { AestheticFinishesComponent } from './aesthetic-finishes/aesthetic-finishes.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'aesthetic-finishes', component: AestheticFinishesComponent },
  { path: 'performance-finishes', component: PerformanceFinishesComponent },
  { path: 'introduction', component: Unit8IntroductionComponent },
  { path: 'conclusion', component: Unit8ConclusionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class Unit8RoutingModule {
  static components = [ AestheticFinishesComponent, PerformanceFinishesComponent, Unit8IntroductionComponent,
        Unit8ConclusionComponent ]
}
