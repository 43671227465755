import { Unit9ConclusionComponent } from './unit9-conclusion/unit9-conclusion.component';
import { Unit9IntroductionComponent } from './unit9-introduction/unit9-introduction.component';
import { CareLabelsComponent } from './care-labels/care-labels.component';
import { CleaningComponent } from './cleaning/cleaning.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'cleaning', component: CleaningComponent },
  { path: 'care-labels', component: CareLabelsComponent },
  { path: 'introduction', component: Unit9IntroductionComponent },
  { path: 'conclusion', component: Unit9ConclusionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class Unit9RoutingModule {
  static components = [ CleaningComponent, CareLabelsComponent, Unit9IntroductionComponent,
      Unit9ConclusionComponent ]
}
