<div align="right">
  <p><img src="/assets/images/unit-7/introduction.gif" alt="" height="37" width="204" align="left" border="0"><a href="u_7_1.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Textiles by themselves can be created with interesting textures, patterns and designs.  From the fibers, to the yarns, to the fabric, textiles have an inherent texture, pattern and color.  Yet, most textiles have some color and often pattern added to create more visual interest.  </p>
<p>Textile products can be enhanced, made more interesting, and sometimes made more serviceable and cost effective by dyeing and printing. Take the example of a silk scarf, which by itself is a smooth and luxurious fabric, but when printed with a richly colored and detailed pattern, becomes a work of art. Another example is a  crossing guard's vest, which without color is plain and unnoticeable, but with color becomes an obvious attention-getter. Fabrics can also be printed to simulate leather, detailed plaids or other more expensive fabrics at a fraction of the cost.</p>
<p>What is dyeing and printing? By simple definition, dyeing is the process of adding allover color. Printing is the selective process of placing color onto the surface of a fabric, creating a partial or allover color or pattern. Coloring can be added at any stage in the development of fibers, yarns and fabrics, but printing is generally reserved for constructed fabrics.</p>
<p>In this unit, we are focusing on the processes of dyeing and printing, and observing the results in the student laboratory. Each of these processes will be discussed and examples will be shown. The examples shown in this unit are fairly simple. In the textile industry, professional textile scientists and designers may modify the techniques or apply more complex versions of these processes to create an array of dyeing and printing varieties.</p>
<h3>Terminology</h3>
<p>Be aware of the following terms used in this unit.</p>
<blockquote>
  <p><span class="boldital">Dyeing: </span> Dyeing is the process of adding allover color to a polymer, fiber, yarn or fabric.</p>
  <p><span class="boldital">Printing:</span>  Printing is the process of applying color to the surface of a fabric.  </p>
</blockquote>
<h3>Reference to Worksheets</h3>

<p>To complete this unit, refer to the worksheet for Unit 7: Dyeing and Printing
to record observations. The textbook and other reference materials may be helpful
in completing this unit.</p>
