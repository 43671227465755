import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aesthetic-finishes',
  templateUrl: './aesthetic-finishes.component.html',
  styleUrls: ['./aesthetic-finishes.component.css']
})
export class AestheticFinishesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
