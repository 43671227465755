<div align="right">
  <p><img src="/assets/images/unit-9/care.gif" alt="" height="37" width="192" align="left" border="0"><a href="u_9_3.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Many textile products have labels indicating care instructions. In the United States, the Federal Trade Commission (FTC) mandated such labeling for certain apparel and piece goods in the 1971 and 1984 rulings on Permanent Care Labeling. Other products not covered by the FTC regulations may also voluntarily carry care labels. If a product is required to have a care label, only one method of care must be outlined in a permanently attached label. Manufacturers are liable for product damage that results during suggested care procedures. Products available outside the U.S. may also have care labels as regulated by the particular country, or they may have voluntary labels.</p>
<p>Care labels indicate, in detail, the most appropriate method that should be used to care for the textile product.  Although other methods of care may be safe, they are generally not indicated on the care label.  Care is described in words or through the use of approved international care symbols.  </p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr>
    <td class="byline" align="center">Example of care labels found in textile products</td>
  </tr>
  <tr>
    <td align="center">
      <p><a href="/assets/images/unit-9/u9large/9-2.jpg" target="_blank"><img src="/assets/images/unit-9/9-2.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>
<p>Care labels include information on the appropriate cleaning method. If the product can be safely laundered, information on washing, bleaching, drying and ironing is provided.</p>
<p><b><i>Cleaning Method</i></b></p>
<p>Labels indicate whether the product should or should not be dry cleaned. If the product should not be dry cleaned, other cleaning methods will be provided. If the product should be dry cleaned, additional details may be provided.</p>
<blockquote>
<table border="0" cellspacing="0" cellpadding="10">
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dryclean/s_dc_circle.gif" width="40" height="40"></td>
        <td><p>Dryclean</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dryclean/s_dc_a.gif" width="40" height="40"></td>
        <td><p>Dryclean, Any Solvent</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dryclean/s_dc_f.gif" width="40" height="40"></td>
        <td><p>Dryclean, Petroleum Solvent Only</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dryclean/s_dc_p.gif" width="40" height="40"></td>
        <td><p>Dryclean, Any Solvent Except Trichloroethylene</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dryclean/s_dc_sc.gif" width="40" height="40"></td>
        <td><p>Dryclean, Short Cycle</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dryclean/s_dc_rm.gif" width="40" height="40"></td>
        <td><p>Dryclean, Reduced Moisture</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dryclean/s_dc_lh.gif" width="41" height="41"></td>
        <td><p>Dryclean, Low Heat</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dryclean/s_dc_ns.gif" width="40" height="40"></td>
        <td><p>Dryclean, No Steam</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dryclean/s_dc_donot.gif" width="54" height="40"></td>
        <td><p>Do Not Dryclean</p></td>
      </tr>
    </table>
</blockquote>
<p><b><i>Washing</i></b></p>
<p>For products that can be safely laundered, labels will indicate if hand washing or machine washing is appropriate. The labels will also specify the temperature of the water, including cool/cold, warm or hot; and type of machine wash cycle, including normal, permanent press, or delicate/gentle, that would be suitable.</p>
<blockquote>
<table border="0" cellspacing="0" cellpadding="10">
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_mw_norm.gif" width="40" height="39"></td>
        <td><p>Machine Wash, Normal</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_mw30c.gif" width="40" height="39"><br>
          <br>
        <img src="/assets/images/unit-9/care_symbols/Wash/s_mw30cd.gif" width="40" height="39"></td>
        <td>
        <p>Machine Wash, Cold (30&deg;C or 65&deg; &#150; 85&deg;F)</p>
  </td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_mw40c.gif" width="40" height="39"><br>
          <br>
        <img src="/assets/images/unit-9/care_symbols/Wash/s_mw40cd.gif" width="40" height="39"></td>
        <td><p>Machine Wash, Warm (40&deg;C or 105&deg;F)</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_mw50c.gif" width="40" height="39"><br>
        <br>
        <img src="/assets/images/unit-9/care_symbols/Wash/s_mw50cd.gif" width="40" height="39"></td>
        <td><p>Machine Wash, Hot (50&deg;C or more or 120&deg;F or more)</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_mw60c.gif" width="40" height="39"><br>
        <br>
        <img src="/assets/images/unit-9/care_symbols/Wash/s_mw60cd.gif" width="40" height="39"></td>
        <td><p>Machine Wash, Hot</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_mw70c.gif" width="40" height="39"><br>
        <br>
        <img src="/assets/images/unit-9/care_symbols/Wash/s_mw70cd.gif" width="40" height="39"></td>
        <td>Machine Wash, Hot</td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_mw95c.gif" width="40" height="39"><br>
        <br>
        <img src="/assets/images/unit-9/care_symbols/Wash/s_mw95cd.gif" width="40" height="39"></td>
        <td>Machine Wash, Hot</td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_mw_ppres.gif" width="40" height="44"></td>
        <td><p>Machine Wash, Permanent Press</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_mwgentle.gif" width="40" height="50"></td>
        <td><p>Machine Wash, Gentle or Delicate</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_hndw.gif" width="40" height="58"></td>
        <td><p>Hand Wash</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Wash/s_w_donot.gif" width="50" height="39"></td>
        <td><p>Do Not Wash</p></td>
      </tr>
    </table>
</blockquote>
<p><em><strong>Bleaching</strong></em></p>
<p>Labels will indicate if the product can or cannot be bleached.  If the product can be bleached, the label will indicate if any bleach can be used or if only non-chlorine bleaches are appropriate.</p>
<blockquote>
<table border="0" cellspacing="0" cellpadding="10">
      <tr>
        <td><img src="/assets/images/unit-9/care_symbols/Bleach/s_b_any.gif" width="39" height="39"></td>
        <td><p>Bleach When Needed</p></td>
      </tr>
      <tr>
        <td><img src="/assets/images/unit-9/care_symbols/Bleach/s_b_nonchl.gif" width="39" height="39"></td>
        <td><p>Non-Chlorine Bleach When Needed</p></td>
      </tr>
      <tr>
        <td><img src="/assets/images/unit-9/care_symbols/Bleach/s_b_dont_s.gif" width="51" height="39"></td>
        <td>Do Not Bleach</td>
      </tr>
    </table>
</blockquote>
<p><b><i>Drying</i></b></p>
<p>Care labels will indicate if the textile can be machine tumble dried or if other drying methods are more appropriate. If the product can be tumble dried the temperature (no heat, low, medium or high) and the cycle setting (normal, permanent press or delicate/gentle) will be indicated. If tumbling is inappropriate, that too will be noted. In the event that tumble drying is not suitable, line drying, drip drying, drying flat, or drying in the shade will be advised.</p>
<blockquote>
<table border="0" cellspacing="0" cellpadding="10">
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_td_norm.gif" width="40" height="40"></td>
        <td><p>Tumble Dry, Normal</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_td_nor_l.gif" width="40" height="40"></td>
        <td><p>Tumble Dry, Normal, Low Heat</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_td_nor_m.gif" width="40" height="40"></td>
        <td><p>Tumble Dry, Normal, Medium Heat</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_td_nor_h.gif" width="40" height="40"></td>
        <td><p>Tumble Dry, Normal, High Heat</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_td_nornh.gif" width="40" height="40"></td>
        <td><p>Tumble Dry, Normal, No Heat</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_td_ppres.gif" width="40" height="45"></td>
        <td><p>Tumble Dry, Permanent Press</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_td_gentl.gif" width="40" height="51"></td>
        <td><p>Tumble Dry, Gentle</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_td_donot.gif" width="54" height="40"></td>
        <td><p>Do Not Tumble Dry</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_dr_donot.gif" width="54" height="40"></td>
        <td><p>Do Not Dry</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_dr_line.gif" width="40" height="40"></td>
        <td><p>Line Dry</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_dr_drip.gif" width="40" height="40"></td>
        <td><p>Drip Dry</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_dr_flat.gif" width="40" height="40"></td>
        <td><p>Dry Flat</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_dr_shade.gif" width="40" height="40"></td>
        <td><p>Dry In Shade</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Dry/s_wr_donot.gif" width="51" height="34"></td>
        <td><p>Do Not Wring</p></td>
      </tr>
    </table>
</blockquote>
<p><b><i>Ironing</i></b></p>
<p>Care labels will identify if a textile product can be safely ironed or not. If a product can be ironed, the temperature of the iron (low, medium or high) and whether or not steam may be applied will be noted on the care label.</p>
<blockquote>
<table border="0" cellspacing="0" cellpadding="10">
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Iron/s_ir_tall.gif" width="45" height="35"></td>
        <td><p>Iron, Any Temperature, Steam or Dry</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Iron/s_ir_talll.gif" width="45" height="35"></td>
        <td><p>Iron, Low (110&deg;C or 230&deg;F)</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Iron/s_ir_tallm.gif" width="45" height="35"></td>
        <td><p>Iron, Medium (130&deg;C or 300&deg;F)</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Iron/s_ir_tallh.gif" width="45" height="35"></td>
        <td><p>Iron, High (200&deg;C or 390&deg;F)</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Iron/s_ir_nostm.gif" width="45" height="55"></td>
        <td><p>Do Not Steam</p></td>
      </tr>
      <tr>
        <td align="center"><img src="/assets/images/unit-9/care_symbols/Iron/s_ir_dontt.gif" width="50" height="36"></td>
        <td><p>Do Not Iron</p></td>
      </tr>
    </table>
</blockquote>
<p>The following observations will provide a general comparison of the information presented on care labels.  After viewing the care labels, you should be able to assess the information provided, determine the appropriate method of care for the product, and actually perform the process.  </p>
<h3>Objectives</h3>
<ol>
  <li>To observe permanent care labels </li>
  <li>To recognize and describe international care symbols </li>
  <li>To interpret procedures indicated on care labels </li>
  <li>To create care labels for textile products composed of specific fibers and constructed in various different styles</li>
</ol>
<h3>Observations</h3>
<p>This exercise is intended to provide a better understanding of the information contained on care labels. You will be shown a series of photographs illustrating various care labels that might be found on textile products. Carefully view the labels and note your observations. Identify the procedure you would use to clean and care for this particular product. Record your notes on the related worksheet. Should the product be dry cleaned? Can the product be laundered? If so, under what conditions can it be laundered? Can the product be bleached? How should it be dried? Can it be ironed?</p>
<h3></h3>
<h3>Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Care Label 1</td>
    <td class="byline">Care Label 2</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-9/u9large/9-9.jpg" target="_blank"><img src="/assets/images/unit-9/9-9.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-9/u9large/9-10.jpg" target="_blank"><img src="/assets/images/unit-9/9-10.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Care Label 3</td>
    <td class="byline"><br>
      Care Label 4</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-9/u9large/9-11.jpg" target="_blank"><img src="/assets/images/unit-9/9-11.jpg" alt="" height="74" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-9/u9large/9-12.jpg" target="_blank"><img src="/assets/images/unit-9/9-12.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Care Label 5</td>
    <td class="byline"><br>
      Care Label 6</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-9/u9large/9-13.jpg" target="_blank"><img src="/assets/images/unit-9/9-13.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-9/u9large/9-14.jpg" target="_blank"><img src="/assets/images/unit-9/9-14.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
