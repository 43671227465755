import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Unit3RoutingModule } from './unit-3-routing.module';


@NgModule({
  imports: [CommonModule, ReactiveFormsModule, Unit3RoutingModule],
  declarations: [Unit3RoutingModule.components]
})

export class Unit3Module { }
