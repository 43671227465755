<div align="right">
  <p><img src="/assets/images/unit-7/conclusion.gif" alt="" height="37" width="189" align="left" border="0"><a href="u_7_4.html" target="_blank">Printable Page</a></p>
</div>
<h3><br>
  Threads of History</h3>
<p>The art of dyeing has been around as early as the Stone Age. Ancient man learned dyeing methods before textiles were even introduced. Dyed textiles containing some color have been found in Egyptian tombs; and garments dyed with the blood of grapes are referenced in the Bible. The earliest dyes were predominately from plants, animals and minerals.</p>
<p>Popular plant dyes came from flowers, leaves, roots, berries and barks. A garment dating back to 3000 B.C. has been found dyed in indigo. There are 350 plants that produce Indigo; one of the most common is a bush called Indigofera tinctoria. The blue colorant is obtained from the leaves when extracted in water. Saffron, a golden yellow dye coming from the purple flower called Crocus sativus, dates back to 2000 B.C. in Egypt. The flowers&rsquo; stigmas are picked, dried and dissolved in water for dyestuff to extract. Animal sources also provide excellent dyestuffs because of the rich and deep colors they produce. Tyrian purple comes from species of shellfish, where the dye is located in a small area next to the head of the animal. The Mediterranean city of Tyre is given credit for discovery of the brilliant purple-red color in 3500 B.C..</p>
<p>The oldest method of designing by dyeing is called resist dyeing. In Indonesia, batik, sometimes meaning a paste or wax resist, was first used in the seventeenth century. The cloth was soaked in coconut oil for several days, a design was applied with hot beeswax and then the cloth was dyed. Wherever the wax was applied, the dye did not reach the fabric. Later the wax was removed.</p>
<h3>Summary Questions	</h3>
<ol>
  <li>What are some of the advantages of dyeing at various stages of the textile production cycle?  What are some of the disadvantages of dyeing at various stages of the textile production cycle?
  <li>How could you determine if a fabric is colorfast? How could you determine if a fabric is prone to crocking? How could you determine if a fabric is prone to bleeding?<li>What are the basic differences between direct, resist, discharge and heat transfer printing?
  <li>How does the cost of printing influence the method of printing?</ol>
