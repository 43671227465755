import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Unit2RoutingModule } from './unit-2-routing.module';


@NgModule({
  imports: [CommonModule, ReactiveFormsModule, Unit2RoutingModule],
  declarations: [Unit2RoutingModule.components]
})

export class Unit2Module { }
