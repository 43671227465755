<div align="right">
  <p><img src="/assets/images/unit-2/tenacity.gif" alt="" height="37" width="157" align="left" border="0"><a href="u_2_2.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
Have you ever pulled on a cord or string and had it break? The ability of fibers
to resist breakage can be assessed by measuring the fiber&rsquo;s tenacity or
tensile strength. Tenacity is determined by measuring the force required to
rupture or to break the fiber. Fibers that have high tensile strengths will
resist breakage when pulled. Tenacity is important if a fiber is used in such
a way that would need to resist breakage such as in the case of a drapery pull
cord or a jacket's drawstring.</p>
<p>The size, structure and density of fibers are indicators of tensile strength. Yet, because of the seemingly infinite combinations, fibers must be tested to effectively assess tensile strength. Through scientific testing procedures tensile strength of fibers can be measured in grams per denier or grams per tex allowing the tensile strength of various fibers to be compared or measured against an acceptable standard. The tensile strength of some fibers may vary if the fibers being tested are dry or wet. Therefore, the end use of the fibers should be considered when examining tensile strength.&nbsp;</p>

<p>A variety of tests and testing equipment can be used to measure specific aspects
of tensile strength. The following experiments illustrate how the tenacity of
fibers in fabrics can be observed, measured and compared using scientific and
non-technical means. After viewing the tensile strength tests, you should be
able to assess the tensile properties of the sample fabrics. It should be noted
that other tests and testing equipment can be used to effectively assess the
tenacity of fibers and fabrics and may be more appropriate for specific applications.
More detailed analysis, as might be performed by a textile scientist, would
also be necessary to measure smaller degrees of variation in tensile strength
between various fibers.&nbsp;</p>
<h2>Objectives</h2>
<ol>
  <li>To observe the tensile strength of sample fabrics
  <li>To note differences in tensile properties of various fabrics
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following term as you watch the video clips showing tensile strength tests with different fabrics.</p>
<blockquote>

<p><b><i>Tenacity</i>:</b> Tenacity is the strength of the fiber and fabrics.
It is the ability of the fibers to withstand breakage. Do the fabrics resist
breakage? Do the fibers slowly break away? Does the fabric gradually break?
Does it appear unbreakable?&nbsp;</p>
</blockquote>
<h3>Standardized Procedures and Observations </h3>
<p>Scientific tensile strength testing requires the use of specialized equipment and consequently is typically conducted in a specialized textile testing facility. The tensile strength test shown in this section took place in a controlled laboratory environment at the J.C. Penney&rsquo;s Product Research and Technical Laboratories using an Instron Tensile Tester according to ASTM standard test methods for tensile strength of textiles. You will observe a video clip of a fabric as it is tested for tensile strength. Carefully observe what happens to the test fabric as it is stretched. Record all observations, results and conclusions on the related worksheet. Does the fabric appear to have reasonable tensile strength? What might be a suitable use for the fabric considering the tensile strength? What applications might not be suitable?</p>
<h3>Standardized Test	</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">
        Tensile Strength </td>
  </tr>
  <tr align="center">
        <td>
        <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
          <param name="movie" value="videos/2-10.swf">
          <param name="quality" value="high">
          <embed src="videos/2-10.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270"></embed>
        </object> -->
        <video width="320" height="270" controls>
          <source src="/assets/videos/unit-2/2-10.mp4" type="video/mp4">
          Your browser does not support the video tag
        </video>
      </td>
      </tr>
</table>
<h3>Non-Technical Procedures and Observations </h3>
<p>Tensile testing equipment and methods as observed in the previous test are available in private or commercial textile testing laboratories throughout the world. However, consumers and most small businesses do not have the resources available to utilize such services. In this section, relatively simple alternative means of testing tensile strength will be shown. The results from these tests are far from scientific but can provide the consumer, designer or merchandiser with an indication of the tensile strength of the yarn or fabric in question.&nbsp;</p>
<p>By firmly grasping both edges of a fabric sample and pulling, one can informally observe the tensile strength of a fabric. This informal test will allow for an observation of the tenacity as well as the elasticity of a fabric. If fabrics are to be compared, the fabric samples should be of the same size and pulled with approximately the same level of force. Observations can include the distance the fabric is able to be stretched, if the fabric is prone to rupture when stretched, and the ability of the fabric to return to its original size after being stretched. Observe the following video clips of three fabrics as they are tested for tensile strength and elasticity. Note what happens to the test fabrics as they are stretched. Record all observations, results and conclusions on the related worksheet. Note the differences in the tensile strength of three fabrics. Which fabric appears to have the greatest degree of tenacity? Which appears to have the weakest tensile strength?</p>
  <h3>Non-Technical Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">
        Home Tensile Strength </td>
  </tr>
  <tr align="center">
        <td><div class="byline">
        <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
          <param name="movie" value="videos/2-15.swf">
          <param name="quality" value="high">
          <embed src="videos/2-15.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270"></embed>
        </object> -->
        <video width="320" height="270" controls>
          <source src="/assets/videos/unit-2/2-15.mp4" type="video/mp4">
          Your browser does not support the video tag
        </video>
      </div>
      </td>
      </tr>
</table>
<p>&nbsp;	</p>
