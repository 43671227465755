<div align="right">
  <p><img src="/assets/images/unit-8/conclusion.gif" alt="" height="37" width="189" align="left" border="0"><a href="u_8_4.html" target="_blank">Printable Page</a></p>
</div>
<h3><br>
  Threads of History</h3>
<p>The process of finishing fabrics has been around for many years. Early finishes primarily improved the appearance and hand of fabrics. Today, finishes are used to enhance safety and simplify maintenance, as well as improve aesthetic and textural qualities. Properties of fibers can be completely changed by finishes, resulting in finished products that bear little resemblance to the original fibers.</p>

<p>Watering, a medieval finish, also known today as moir&eacute;ing, gave a wavelike
or veined pattern to silks. In Europe and the Orient, the finish was made by
placing two layers of fabric on a wooden block and beating them with mallets.
Today, the moir&eacute; pattern can be temporary, durable or permanent depending
on the fibers and chemicals used. Cotton fabrics can be beetled to look like
linen. The beetling machine was invented in 1850 and consisted of a pair of
wooden rollers with hammers that pounded the cloth to give it a smooth, soft
finish. In 1895, the schreiner calender finish was introduced. Rollers engraved
fabrics with fine lines, heat was applied, and then pressure was added to give
a soft luster to fabrics. In the seventeenth and eighteenth centuries, frizzing
gave wool fabrics a curly appearance. Honey was applied to the cloth and the
nap was rubbed with sandstone in a circular motion.<sup>7,9</sup></p>
<h3>Summary Questions	</h3>
<ol>
  <li>Why might an aesthetic finish be added to a textile rather than changing the fiber or fabric construction?
  <li>Why might a performance finish be added to a product rather than changing the fiber or fabric construction?
  <li>How would a consumer know if a textile product had a particular finish?
  <li>How would a consumer protect a non-durable finish?
  <li>What are some of the disadvantages of applying finishes to a textile product?
  <li>What tradeoffs might a product developer need to make when combining various finishes?
</ol>
