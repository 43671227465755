<div align="right">
  <p><img src="/assets/images/unit-6/fiber_based.gif" alt="" height="37" width="290" align="left" border="0"><a href="u_6_3.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  For years, many fabrics were constructed directly from fibers. Early examples show the use of natural fibers being produced directly into fabrics, without first being formed into yarns. Today, both natural and synthetic fibers can be formed directly into fabrics such as bark cloth, felt and other fiberweb fabrics.</p>
<h3>Bark Cloth</h3>
<p>Bark cloth, or tapa, is a unique fabric with early origins in the Pacific Islands. The cloth is produced from inner layers of bark from native fruit trees such as fig or mulberry. The bark is first soaked and later beaten until a smooth, paperlike fabric results. Because of the paperlike quality, the fabric lacks drapability and ease of handling. Consequently, designs and conventional construction techniques must be modified for any effective use of bark cloth. Today, most bark cloth is used in native dress or for decorative accessories.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Bark cloth</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-10.jpg" target="_blank"><img src="/assets/images/unit-6/6-10.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Felt</h3>

<p>Felt is one of the oldest known forms of fabric construction. True felt is
created using wool fibers; however, other fibers can also be used to produce
felt-type fabrics such as craft felt. Thick stacks of evenly spread fibers are
steamed and rolled or pressed to compact the fibers into a felt. By laying the
stacks of fibers at opposing angles, adding friction in the pressing process,
and exposing the felt to a soap or acid solution, the felt can be given added
strength. </p>

<p>Felt ordinarily lacks strength and drapability, which limits its generalized
application. However, felt has a natural insulative quality due to the air trapped
inside the layers of fiber. It is easily cut and shaped, making felt an ideal
fabric for hats and fashion accessories, as well as for padding and insulation
in home furnishings.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Felt</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-11.jpg" target="_blank"><img src="/assets/images/unit-6/6-11.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<h3>Fiberweb or Nonwoven Fabrics</h3>
<p>Contemporary fibers require modern methods for fabric construction. Fiberweb
fabrics are commonly referred to as nonwovens. They are produced much like felt;
however, they are made from a variety of types of fibers other than wool. In
the construction process fibers are arranged to create a fiber web. The fiber
web is then bonded using heat, chemicals, or mechanical means to form the fabric.</p>
<p>Fiberweb fabrics may take on a variety of characteristics based on the fibers used and the specific treatments applied during production. Common characteristics of fiberweb fabrics are that they tend to be very inexpensive and serve a seemingly endless list of functions. Fiberweb fabrics have been used for apparel and home furnishings as well as medical, agricultural, mechanical, and industrial end uses. Common applications of fiberweb fabrics are blankets, carpets, disposable diapers, interfacing, fiberfill and batting.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Fiberweb</td>
    <td class="byline">Equipment for fiberweb production</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-12.jpg" target="_blank"><img src="/assets/images/unit-6/6-12.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-6/u6large/6-13.jpg" target="_blank"><img src="/assets/images/unit-6/6-13.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Objectives</h3>
<ol>
  <li>To observe the similarities and differences among various fiber based fabrics<li>To assess differences between bark cloth, felt and fiberweb fabrics
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you observe the photographs showing different fiber based fabrics.</p>
<blockquote>
  <p><span class="boldital">Bark Cloth:</span>  Bark cloth is a paperlike fabric produced from the inner bark of fruit trees.  Does the fabric resemble paper?  Does the fabric seem stiff and lack drapability?</p>

<p><span class="boldital">Felt: </span>True felt is a fabric made from compressed
wool fibers. Is the fabric comprised mostly of wool fibers or another type
of staple fiber? Does the fabric seem relatively thick?</p>
<p><span class="boldital">Fiberweb or Nonwoven Fabric: </span> Fiberweb fabrics
are made directly from compressed fibers. Is the fabric made directly from
fibers? Is the fabric comprised of fibers other than wool?</p>
</blockquote>
<h3>Observations</h3>
<p>This exercise is intended to provide an understanding of fiber based fabrics as well as some of their varieties. You will be shown a series of photographs of different fiber based fabrics. While viewing the fabrics note the qualities and describe the observable differences. Classify the fabric as bark cloth, felt or fiberweb fabric based on your observations. Record your notes on the related worksheet.</p>
<h3>Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Fiber Based Fabric 1</td>
    <td class="byline">Fiber Based Fabric 2</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-14.jpg" target="_blank"><img src="/assets/images/unit-6/6-14.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-6/u6large/6-15.jpg" target="_blank"><img src="/assets/images/unit-6/6-15.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><br>
      Fiber Based Fabric 3</td>
    <td class="byline">

    </td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-16.jpg" target="_blank"><img src="/assets/images/unit-6/6-16.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td></td>
  </tr>
</table>
