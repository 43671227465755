
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FilamentsComponent } from './filaments/filaments.component';
import { YarnTwistComponent } from './yarn-twist/yarn-twist.component';
import { YarnSizeComponent } from './yarn-size/yarn-size.component';
import { YarnComplexityComponent } from './yarn-complexity/yarn-complexity.component';
import { Unit3ConclusionComponent } from './unit3-conclusion/unit3-conclusion.component';
import { Unit3IntroductionComponent } from './unit3-introduction/unit3-introduction.component';

const routes: Routes = [
  { path: 'filaments-spurn-yarns', component: FilamentsComponent },
  { path: 'yarn-twist', component: YarnTwistComponent },
  { path: 'yarn-size', component: YarnSizeComponent },
  { path: 'yarn-complexity', component: YarnComplexityComponent },
  { path: 'introduction', component: Unit3IntroductionComponent },
  { path: 'conclusion', component: Unit3ConclusionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class Unit3RoutingModule {
  static components = [ FilamentsComponent, YarnTwistComponent, YarnSizeComponent, YarnComplexityComponent,
      Unit3IntroductionComponent, Unit3ConclusionComponent ]
}
