<div align="right">
  <p><img src="/assets/images/unit-5/introduction.gif" alt="" height="37" width="204" align="left" border="0"><a href="u_5_1.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  You have already examined the construction of fabrics through the weaving process. This unit will now examine the process of knitting. Knitting, by definition, is the process of looping and intertwining stitches in order to form a fabric, component of a knitted product, or knitted product.</p>
<table width="64" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Hand knitting</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-1.jpg" target="_blank"><img src="/assets/images/unit-5/5-1.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>Originally, all knitting was done by hand. Today many crafty knitters still enjoy the art of knitting. However, most contemporary knits are commercially produced on high speed, sophisticated knitting machines which are usually faster and more efficient than weaving machines. By varying the programming of the knitting machines and the yarns used in the knitting process, a variety of sizes and styles of knits can be produced in rapid succession. Although many knits are produced as flat yardage, others are knitted in tubes to allow for more efficient construction and to reduce or eliminate the need for seams in end-use products.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline" colspan="2">Commercial knitting machine</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-2.jpg" target="_blank"><img src="/assets/images/unit-5/5-2.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/5-3.jpg" target="_blank"><img src="/assets/images/unit-5/5-3.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-4.jpg" target="_blank"><img src="/assets/images/unit-5/5-4.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td></td>
  </tr>
  <tr align="center">
    <td colspan="2" class="byline"><br>
      Circular knitting machine</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-5.jpg" target="_blank"><img src="/assets/images/unit-5/5-5.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/5-6.jpg" target="_blank"><img src="/assets/images/unit-5/5-6.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>Knits are very versatile.  They can be lightweight and sheer, or they can be heavy, warm and bulky.  Knits can be smooth and sleek, or they can be heavily textured.  Knits can be solid in color, texture and pattern, or they can be colorful and ornate.  </p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr>
    <td class="byline" colspan="2">Circular knitting machine for smaller fabrics and trims</td>
  </tr>
  <tr>
    <td><a href="/assets/images/unit-5/u5large/5-7.jpg" target="_blank"><img src="/assets/images/unit-5/5-7.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td></td>
  </tr>
  <tr>
    <td colspan="2" class="byline" align="center">Specialty knitting machine knits trim with words or patterns in knit</td>
  </tr>
  <tr>
    <td><a href="/assets/images/unit-5/u5large/5-8.jpg" target="_blank"><img src="/assets/images/unit-5/5-8.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/5-9.jpg" target="_blank"><img src="/assets/images/unit-5/5-9.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>Compared to wovens, knits possess some common qualities. Most knits are pliable, elastic,  comfortable and wrinkle resistant. Knits are also good insulators. Unfortunately, knits have a tendency toward shrinking, snagging, and running. In general, the advantages of knits outweigh their disadvantages.</p>
<p>In this unit, examples of the various types of knits and knit stitches will be shown through illustrations and photographs. The examples shown in this unit are relatively simple and easily identifiable. Textile and knit designers often use combinations of the basic knits, as well as more complex versions, to create unique and intricate designs. By adjusting yarns, stitches and knitting methods, a seemingly infinite variety of designs and products can be developed.</p>
<h3>Terminology</h3>
<p>Be aware of the following terms used in this unit.</p>
<blockquote>
  <p><span class="boldital">Knitting:</span>  Knitting is the process of looping yarn or yarns to produce a fabric.  </p>
</blockquote>
<h3>Reference to Worksheet</h3>

<p>To complete this unit, refer to the worksheet for Unit 5: Knits to record observations.
The textbook and other reference materials may be helpful in completing this
unit.</p>
