import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit5-introduction',
  templateUrl: './unit5-introduction.component.html',
  styleUrls: ['./unit5-introduction.component.css']
})
export class Unit5IntroductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
