import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit8-introduction',
  templateUrl: './unit8-introduction.component.html',
  styleUrls: ['./unit8-introduction.component.css']
})
export class Unit8IntroductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
