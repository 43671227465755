<div align="right">
  <p><img src="/assets/images/unit-1/microscopic_observation.gif" alt="Microscopic Observation" height="37" width="366" align="left" border="0"><a href="file:///D|/TEXTILE_MANIA/U_1/u_1_4.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Magnified views of textile fibers are helpful in classifying fibers. Viewing fibers through a microscope provides another piece of the puzzle, and a significant one, when identifying fibers. Longitudinal and cross-section views are both valuable in the process of fiber identification. </p>
<p>The luster or light reflection observed in a fabric is affected by a fiber&rsquo;s cross-section. For example, round is shiny, lobed has a diffused glow, and irregular shapes give a dull appearance. Luster may also be modified in manufactured fibers by the addition of titanium dioxide to the spinning solution. Such a delustered fiber will appear to have black specks when observed under the microscope.</p>

<p>Textile fibers, particularly the major natural fibers&mdash;cotton, linen,
wool and silk&mdash;have a distinctive appearance when observed under the microscope.
This distinctive appearance can be used to identify an unknown fiber and give
the observer information about fiber properties. Natural fibers, with the exception
of silk, tend to appear irregular in shape and rough in texture under a magnified
longitudinal view. In a cross-sectional view, they also appear irregularly shaped.
Cellulosic fibers have a cell structure that is easily identified under magnification,
whereas protein fibers like wool have a scalelike structure. Silks, although
a protein-based fiber, appear rather smooth and almost transparent. </p>
    <p>In a magnified longitudinal view, most manufactured fibers, particularly synthetic fibers, tend to be very smooth and even. In a magnified cross-sectional view, manufactured fibers also tend to be regular in shape, with some exceptions such as cellulose-based fibers like rayon or acetate. The appearance of man-made fibers can be altered by variations in the manufacturing process; thus, the microscopic test is often of little value in the specific identification of manufactured fibers.</p>
    <h3>Objectives</h3>
    <ol>
      <li>To become aware of characteristics observed in longitudinal views of selected textile fibers</li>
      <li>To become aware of characteristics observed in cross-sectional views of selected fibers</li>
      <li>To identify unknown fibers through microscopic analysis</li>
    </ol>
    <h3>Procedures and Standards</h3>
    <p>This test is used to evaluate the visual characteristics of a fiber as viewed under a microscope. Fibers may be examined under magnification in the lengthwise dimension. Longitudinal slides of fibers are relatively easy to make, whereas cross-sectional slides require degrees of skill and more sophisticated equipment.</p>
    <p>For this exercise, view the longitudinal photos of the seven fibers. Record all observations and conclusions on the related worksheet, while carefully completing the following steps:</p>
    <ol>
      <li>Observe the longitudinal and in some cases, the cross-sectional views of the unknown fibers</li>
      <li>Sketch and describe the appearance of each fiber</li>
      <li>Determine the possible fiber classifications for each of the unknown fibers based upon its characteristics</li>
      <li>If possible, identify the fiber and provide a rationale for your answer based upon your observations, readings and other studies</li>
    </ol>
<h3>Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
      <tr align="center">
        <td class="byline">Fiber 1</td>
        <td class="byline">Fiber 2 </td>
      </tr>
      <tr>
        <td><a href="/assets/images/unit-1/u1large/1-40.jpg" target="_blank"><img src="/assets/images/unit-1/1-40.jpg" width="250" height="188" border="3"></a></td>
        <td><a href="/assets/images/unit-1/u1large/1-42.jpg" target="_blank"><img src="/assets/images/unit-1/1-42.jpg" width="250" height="188" border="3"></a></td>
      </tr>
      <tr align="center">
        <td class="byline"><br>            Fiber 3 </td>
        <td class="byline"><br>
Fiber 4 </td>
      </tr>
      <tr>
        <td><a href="/assets/images/unit-1/u1large/1-44.jpg" target="_blank"><img src="/assets/images/unit-1/1-44.jpg" width="250" height="188" border="3"></a></td>
        <td><a href="/assets/images/unit-1/u1large/1-46.jpg" target="_blank"><img src="/assets/images/unit-1/1-46.jpg" width="250" height="188" border="3"></a></td>
      </tr>
      <tr>
        <td align="center" class="byline"><br>
Fiber 5 </td>
        <td align="center" class="byline"><br>
Fiber 6 </td>
      </tr>
      <tr>
        <td><a href="/assets/images/unit-1/u1large/1-48.jpg" target="_blank"><img src="/assets/images/unit-1/1-48.jpg" width="250" height="188" border="3"></a></td>
        <td><a href="/assets/images/unit-1/u1large/1-50.jpg" target="_blank"><img src="/assets/images/unit-1/1-50.jpg" width="250" height="188" border="3"></a></td>
      </tr>
      <tr>
        <td align="center" class="byline"><br>
Fiber 7 </td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td><a href="/assets/images/unit-1/u1large/1-52.jpg" target="_blank"><img src="/assets/images/unit-1/1-52.jpg" width="250" height="188" border="3"></a></td>
        <td>&nbsp;</td>
      </tr>
</table>
<p>&nbsp;        </p>
