<div align="right">
  <p><img src="/assets/images/unit-7/printing.gif" alt="" height="37" width="146" align="left" border="0"><a href="u_7_3.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Whether fabrics or finished goods have been dyed or not, additional color and patterns may be added through the printing process. Printing is a localized dyeing or coloring of fabrics and is one method of imparting design to textile items. There are a variety of printing methods, with different design effects achieved by each method. Printing methods include direct printing, discharge printing, resist printing and heat transfer printing.</p>
<h3>Direct Printing</h3>

<p>Direct printing involves the direct application of pigment or dye onto a textile.
Common methods of direct printing include block printing, roller printing and
warp printing. Although less common for mass production, block printing involves
the use of a pattern etched or carved into a block. The block is dipped into
a dye and stamped onto the fabric in a manner similar to that of using a rubber
stamp with ink on paper. If multiple colors are needed for a design, separate
blocks need to be carved and precisely placed on the fabric.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Block and sample of block print</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-40.jpg" target="_blank"><img src="/assets/images/unit-7/7-40.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<p>Roller printing is similar to block printing except that the pattern is engraved
on a cylinder to allow for a more efficient means of printing large quantities
of fabric. In the simplest sense, roller printing is much like using a rubber
stamp on a wheel. The cylinder picks up dye and selectively transfers it onto
the fabric. Like with block prints, different cylinders are used for each color
applied in the pattern. Once the rollers are set up, a large supply of fabric
can be printed very quickly and inexpensively.</p>
<table width="540" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Rollers and printed fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-41.jpg" target="_blank"><img src="/assets/images/unit-7/7-41.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-42.jpg" target="_blank"><img src="/assets/images/unit-7/7-42.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2" class="byline"><br>
      Roller printer</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-43.jpg" target="_blank"><img src="/assets/images/unit-7/7-43.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-44.jpg" target="_blank"><img src="/assets/images/unit-7/7-44.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-45.jpg" target="_blank"><img src="/assets/images/unit-7/7-45.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>&nbsp;</td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2" class="byline"><br>
      Roller printed fabric patterned to look like an even-sided twill</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-46.jpg" target="_blank"><img src="/assets/images/unit-7/7-46.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-47.jpg" target="_blank"><img src="/assets/images/unit-7/7-47.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">

        face</td>
    <td class="byline">

        reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2" class="byline"><br>
      Roller printed fabric.  Notice the repeat of the pattern.</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-48.jpg" target="_blank"><img src="/assets/images/unit-7/7-48.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-49.jpg" target="_blank"><img src="/assets/images/unit-7/7-49.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">

        face</td>
    <td class="byline">

        reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><br>
      Imperfections in roller printing</td>
    <td class="byline"><br>
      Fabric not properly aligned in printing process</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-50.jpg" target="_blank"><img src="/assets/images/unit-7/7-50.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-51.jpg" target="_blank"><img src="/assets/images/unit-7/7-51.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>In warp printing, the warp yarns are printed before the fabric is woven. The result is a softly patterned fabric.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Warp printed fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-52.jpg" target="_blank"><img src="/assets/images/unit-7/7-52.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-53.jpg" target="_blank"><img src="/assets/images/unit-7/7-53.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">

        face</td>
    <td class="byline">

        reverse</td>
  </tr>
</table>
<h3>Resist Printing</h3>
<p>Resist printing is a process where the fabric is treated in a way so that part of it will resist or not absorb dye. Methods of resist printing include batik, tie dyeing, ikat, screen printing and stenciling. Batik is a traditional form of printing where a hot wax is used to create a design on a fabric. The wax prevents the fabric from taking on color during the dyeing process. After the fabric is dyed, the wax is removed. By adding layers of wax and redyeing the fabric, a detailed and colorful pattern emerges.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Batik printed fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-54.jpg" target="_blank"><img src="/assets/images/unit-7/7-54.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-55.jpg" target="_blank"><img src="/assets/images/unit-7/7-55.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>Tie dyeing is accomplished by using a yarn or string to tie off areas of the fabric or yarns being dyed. The areas of the fabric or yarn that are tightly tied will take on little or no color during the dyeing process. Thus, the result is a fabric or yarn with a variegated coloration or a distinctive tie-dyed pattern.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Tie-dyed fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-7/u7large/7-56.jpg" target="_blank"><img src="/assets/images/unit-7/7-56.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>Ikat is a form of batik. In ikat, warp yarns are strung on a loom in preparation for weaving. Areas of the yarns are treated with wax or other materials that would prevent the yarns from taking on color. The yarns are dyed and the resist material is later removed. The fabric is then woven, creating a distinctively soft, subtle pattern.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Ikat printed fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-7/u7large/7-57.jpg" target="_blank"><img src="/assets/images/unit-7/7-57.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<p>Screen printing uses a separate screen for each color in the pattern. The screens
block areas that are not intended to be colored on the fabric and allow pigments
to transfer to the textile in desired portions of the design. A complex pattern
may use several screens. A detailed design such as that seen in silk scarves
is often screen printed using many individually handcrafted screens. During
screen printing the fabric is spread on a table, the first flat screen is placed
in proper position on the fabric, print paste, dye, or pigment is squeezed through
the porous part of the screen, and the screen is removed. The process is repeated
with each screen for each color. In some cases, individual screens are moved
consecutively down the length of the fabric to print a repetitive pattern.</p>
<h4>Traditional Screen Printing</h4>
<table width="540" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td><span class="byline">Fabric is stretched, smoothed and prepared for screen printing.</span></td>
    <td><span class="byline">Screens are selected.</span></td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-58.jpg" target="_blank"><img src="/assets/images/unit-7/7-58.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-59.jpg" target="_blank"><img src="/assets/images/unit-7/7-59.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td><span class="byline">The first screen is carefully placed on the fabric and pigment is spread on the screen.</span></td>
    <td><span class="byline">The first screen is removed.</span></td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-60.jpg" target="_blank"><img src="/assets/images/unit-7/7-60.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td class="byline"><a href="/assets/images/unit-7/u7large/7-61.jpg" target="_blank"><img src="/assets/images/unit-7/7-61.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td><span class="byline">Subsequent screens are placed and pigments added.</span></td>
    <td><span class="byline">Each screen adds another layer of color in the pattern.</span></td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-62.jpg" target="_blank"><img src="/assets/images/unit-7/7-62.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-63.jpg" target="_blank"><img src="/assets/images/unit-7/7-63.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td><span class="byline">Screening is repeated until all of the screens for a particular pattern have been used, and the final design on the fabric has been achieved.</span></td>
    <td></td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-64.jpg" target="_blank"><img src="/assets/images/unit-7/7-64.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      Screen printed fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-65.jpg" target="_blank"><img src="/assets/images/unit-7/7-65.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-66.jpg" target="_blank"><img src="/assets/images/unit-7/7-66.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">

        face</td>
    <td class="byline">

        reverse</td>
  </tr>
</table>
<p>Due to the expense, most resist printing is now accomplished through the use of automated systems. Automated systems either move the fabric (rather than the screen) or employ the use of rotary screens.</p>
<table width="540" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Automated high-speed screen printing</td>
    <td class="byline">Screens are developed</td>
  </tr>
  <tr align="center" valign="top">
    <td valign="top"><a href="/assets/images/unit-7/u7large/7-67.jpg" target="_blank"><img src="/assets/images/unit-7/7-67.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-68.jpg" target="_blank"><img src="/assets/images/unit-7/7-68.jpg" alt="" height="250" width="188" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">Screens are put into frame.</td>
    <td class="byline">Fabric is slid into place and pigment is squeezed through the screen.</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-69.jpg" target="_blank"><img src="/assets/images/unit-7/7-69.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-70.jpg" target="_blank"><img src="/assets/images/unit-7/7-70.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">Screens are cleaned for subsequent use.</td>
    <td class="byline">&nbsp;</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-71.jpg" target="_blank"><img src="/assets/images/unit-7/7-71.jpg" alt="" height="250" width="188" border="0"></a></td>
    <td></td>
  </tr>
</table>

<h4><em>Rotary Screen Printing</em></h4>
<p>Rotary screen printing involves the use of a series of revolving cylindrical
screens instead of flat screens. The squeegee, located in a fixed position inside
each rotating screen, forces printing paste onto the fabric. The fabric passes
at a constant speed between the screen and a rubber or steel roller. Today,
rotary screen printing is the most widely used form of screen printing.</p>
<h3>Discharge Printing</h3>
<p>Discharge printing is a process where a discharge material is applied to a previously dyed textile in a desired pattern. The discharge material removes the color from the fabric, resulting in a lighter pattern on a darker background.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Discharge printed fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-72.jpg" target="_blank"><img src="/assets/images/unit-7/7-72.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-73.jpg" target="_blank"><img src="/assets/images/unit-7/7-73.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">

        face</td>
    <td class="byline">

        reverse</td>
  </tr>
</table>
<h3>Heat Transfer Printing </h3>

<p>Heat transfer printing is a common method of printing. The pattern is first
put on a paper backing and is later transferred to the textile product using
heat to transfer and set the pattern. T-shirts, jackets and caps often have
designs that have been printed using the heat transfer method. With the development
of today&rsquo;s home and office computer equipment, heat transfer patterns
can be easily created and applied at home. Heat transfer printing is generally
only effective for fabrics with thermoplastic fibers.</p>
<table width="540" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Heat transfer printed fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-74.jpg" target="_blank"><img src="/assets/images/unit-7/7-74.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-7/u7large/7-75.jpg" target="_blank"><img src="/assets/images/unit-7/7-75.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">

        face<br>
      <br>
    </td>
    <td class="byline">

        reverse<br>
      <br>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td><span class="byline">Heat transfers may loosen, flake or peel from the fabric.</span></td>
    <td></td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-76.jpg" target="_blank"><img src="/assets/images/unit-7/7-76.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td></td>
  </tr>
</table>
<h3>Other Methods of Printing </h3>

<p>Other methods of printing are often used for specific purposes. Examples include
jet printing, photographic printing, flock printing, and digital printing. Jet
printing of textiles is much like a computer ink jet printing process where
color is injected or printed on the fabric in a pattern by jets. Photographic
textile printing uses a technique similar to the process used for standard photographs,
imparting realistic patterns on a fabric. Flock printing imparts short fibers,
as opposed to a dye, on a base fabric to create the design. Digital printing
is much slower and more costly than other printing methods making it most suitable
for product samples or artistic pieces.</p>
<table width="540" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Photographic printed fabric</td>
    <td class="byline">Flock printed fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-7/u7large/7-77.jpg" target="_blank"><img src="/assets/images/unit-7/7-77.jpg" alt="" height="188" width="250" border="0"></a></td>

<td><a href="/assets/images/unit-7/u7large/7-78.jpg" target="_blank"><img src="/assets/images/unit-7/7-78.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Objectives</h3>
<ol>
  <li>To observe the results of various printing methods
  <li>To assess differences in results of textiles printed through various methods
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you view the photographs showing different printed fabrics.</p>
<blockquote>
  <p><span class="boldital">Direct Printing:</span> Direct printing is the process of applying pigment or dyes to a fabric or yarn in a desired pattern.   </p>
  <p><span class="boldital">Discharge Printing: </span> Discharge printing is the process of selectively removing color from a previously dyed fabric.  Is the background color of the fabric darker than the pattern?  </p>
  <p><span class="boldital">Heat Transfer Printing:</span>  Heat transfer printing is the process of transferring a pattern from a paper backing to a fabric using heat.  Is the pattern in a localized area?  Is the pattern apparent from only one side of the fabric?</p>
  <p><span class="boldital">Resist Printing:</span>  Resist printing is the process of placing a material that will not allow for the transfer of pigments or dye onto a fabric and then dyeing the fabric.   Is the pattern apparent on both sides of the fabric?  </p>
</blockquote>
<h3>Observations</h3>
<p>This exercise is intended to provide a better understanding of the resulting effects of using various methods for printing fabrics. You will be shown a series of photographs depicting fabrics that were printed through different methods. Carefully view the fabrics and note your observations. Identify which printing method you think was most likely used and provide a justification for your determination. Record your notes on the related worksheet. Which printing method was most likely used? Can you determine the variation of the method that was used? If you cannot clearly identify the printing process used, what printing processes might have been used? Why?</p>
<h3>Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Printing 1</td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-79.jpg" target="_blank"><img src="/assets/images/unit-7/7-79.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Printing 2</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-80.jpg" target="_blank"><img src="/assets/images/unit-7/7-80.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-81.jpg" target="_blank"><img src="/assets/images/unit-7/7-81.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">

      face</td>
    <td class="byline">

      close up</td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Printing 3</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-82.jpg" target="_blank"><img src="/assets/images/unit-7/7-82.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-83.jpg" target="_blank"><img src="/assets/images/unit-7/7-83.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">

      face</td>
    <td class="byline">

      reverse</td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Printing 4</td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-84.jpg" target="_blank"><img src="/assets/images/unit-7/7-84.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Printing 5</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-85.jpg" target="_blank"><img src="/assets/images/unit-7/7-85.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-86.jpg" target="_blank"><img src="/assets/images/unit-7/7-86.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">

      face</td>
    <td class="byline">

      reverse</td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Printing 6</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-87.jpg" target="_blank"><img src="/assets/images/unit-7/7-87.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-88.jpg" target="_blank"><img src="/assets/images/unit-7/7-88.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">

      face</td>
    <td class="byline">

      reverse</td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Printing 7</td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-89.jpg" target="_blank"><img src="/assets/images/unit-7/7-89.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Printing 8</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-90.jpg" target="_blank"><img src="/assets/images/unit-7/7-90.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-91.jpg" target="_blank"><img src="/assets/images/unit-7/7-91.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">

      face</td>
    <td class="byline"> reverse</td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Printing 9</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-7/u7large/7-92.jpg" target="_blank"><img src="/assets/images/unit-7/7-92.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-7/u7large/7-93.jpg" target="_blank"><img src="/assets/images/unit-7/7-93.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">

      face</td>
    <td class="byline">

      reverse</td>
  </tr>
</table>
