<div align="right">
  <p><img src="/assets/images/unit-6/animal.gif" alt="" height="37" width="258" align="left" border="0"><a href="u_6_6.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Animal hides and skins form the basis of leathers and furs.  Because they are not a textile based product, leathers and furs are not technically textiles.  However, they are often used in applications in the same manner as other textile products.   </p>
<h3>Leather</h3>
<p>Leather is the resulting product of an animal hide or skin that has been tanned or preserved. The appearance, quality and cost of the leather depends on the type and quality of the hide or skin and the methods used in processing the hide. Typically, cowhide, pig skin, calf skin, or lamb skin is used for most leather goods. However, some leathers have their origin in alligator, ostrich, snake, eel or other exotic species.</p>
<p>To produce leather, the hide or skin is first soaked in a salt solution and the hair and lower layers of skin are removed. Later, the hide is tanned using one of a variety of tanning or chemical agents and finished to create the desired look and quality. The tanning process preserves the leather and makes it soft and pliable. In some cases, the leather is split into two or more layers. The outer-most layer of the leather, known as top grain, takes on the best appearance and wear characteristics, while layers nearer to the animal body are not as smooth and have a tendency to scuff or scratch, showing early signs of wear.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2"> Leather</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-70.jpg" target="_blank"><img src="/assets/images/unit-6/6-70.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-71.jpg" target="_blank"><img src="/assets/images/unit-6/6-71.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">
        face</td>
    <td class="byline">
        reverse</td>
  </tr>
</table>
<p>Leathers can be given special treatments to create a unique set of characteristics or appearance. Leathers are often dyed. They may also be embossed with patterns to give them the appearance of a more exotic leather, or simply to provide a texture with an interesting appeal. The surface of the leather may be sanded to roughen the surface, as in suedes, or it may be polished to a high sheen as in patent leathers.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Suede</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-72.jpg" target="_blank"><img src="/assets/images/unit-6/6-72.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-73.jpg" target="_blank"><img src="/assets/images/unit-6/6-73.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">
        face</td>
    <td class="byline">
        reverse</td>
  </tr>
</table>
<p>The applications for leathers and suedes are numerous and diverse.   Leathers are commonly used for upholstered goods such as car interiors, sofas and chairs.  In apparel applications, leathers are commonly used for coats, jackets and accessories such as shoes, handbags, wallets and belts.  </p>
<h3>Fur</h3>
<p>Furs are similar to leather except that the hair or fur has not only been left intact, but it is the most desirable part of the finished good. Like leather, the quality, appearance and cost of the finished good is dependent on the type and quality of the pelt and the processing used. Hides of mink, fox, beaver, chinchilla, lamb and rabbit are often used for furs.</p>
<p>Furs are processed much the same as leathers except that special care must be taken to preserve the fur on the hide. Because most pelts are relatively small, they are often cut and sewn to give the illusion of a long, continuous pelt for applications such as full length coats or throws. Furs may also undergo a variety of finishing techniques. They may be dyed, sheared, brushed and/or combed to create the desired look.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Fur</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-74.jpg" target="_blank"><img src="/assets/images/unit-6/6-74.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-75.jpg" target="_blank"><img src="/assets/images/unit-6/6-75.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">
        face</td>
    <td class="byline">
        reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-76.jpg" target="_blank"><img src="/assets/images/unit-6/6-76.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">edge of fur</td>
    <td></td>
  </tr>
</table>
<h3>Objectives</h3>
<ol>
  <li>To observe the similarities and differences among various animal-product based materials<li>To assess differences between leather, suede and fur
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you observe photographs showing different animal-product materials.</p>
<blockquote>
  <p><span class="boldital">Fur:</span>  Fur is a tanned pelt with the hair or fur left in place.  Does the pelt seem like a leather with fur?   </p>
  <p><span class="boldital">Leather:</span> Leather is from a hide or skin that has been preserved and softened through a tanning process. Does the surface resemble that of a smooth-skinned animal? Does the surface appear relatively uniform?</p>
  <p><span class="boldital">Suede:</span>  Suede is a leather that has been sanded to develop a napped surface.  Does the material look like a napped leather?   Does the surface have a regular but napped finish?  </p>
</blockquote>
<h3>Observations</h3>
<p>This exercise is intended to provide an understanding of materials made from animal products as well as some of their common varieties. You will be shown a series of photographs of different materials from animal products. While viewing the materials note the qualities and describe the observable differences. Classify the material as leather, suede or fur based on your observations. Record your notes on the related worksheet.</p>
<h3>Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Animal Product 1</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-77.jpg" target="_blank"><img src="/assets/images/unit-6/6-77.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-78.jpg" target="_blank"><img src="/assets/images/unit-6/6-78.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">
        reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      Animal Product 2</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-79.jpg" target="_blank"><img src="/assets/images/unit-6/6-79.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-80.jpg" target="_blank"><img src="/assets/images/unit-6/6-80.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">
        face</td>
    <td class="byline">
        reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      Animal Product 3</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-81.jpg" target="_blank"><img src="/assets/images/unit-6/6-81.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-6/u6large/6-82.jpg" target="_blank"><img src="/assets/images/unit-6/6-82.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">
        face</td>
    <td class="byline">
        reverse</td>
  </tr>
</table>
