<div align="right">
  <p><img src="/assets/images/unit-4/basic.gif" alt="" height="37" width="227" align="left" border="0"><a href="u_4_2.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Most woven fabric is classified as a basic weave. Basic weaves are the simplest of all weaves and form the basis for other more intricate weaves. Basic weaves are categorized as plain weaves, twill weaves or satin weaves. Each will be examined in more detail.</p>
<h3>Plain Weaves</h3>
<p>The most common weave is the plain weave. The plain weave is produced when a weft yarn is woven over a warp yarn and under the next warp yarn in a repetitious pattern across the entire width of the fabric. In each subsequent row, the weft yarn is woven over and under and in the opposite direction of the previous row. As a result, a fabric with a maximum number of interlacings produces a very regular, repetitious weave. By varying the type and weight of the yarns and the number of yarns in a given area, plain weaves can range from lightweight, sheer fabrics to heavy, opaque fabrics.</p>
<p>Balanced weaves are the most common of the plain weaves. They have a very even checkerboard appearance produced by using similar yarns in both the warp and weft directions and by evenly spacing the warp and weft yarns. Because there are so many balanced weaves, they are divided by the weight of the fabric. Examples of balanced weaves are listed in the chart.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">A balanced plain weave fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2">
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="250" height="330">
              <param name="movie" value="..//assets/images/unit-4/animations/Balanced_Folder/balanced.swf">
              <param name="quality" value="high">
              <embed src="..//assets/images/unit-4/animations/Balanced_Folder/balanced.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="250" height="330"></embed>
      </object> -->
    </td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-10.jpg" target="_blank"><img src="/assets/images/unit-4/4-10.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-11.jpg" target="_blank"><img src="/assets/images/unit-4/4-11.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
<table border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>BALANCED PLAIN WEAVES</caption>
  <tr align="center">
    <th>Light Weight</th>
    <th>Medium Weight</th>
    <th>Heavy Weight</th>
  </tr>
  <tr align="center">
    <td>Cheesecloth</td>
    <td>Calico</td>
    <td>Butcher linen</td>
  </tr>
  <tr align="center">
    <td>Chiffon</td>
    <td>Chambray</td>
    <td>Crash</td>
  </tr>
  <tr align="center">
    <td>Crinoline</td>
    <td>Chintz</td>
    <td>Flannel</td>
  </tr>
  <tr align="center">
    <td>Gauze</td>
    <td>Flannelette</td>
    <td>Homespun</td>
  </tr>
  <tr align="center">
    <td>Georgette</td>
    <td>Gingham</td>
    <td>Osnaburg</td>
  </tr>
  <tr align="center">
    <td>Ninon</td>
    <td>Muslin</td>
    <td>Tweed</td>
  </tr>
  <tr align="center">
    <td>Organdy</td>
    <td>Percale</td>
    <td>&nbsp;</td>
  </tr>
  <tr align="center">
    <td>Organza</td>
    <td>Pongee</td>
    <td>&nbsp;</td>
  </tr>
  <tr align="center">
    <td>Voile</td>
    <td>True crepe</td>
    <td>&nbsp;</td>
  </tr>
</table>
<p>By varying the size of the warp and weft yarns or by varying the number of warp and weft yarns in a given area, an unbalanced plain weave is produced. The result is a plain weave that has a ribbed effect. Like balanced weaves, unbalanced plain weaves are grouped by their weight. See examples of unbalanced fabrics in the corresponding list.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">An unbalanced plain weave fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2">
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="250" height="330">
              <param name="movie" value="..//assets/images/unit-4/animations/Unbalanced_Folder/unbalanced.swf">
              <param name="quality" value="high">
              <embed src="..//assets/images/unit-4/animations/Unbalanced_Folder/unbalanced.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="250" height="330"></embed>
      </object> -->
    </td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-12.jpg" target="_blank"><img src="/assets/images/unit-4/4-12.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-13.jpg" target="_blank"><img src="/assets/images/unit-4/4-13.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
<table border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>UNBALANCED PLAIN WEAVES</caption>
  <tr align="center">
    <th>Light Weight</th>
    <th>Medium Weight</th>
    <th>Heavy Weight</th>
  </tr>
  <tr align="center">
    <td>Crepe de Chine</td>
    <td>Broadcloth</td>
    <td>Bedford cord</td>
  </tr>
  <tr align="center">
    <td>&nbsp;</td>
    <td>Shantung</td>
    <td>Bengaline</td>
  </tr>
  <tr align="center">
    <td>&nbsp;</td>
    <td>Taffeta</td>
    <td>Faille</td>
  </tr>
  <tr align="center">
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>Grosgrain</td>
  </tr>
  <tr align="center">
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>Poplin</td>
  </tr>
</table>
<p>A basket weave is another variation of the plain weave. A basket weave is created when two or more warp yarns cross over and under one or more weft yarns or two or more weft yarns cross over one or more warp yarns in succession. Because the yarns pass over more than one yarn, basket weaves have longer floats than other plain weaves. However, the uniformity and regularity of the weave is similar to other plain weaves. Basket weaves are often designated by the number of yarns passed over in each direction. Common basket weaves are indicated as 2 X 2 or 4 X 4. Other common basket weaves are the 2 X 1 or the 2 X 3. Note some of the common basket weaves in the chart.</p>
<p></p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">A basket weave construction</td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2">
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="250" height="330">
              <param name="movie" value="..//assets/images/unit-4/animations/Basket_Folder/basket.swf">
              <param name="quality" value="high">
              <embed src="..//assets/images/unit-4/animations/Basket_Folder/basket.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="250" height="330"></embed>
      </object> -->
    </td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-14.jpg" target="_blank"><img src="/assets/images/unit-4/4-14.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-15.jpg" target="_blank"><img src="/assets/images/unit-4/4-15.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
<table width="155" border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>BASKET WEAVES</caption>
  <tr align="center">
    <td>Canvas</td>
  </tr>
  <tr align="center">
    <td>Duck cloth</td>
  </tr>
  <tr align="center">
    <td>Hopsacking</td>
  </tr>
  <tr align="center">
    <td>Monk&rsquo;s cloth</td>
  </tr>
  <tr align="center">
    <td>Oxford</td>
  </tr>
  <tr align="center">
    <td>Sailcloth</td>
  </tr>
</table>
<p>Because plain weaves have many interlacings, these fabrics tend to wrinkle more than other weaves, but they ravel less and tend to be more absorbent. Plain weaves are very versatile and relatively inexpensive to produce, making them a suitable fabric for a variety of applications.</p>
<h3>Twill Weaves</h3>
<p>Unlike plain weaves, twill weaves have a distinctive diagonal pattern. In twill weaves each warp or weft yarn floats across two or more yarns before being woven into the fabric, producing the diagonal line in the fabric. If the face of the fabric shows a comparable amount of warp and weft yarns, the twill is said to be even-sided. If, however, more warp yarns are showing on the face than weft yarns, the weave is known as a warp-faced twill. In rare instances more weft yarns show on the face of the fabric than warp yarns, creating a filling-faced twill.</p>
<p>The slope or degree of the angle of the diagonal line can be changed by varying the number, tightness and size of yarns. Similarly, by altering the direction of the weave, the diagonal can be changed from a downward slope to an upward slope. Most notably the change in apparent direction can be seen in herringbone fabrics, where the direction of the slope changes in a repeated pattern.</p>
<p>Because twill weaves have fewer interlacings than plain weave fabrics, they tend to be heavier and sturdier. Twills also tend to resist wrinkling and soiling more than most plain weaves. Such qualities make twills serviceable weaves for a variety of end-uses. Note the various types of twill weaves in the chart.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Twill weave construction</td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2">
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="250" height="330">
              <param name="movie" value="..//assets/images/unit-4/animations/Twill_Folder/twill.swf">
              <param name="quality" value="high">
              <embed src="..//assets/images/unit-4/animations/Twill_Folder/twill.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="250" height="330"></embed>
      </object> -->
    </td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-16.jpg" target="_blank"><img src="/assets/images/unit-4/4-16.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-17.jpg" target="_blank"><img src="/assets/images/unit-4/4-17.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face<br>
      <br>
    </td>
    <td class="byline">reverse<br>
      <br>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      A herringbone twill weave<br>
      Note the change of direction of the slope in the twill weave</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-18.jpg" target="_blank"><img src="/assets/images/unit-4/4-18.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-19.jpg" target="_blank"><img src="/assets/images/unit-4/4-19.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
<table border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>TWILL WEAVES</caption>
  <tr align="center">
    <th>Even-Sided</th>
    <th>Warp-Faced</th>
  </tr>
  <tr align="center">
    <td>Flannel</td>
    <td>Chino</td>
  </tr>
  <tr align="center">
    <td>Herringbone</td>
    <td>Denim</td>
  </tr>
  <tr align="center">
    <td>Houndstooth</td>
    <td>Drill</td>
  </tr>
  <tr align="center">
    <td>Plaids</td>
    <td>Gabardine</td>
  </tr>
  <tr align="center">
    <td>Serge</td>
    <td>Jean</td>
  </tr>
</table>
<h3>Satin Weaves</h3>
<p>Satin weaves are characterized by yarns floating in one direction over four or more yarns before they are secured by a single yarn. If the warp yarns are floating on the face of the fabric, the weave is known as a warp-faced satin. If the weft yarns are floating on the face of the fabric, the weave is instead known as a filling-faced satin.</p>
<p>The long float yarns of the satin weave give the fabric a smooth and lustrous appearance, but the long floats of this weave are susceptible to snagging. Satins are often used in apparel for lining fabrics, as they slide easily over other fabrics. In home furnishings, satin sheets are considered smooth and elegant. Note the various types of satin weaves in the chart.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Satin weave construction</td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2">
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="250" height="330">
              <param name="movie" value="..//assets/images/unit-4/animations/Satin_Folder/satin.swf">
              <param name="quality" value="high">
              <embed src="..//assets/images/unit-4/animations/Satin_Folder/satin.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="250" height="330"></embed>
      </object> -->
    </td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-20.jpg" target="_blank"><img src="/assets/images/unit-4/4-20.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-21.jpg" target="_blank"><img src="/assets/images/unit-4/4-21.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
<table width="128" border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>SATIN WEAVES</caption>
  <tr align="center">
    <td>Antique satin</td>
  </tr>
  <tr align="center">
    <td>Crepe-backed satin</td>
  </tr>
  <tr align="center">
    <td>Peau de soie</td>
  </tr>
  <tr align="center">
    <td>Sateen</td>
  </tr>
  <tr align="center">
    <td>Satin</td>
  </tr>
  <tr align="center">
    <td>Slipper satin</td>
  </tr>
</table>
<p>This segment is designed to provide a general understanding of basic weaves as well as a comparison of plain, twill and satin weaves. After completing this segment you should be able to assess differences among the various basic weaves. It should be noted, however, that many fabric designers combine different basic weaves to create more interesting patterns and effects.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Use of more than one basic weave in a single fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-4/u4large/4-22.jpg" target="_blank"><img src="/assets/images/unit-4/4-22.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-4/u4large/4-23.jpg" target="_blank"><img src="/assets/images/unit-4/4-23.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-24.jpg" target="_blank"><img src="/assets/images/unit-4/4-24.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-25.jpg" target="_blank"><img src="/assets/images/unit-4/4-25.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>
<h3>Objectives</h3>
<ol>
  <li>To observe characteristics of basic weaves
  <li>To note differences in plain, twill and satin weaves
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you view the photos showing different types of basic weaves.</p>
<blockquote>
  <p><span class="boldital">Plain Weave:</span> Plain weaves are relatively smooth, very regular, and repetitive weaves. Does the weave appear very simple and basic? Does the pattern appear to have numerous, repetitive interlacings?</p>
  <p><span class="boldital">Twill Weave:</span> Twill weaves are basic weaves with an obvious diagonal pattern created from short yarn floats. Does the weave appear smooth and regular? Does the weave have a distinctive diagonal line in the weave?</p>
  <p><span class="boldital">Satin Weave:</span> Satin weaves are smooth, basic woven fabrics with long floats of yarn on the face side. Does the fabric appear uniform and smooth? Does the woven fabric have long floats of yarn in a single direction?</p>
</blockquote>
<h3>Observations</h3>

<p>This exercise is intended to provide an understanding of basic woven fabrics
as well as a comparison of plain, twill and satin weaves. First, you will be
shown an example of a specific woven fabric with indications as to why it was
classified as such. Next, you will be shown a series of photographs of different
basic weaves. While viewing the weaves, note the qualities and describe the
apparent differences. Classify the weave as plain, twill or satin based on your
observations. Record your notes on the related worksheet. If the sample is a
plain weave, is it a balanced, unbalanced or basket weave? If the sample is
a twill weave, is it an even-sided, warp-faced or filling-faced twill? If the
sample is a satin, is it a warp-faced or filling-faced satin? Can you identify
the name of the specific type of weave shown?</p>
<h3>Sample Test</h3>
<p>
  <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="80" height="40">
        <param name="movie" value="c4st.swf">
        <param name="quality" value="high">
        <embed src="c4st.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="80" height="40"></embed>
    </object> -->
    <video width="500" height="50" controls>
      <source src="/assets/videos/unit-4/c4st.mp4" type="video/mp4">
      Your browser does not support the video tag
    </video>
</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">

<tr align="center" valign="top">
<td>
<p><a href="/assets/images/unit-4/u4large/4-26.jpg" target="_blank"><img src="/assets/images/unit-4/4-26.jpg" width="250" height="188" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-27.jpg" target="_blank"><img src="/assets/images/unit-4/4-27.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<h3>Tests</h3>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Weave 1</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-28.jpg" target="_blank"><img src="/assets/images/unit-4/4-28.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-29.jpg" target="_blank"><img src="/assets/images/unit-4/4-29.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      Weave 2</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-30.jpg" target="_blank"><img src="/assets/images/unit-4/4-30.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-31.jpg" target="_blank"><img src="/assets/images/unit-4/4-31.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      Weave 3</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-32.jpg" target="_blank"><img src="/assets/images/unit-4/4-32.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-33.jpg" target="_blank"><img src="/assets/images/unit-4/4-33.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      Weave 4</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p>
        <a href="/assets/images/unit-4/u4large/4-34.jpg" target="_blank">
          <img src="/assets/images/unit-4/4-34.jpg" alt="" height="188" width="250" border-color="white">
        </a>
      </p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-35.jpg" target="_blank">
        <img src="/assets/images/unit-4/4-35.jpg" alt="" height="188" width="250" border-color="white">
      </a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      Weave 5</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-36.jpg" target="_blank"><img src="/assets/images/unit-4/4-36.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-37.jpg" target="_blank"><img src="/assets/images/unit-4/4-37.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      Weave 6</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-38.jpg" target="_blank"><img src="/assets/images/unit-4/4-38.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-39.jpg" target="_blank"><img src="/assets/images/unit-4/4-39.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
