<div align="right">
  <p><img src="/assets/images/unit-5/conclusion.gif" alt="" height="37" width="189" align="left" border="0"><a href="u_5_4.html" target="_blank">Printable Page</a></p>
</div>
<h3><br>
  Threads of History</h3>
<p>Most likely knitting is not as old as weaving, but knitted cloth fragments have been found near ancient Palestine.<sup>4</sup> In 1488, knitted caps were so important that their prices were regulated by England&rsquo;s Act of Parliament. The first pair of knitted stockings was introduced to England by Spain during Queen Elizabeth I&rsquo;s reign.<sup>11</sup></p>

<p>Knitting was done by hand until 1589, when William Lee invented the first knitting
frame machine. The use of his machine replaced most knits that were made by
hand. The flatbed knitting machine used spring beard type needles and produced
knitted cloth ten times faster than hand knitting.<sup>1,4</sup> Lee asked Queen
Elizabeth for a patent, but she refused because she feared hand knitters would
be forced out of work. With little recognition, William Lee died after immigrating
to France. His discovery was finally recognized after his brother James went
back to England and started a thriving hosiery industry.<sup>1, 7</sup> Nearly
200 years later circular knitting machines were developed. In 1864, William
Cotton invented a new machine that could shape garments by adding or dropping
stitches. This new knitting machine was great for hosiery because it could shape
heels and toes.<sup>1,4</sup></p>
<h3>Summary Questions</h3>
<ol>
  <li>What are the distinguishing characteristics of weft knits?

<li>What are the distinguishing characteristics of warp knits?
<li>When might a single knit be more advantageous than a double knit? When might a double knit be more advantageous than a single knit?
  <li>While in a store, how might one identify if a knit is a weft knit or a warp knit?
  <li>Why might a designer choose to use knits as opposed to woven fabrics for a product?<br>

</ol>
