<div align="right">
  <p><img src="/assets/images/unit-3/complexity.gif" alt="" height="37" width="299" align="left" border="0"><a href="u_3_5.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Yarns vary in their complexity. Some appear quite simplistic while others seem far more complex. There are varying degrees of complexity in yarns and various means of achieving the complexity.</p>
<p>The basic yarns that appear very regular and uniform are known as simple yarns. A variety of other yarns that are not so regular or uniform are more complex and are referred to as fancy, novelty or complex yarns. Fancy yarns may use any number of three basic components: the base yarn, effect yarn, and binder. The base yarn provides the support for the yarn structure; the effect yarn creates the decorative appearance of the yarn; and the binder yarn helps to secure the effect yarn, providing additional support and maintaining the desired appearance.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Fancy Yarn Components</td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2"><a title="Fancy Yarn Schematic" href="/assets/images/unit-3/animations/Fancy_Yarns_Folder/large_/assets/images/unit-3/Schematic.gif" target="_blank"><img src="..//assets/images/unit-3/animations/Fancy_Yarns_Folder/Schematic_sm.gif" alt="Fancy Yarn Schematic" width="210" height="336" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><br>
          Simple yarn</td>
    <td class="byline"><br>
      Fancy yarn</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-60.jpg" target="_blank"><img src="/assets/images/unit-3/3-60.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-61.jpg" target="_blank"><img src="/assets/images/unit-3/3-61.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<p>By manipulating various components, yarns take on different appearances, creating
a number of different types of fancy yarns. Some of the more common fancy, novelty
or complex yarns are as follows:</p>

<p><b> Boucl&eacute;, Curl, Gimp or Looped:</b> Boucl&eacute;, curl, gimp or looped
yarns are created by loosely looping an effect yarn around a base yarn. Ratin&eacute;
is a form of boucle with evenly spaced loops.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Loop</td>
    <td class="byline">
      Boucle yarn</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="..//assets/images/unit-3/animations/Fancy_Yarns_Folder/large_/assets/images/unit-3/Loop_large.jpg" target="_blank"><img src="..//assets/images/unit-3/animations/Fancy_Yarns_Folder/Loop.jpg" alt="Loop" height="175" width="175" border="0"></a></td>
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-62.jpg" target="_blank"><img src="/assets/images/unit-3/3-62.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p><b>Nub:</b> A nub or knot is created by tightly twisting an effect fiber around the base fiber. The nub is most easily identified when the effect and base yarns are of different colors.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Nub/Knot</td>
    <td class="byline">
      Nub yarn</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="..//assets/images/unit-3/Fancy_Yarns_Folder/large_/assets/images/unit-3/Knot_large.jpg" target="_blank"><img src="..//assets/images/unit-3/animations/Fancy_Yarns_Folder/Knot_2.jpg" alt="" height="175" width="175" border="0"></a></td>
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-63.jpg" target="_blank"><img src="/assets/images/unit-3/3-63.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p><b>Slub:</b> A slub or thick spot in a yarn is created by varying the tightness of the twist of the yarn at various intervals.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Slub</td>
    <td class="byline">
      Slub yarn</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="..//assets/images/unit-3/animations/Fancy_Yarns_Folder/large_/assets/images/unit-3/slub_large.jpg" target="_blank"><img src="..//assets/images/unit-3/animations/Fancy_Yarns_Folder/slub.jpg" alt="" height="175" width="175" border="0"></a></td>
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-64.jpg" target="_blank"><img src="/assets/images/unit-3/3-64.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p><b>Corkscrew or Spiral:</b> The appearance of corkscrew or spiral yarns is achieved by using yarns of two different fibers and often twisting one under a different tension than the other.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr>
    <td class="byline" align="center" valign="top">Corkscrew/Spiral</td>
    <td class="byline" align="center" valign="top">
      Corkscrew yarn</td>
  </tr>
  <tr>
    <td align="center" valign="top" class="byline"><a href="..//assets/images/unit-3/animations/Fancy_Yarns_Folder/large_/assets/images/unit-3/spiral_large.jpg"><img src="..//assets/images/unit-3/animations/Fancy_Yarns_Folder/spiral.jpg" alt="" height="175" width="175" border="0"></a></td>
    <td align="center" valign="top" class="byline"><a href="/assets/images/unit-3/u3large/3-65.jpg" target="_blank"><img src="/assets/images/unit-3/3-65.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p><b>Chenille:</b> The soft, fuzzy surface of chenille yarns can be created in several ways. Most commonly, a fabric is first produced and then cut into narrow strips resembling a yarn. Then, when the fabric is cut, the raw edges become very fuzzy and produce the chenille appearance. Other chenilles are created by trimming a loosely attached effect fiber to create the fuzzy appearance. Still other chenilles are created by attaching or gluing fibers to the yarn.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Chenille yarn</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-66.jpg" target="_blank"><img src="/assets/images/unit-3/3-66.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p><b>Metallic yarns:</b> Metallic yarns are often classified as fancy yarns but are created by merely adding a metallic fiber or yarn to the blend.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Metallic yarn</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-67.jpg" target="_blank"><img src="/assets/images/unit-3/3-67.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p><b>Crepe yarns:</b> Crepe yarns may be classified as fancy yarns but are merely created by tightening the twist given to a yarn, resulting in a kinked or looped yarn.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">
      Crepe yarn</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><a href="/assets/images/unit-3/u3large/3-68.jpg" target="_blank"><img src="/assets/images/unit-3/3-68.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<p><strong>Flake, Flock or Seed Yarn:</strong> Flake, flock or see yarns are distinguished
from other yarns by the tufts of fiber held to the base yarn. The binder holds
the tufts in place.</p>
<p>This segment is designed to provide a general comparison of simple and complex
yarns as well as a variety of complex yarns. After completing this segment,
you should be able to assess the basic differences in the complexity of simple
yarns and variations of fancy yarns.</p>
<h3>Objectives</h3>
<ol>
  <li>To observe simple yarns
  <li>To observe fancy yarns
  <li>To note differences between simple and fancy yarns
  <li>To observe variations of fancy yarns
  <li>To note differences between various fancy yarns
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you view the photographs of the different yarns.</p>
<blockquote>

<p><span class="boldital">Boucl&eacute;, Curl, Gimp or Looped Yarn:</span> Boucl&eacute;,
curl, gimp or looped yarns are distinguished by their irregular closed loops
surrounding the base yarn. Does the yarn have obvious loops along the entire
base yarn?</p>
<p><span class="boldital">Chenille Yarn:</span> Chenille is a very soft, fuzzy
yarn resembling a caterpillar. Does the yarn appear to have a fuzzy surface?
Does the surface appear to have cut edges of fibers perpendicular to the base
fiber?</p>
  <p><span class="boldital">Corkscrew or Spiral Yarn:</span> Corkscrew or spiral
yarns are yarns with two different yarns twisted together, so that one appears
more tightly twisted than the other, producing a corkscrew effect. Does the
yarn have a definite spiral twist? Does it appear that two different yarns
have been used to create the effect? Does it appear that one yarn is more
tightly twisted than the other?</p>
<p><span class="boldital">Crepe Yarn:</span> Crepe yarns are yarns with a high
twist count, giving an appearance of a kinked or looped yarn. Does the yarn
have apparent loops? Do the loops appear to be formed by the twist?</p>
<p><em><strong><span class="boldital">Fancy Yarn:</span> </strong></em>Fancy
yarns, also known as novelty and complex yarns, are often irregular in appearance
and dimension, creating an interesting and decorative look. Does the surface
of the yarn appear irregular? Does the yarn appear fuzzy, knobby, twisted,
or multi-textured?</p>
<p><em><strong>Flake, Flock or Seed Yarn:</strong></em> Flake, flock or seed
yarns have tufts of fiber held to the base yarn by the binder.</p>
<p><span class="boldital">Metallic Yarn:</span> Metallic yarns have metallic fibers or yarns in the finished yarn, giving it a metallic and shiny element. Does the yarn appear to have metallic fibers in it?</p>

<p><span class="boldital">Nub Yarn:</span> Nub yarns have sections where the
effect yarn is twisted closely around the base yarn, creating a nub or knot
followed by gaps where the effect yarn is </p>
<p><span class="boldital">Simple Yarn:</span> Simple yarns are generally simple
in construction, and regular and uniform in appearance. Does the yarn appear
to be constant in size, form and appearance?</p>
  <p><span class="boldital">Slub Yarn:</span> Slub yarns have slubs or sections of thicker yarn, created by a variation in the tightness of the twist at various intervals. Does the yarn appear to have thick, soft spots at various intervals? Are the apparent slubs the same color and fiber as the rest of the yarn?</p>
</blockquote>
<h3>Observations</h3>
<p>This exercise is designed to illustrate the difference between simple and fancy yarns as well as some of the varieties of fancy yarns. You will be shown several examples of simple and fancy yarns. While viewing the yarns describe apparent features. Classify the yarn as either simple or fancy. If fancy, determine which specialty type of yarn the sample is illustrating. Record your notes on the related worksheet. Which of the yarns are simple? Which of the yarns would be classified as fancy? What types of fancy yarns are shown? How were you able to distinguish between the various examples?</p>
<h3>Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Yarn Complexity 1</td>
    <td class="byline">Yarn Complexity 2</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-3/u3large/3-77.jpg" target="_blank"><img src="/assets/images/unit-3/3-77.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-70.jpg" target="_blank"><img src="/assets/images/unit-3/3-70.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Yarn Complexity 3</td>
    <td class="byline"><br>
      Yarn Complexity 4</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-3/u3large/3-71.jpg" target="_blank"><img src="/assets/images/unit-3/3-71.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-72.jpg" target="_blank"><img src="/assets/images/unit-3/3-72.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Yarn Complexity 5</td>
    <td class="byline"><br>
      Yarn Complexity 6</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-3/u3large/3-73.jpg" target="_blank"><img src="/assets/images/unit-3/3-73.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-69.jpg" target="_blank"><img src="/assets/images/unit-3/3-69.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Yarn Complexity 7</td>
    <td class="byline"><br>
      Yarn Complexity 8</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-3/u3large/3-75.jpg" target="_blank"><img src="/assets/images/unit-3/3-75.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-76.jpg" target="_blank"><img src="/assets/images/unit-3/3-76.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
