import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plain-weaves',
  templateUrl: './plain-weaves.component.html',
  styleUrls: ['./plain-weaves.component.css']
})
export class PlainWeavesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
