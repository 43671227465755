<div align="right">
  <p><img src="/assets/images/unit-10/voluntary.gif" alt="" height="37" width="305" align="left" border="0"><a href="u_10_3.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Many textile producers and textile product manufacturers have long recognized the need to establish a higher standard for production and trade beyond the involuntary regulations imposed by governmental agencies. A variety of textile associations and organizations have been formed for various special interests and have developed voluntary standards. Although they cannot mandate compliance as with governmental involuntary regulations, voluntary standards can strongly urge manufacturers and distributors to adhere to the guidelines. Voluntary programs are often evidenced in warranty, license and certification programs. If used appropriately, self-imposed, voluntary standards may reduce the need for further involuntary regulations. Some of the associations and organizations that are known for the establishment of voluntary standards in textile production and distribution are described in this section. Often, indications of their influence on product development can be seen in labels and hangtags on textile goods.</p>
<h3>American Association of Textile Chemists and Colorists (AATCC) </h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr>
    <td><img src="/assets/images/unit-10/10-50.png" width="191" height="119"></td>
  </tr>
</table>
<p>The American Association of Textile Chemists and Colorists is comprised of a group of individuals in the textile industry. The AATCC develops testing methods, establishes voluntary standards based on testing and research, and produces educational publications and programs. An example of what AATCC does is their standardized methods for testing textile properties such as visual appearance, colorfastness, dyes and finishes. For more information on AATCC and their current efforts, visit their web site: <a href="http://www.AATCC.org" target="_blank">www.AATCC.org</a>.</p>
<h3>American Fiber Manufacturers Association, Inc. (AFMA)</h3>
<table border="0" cellspacing="0" cellpadding="10">
      <tr>
        <td><img src="/assets/images/unit-10/10-51.gif" width="250" height="93"></td>
      </tr>
    </table>
<p>The American Fiber Manufacturers Association, Inc. is a domestic trade organization that represents U.S. producers of more than 90 percent of all domestic production of manufactured fibers. AFMA provides an interface between their member companies and key contacts in the federal government on industry trade policy, seeks positive outcomes on technical regulatory issues by working closely with state and federal agencies, and monitors industry trends through their statistics division, called the Fiber Economic Bureau. AFMA also has a publications program.&nbsp;</p>
<p>AFMA sponsors a very informative educational website called &ldquo;Fibersource&rdquo;.&nbsp; For more information about AFMA and a complete review of their publications (along with purchase options), visit their website at <a href="http://www.AFMA.org" target="_blank">www.AFMA.org</a>.</p>
<h3>American National Standards Institute (ANSI)</h3>
<table border="0" cellspacing="0" cellpadding="10">
      <tr>
        <td><img src="/assets/images/unit-10/10-52.gif" width="261" height="77"></td>
      </tr>
    </table>
<p>The American National Standards Institute is a group that includes representation from both trade organizations and governmental agencies. Although they do not develop testing methods or standards themselves, ANSI serves to coordinate standards established by a variety of other entities. For more information on ANSI and their current activities, visit their web site: <a href="http://www.ANSI.org" target="_blank">www.ANSI.org</a>.</p>
<h3>ASTM (American Society for Testing and Materials) International</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr>
    <td><img src="/assets/images/unit-10/10-53.gif" width="140" height="141"></td>
  </tr>
</table>
<p>ASTM International is one of the world&rsquo;s largest voluntary groups responsible for establishing and distributing technical standards for materials, products, systems and services. Through a group of committees, ASTM establishes testing methods and industry standards. One such group is the Committee on Textiles. Examples of ASTM International&rsquo;s textile testing methods and standards were noted in Unit 2, where you observed textile performance standards. For more information on ASTM and their current efforts, visit their web site: <a href="http://www.ASTM.org" target="_blank">www.ASTM.org</a>.</p>
<h3>National Council of Textile Organizations (NCTO)</h3>
<table border="0" cellspacing="0" cellpadding="10">
      <tr>
        <td><img src="/assets/images/unit-10/10-54.gif" width="384" height="44"></td>
      </tr>
    </table>
<p>The National Council of Textile Organizations is a relatively new national trade association for the textiles industry. The primary focus of NCTO is to create powerful coalitions and represent the fiber, fabric, supplier and yarn industries by protecting, strengthening and informing the American textile industry. NCTO also has plans to participate in the development of standards. An example of their efforts can be seen in current attempts to protect American textile manufacturers from excessive textile imports, resulting in unfair trade practices. For more information on NCTO and their activities, check  their website:&nbsp;<a title="www.NCTO.org" href="http://www.NCTO.org/">www.NCTO.org</a>.</p>
<h3>Upholstered Furniture Action Council (UFAC) </h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr>
    <td><img src="/assets/images/unit-10/10-55.jpg" alt="" height="56" width="200" border="0"></td>
  </tr>
</table>
<p>The Upholstered Furniture Action Council establishes voluntary standards for upholstered furniture. For example, the UFAC has voluntarily established standards for producing upholstered furniture that is more resistant to ignition from smoldering cigarettes. UFAC labels can be noted on pieces of upholstered furniture that are in compliance with the UFAC standards. For more information on UFAC and their current works, visit their web site: <a href="http://www.UFAC.org" target="_blank">www.UFAC.org</a>.</p>
<p>The following exercise will provide a general understanding of the application of voluntary regulations. After viewing the labels that were found on textile products, you should be able to assess the information provided and identify which voluntary organizations established the standards indicated on the labels.</p>
<h3>Objectives</h3>
<ol>
  <li>To observe examples of voluntary regulations on textiles and textile products
  <li>To identify and describe evidence of voluntary regulations</ol>
<h3>Observations</h3>
<p>This exercise is intended to illustrate evidence of voluntary standards on labels that might be seen on textile products.   You will be shown a series of photographs of textile product labels.   Carefully view the labels and note your observations.  Identify and describe how voluntary organizations have influenced the information provided.  What voluntary organizations are cited on the label?  What voluntary standards influenced the provision of the information on the label?  What information is required on the label?  What additional information is provided on the label?  </p>
<h3>Tests</h3>
<table width="294" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Voluntary Standards Label 1</td>
    <td class="byline">Voluntary Standards Label 2</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-10/u10large/10-56.jpg" target="_blank"><img src="/assets/images/unit-10/10-56.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-10/u10large/10-57.jpg" target="_blank"><img src="/assets/images/unit-10/10-57.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Voluntary Standards Label 3</td>
    <td class="byline"><br>
      Voluntary Standards Label 4</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-10/u10large/10-58.jpg" target="_blank"><img src="/assets/images/unit-10/10-58.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-10/u10large/10-59.jpg" target="_blank"><img src="/assets/images/unit-10/10-59.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Voluntary Standards Label 5</td>
    <td class="byline"><br>
      Voluntary Standards Label 6</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-10/u10large/10-60.jpg" target="_blank"><img src="/assets/images/unit-10/10-60.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-10/u10large/10-61.jpg" target="_blank"><img src="/assets/images/unit-10/10-61.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
