<div align="right">
  <p><img src="/assets/images/unit-2/colorfastness.gif" alt="" height="37" width="216" align="left" border="0"><a href="u_2_3.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
Colorfastness refers to the ability of a fiber or fabric to retain its color.
The colorfastness properties of a fabric can be examined through several measures.
As a textile comes in contact with outside elements it may change or lose color.
Sometimes the color fades or dissipates and at other times the hue of the color
changes. In some cases, the color transfers to the other element such as another
fabric, one's skin, or in the wash water. &nbsp;The following table shows a
partial list of the elements and conditions where colorfastness may be an issue:</p>
<table border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <tr>
    <th colspan="2" valign="top" width="481">
      <p align="center">CONDITIONS FOR TESTING COLORFASTNESS</p>
    </th>
  </tr>
  <tr>
    <td valign="top" width="239">
      <p>Air or atmosphere</p>
    </td>
    <td valign="top" width="239">
      <p>Ironing or pressing</p>
    </td>
  </tr>
  <tr>
    <td valign="top" width="239">
      <p>Bleaching</p>
    </td>
    <td valign="top" width="239">
      <p>Laundering or washing</p>
    </td>
  </tr>
  <tr>
    <td valign="top" width="239">
      <p>Chlorinated or pool water</p>
    </td>
    <td valign="top" width="239">
      <p>Perspiration</p>
    </td>
  </tr>
  <tr>
    <td valign="top" width="239">
      <p>Crocking or rubbing</p>
    </td>
    <td valign="top" width="239">
      <p>Salt or sea water</p>
    </td>
  </tr>
  <tr>
    <td valign="top" width="239">
      <p>Dry cleaning</p>
    </td>
    <td valign="top" width="239">
      <p>Ultraviolet or sunlight</p>
    </td>
  </tr>
</table>
<p>Colorfastness in a product is very important. Few consumers appreciate textile products that leave color on other goods or themselves; nor are most consumers pleased with products that fade or change color from when they were purchased. For example, draperies that fade from sunlight or atmospheric conditions are generally considered a poor investment. Swimming suits that fade in pool or sea water quickly lose their aesthetic appeal. On the contrary, consumers are generally pleased with their investment when they purchase a variety of pieces in a collection of home linens, or various apparel items; use and care for the pieces over a period of time; and the items retain their color and continue to appear as a unified set.&nbsp;</p>
<p>Consumers assume, almost unconsciously, that products have strong colorfastness properties. However, colorfastness is no accident. Textiles are developed and tested to insure colorfastness.</p>
<p>As the list of conditions in which colorfastness should be examined is lengthy, this section will focus on a select few of those conditions. Crocking or rub-off occurs when the color of a textile rubs off or transfers onto another fabric, material, or one&rsquo;s skin. The transfer occurs when the two materials are in direct contact, rubbing against each other. In some cases, the textile is relatively colorfast when the fabric is dry but prone to crocking when the test specimen is moist. Problems from crocking can occur when apparel fabric transfers color onto an upholstered seat, or the fabric of the upholstered seat transfers color onto the apparel of the person sitting on it. More commonly, problems from crocking occur when color from one apparel item or accessory transfers color onto another apparel item.</p>

<p>Colorfastness to perspiration is an important consideration in the design and
development of apparel, particularly active sportswear. If a fabric is colorfast
to perspiration, little color transfer will take place as the fabric is exposed
to perspiration moisture and heat. If a fabric is not colorfast to perspiration,
color from the fabric will typically bleed or rub onto other fabrics, resulting
in a temporary or permanent change in color of either or both fabrics.&nbsp;&nbsp;&nbsp;</p>
<p>Some fabrics, if exposed to sunlight for an extended period, fade or lose their intensity of color. Such fabrics are considered to have poor colorfastness to light. Fabrics that retain their color after extended exposure to light are considered to be colorfast to light. Colorfastness to light is particularly critical for fabrics used in automobiles and boats, in awnings and umbrellas, in draperies and window coverings, and in outdoor apparel such as jackets and swimming suits.</p>
<p>A variety of tests and testing equipment are essential for scientifically measuring the colorfastness of fabrics. Because exposure to so many different elements can influence the colorfastness of textiles, a variety of tests and equipment are needed. The following experiments will provide a general understanding of the process for testing and evaluating colorfastness. After viewing the tests, you should be able to assess the colorfastness properties of the sample fabrics. &nbsp;A textiles laboratory would conduct additional colorfastness tests as appropriate to measure other aspects of colorfastness for specific applications.</p>
<h3>Objectives</h3>
<ol>
  <li>To observe the colorfastness of sample fabrics
  <li>To assess differences in colorfastness properties of various fibers and fabrics
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you watch the video clips showing different fabrics being tested for colorfastness.</p>
<blockquote>

<p><b><i>Colorfastness</i></b><b>:</b> &nbsp;Colorfastness is the ability of
fibers or fabrics to retain their color through use and care such as after
exposure to elements like sunlight, cleaning processes or contact with skin
or other materials. Does the fabric retain its color? Does the color rub off
or bleed onto other fabrics or materials?</p>
  <p><b><i>Crocking:</i></b> Crocking is the rubbing off of color from one fabric to another fabric, material, or one&rsquo;s skin. Does a fabric or material that comes in contact with the textile take on color from that textile?&nbsp;</p>
</blockquote>
<h3>Standardized Procedures and Observations</h3>

<p>Scientific testing for colorfastness requires the use of specialized testing
equipment in a controlled laboratory setting. The colorfastness tests shown
in this section were conducted in a controlled laboratory environment at the
J.C. Penney's Quality Assurance Center according to approved, standardized testing
methods.<b><i> </i></b></p>
<p>The first video clip shows a fabric being tested for colorfastness to crocking. Colorfastness to crocking in this test is conducted using a crockmeter in accordance with American Association of Textile Chemists and Colorists (AATCC) standards. A sample of fabric is mounted on the crockmeter along with a dry, white test fabric. After a specified number of rubs with controlled pressure, the test sample is removed and compared to a gray scale for staining to measure the amount of crocking that has occurred. A second test is run according to the same procedures, except that a moist, white test fabric is used.&nbsp;</p>
<p>In the second video clip, colorfastness to perspiration is tested using AATCC standards. The fabric sample and a multifiber test strip are weighed before being placed into a beaker. An artificial acidic perspiration liquid is added to the beaker until the fabrics are submerged. After the fabrics have stayed in the solution for 30 minutes they are removed and the excess moisture is wrung out. The fabrics are reweighed to determine if an appropriate moisture gain has taken place. If the moisture content is not sufficient according to levels specified in the testing procedures, additional moisture is added. The sample fabric is placed face down against the multifiber strip in an apparatus that controls for pressure. The apparatus is heated to body temperature in an oven for 6 hours. The fabrics are then removed and left to dry. Once dry, the multifiber fabric strip is examined using the gray scale for staining to determine the colorfastness rating.</p>
<p>The video clip shown in this section illustrates the results of a colorfastness to light test as specified by AATCC standards. Sample fabrics are placed in a test machine that exposes portions of the fabric samples to extended periods of light, while keeping other areas of the same test fabric unexposed. After a set period of exposure, the sample fabrics are removed and tested using a gray scale for evaluating change in color.&nbsp;</p>
<p>While viewing each of these video clips note the processes that are used to test the fabrics for colorfastness. Record what you observe. Note the conclusions that you can draw from your observations. In the gray scale ratings, 5 is the best performance on colorfastness and 1 is the poorest. How does each of the tested fabrics rate?</p>
<h3>Standardized Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">
        Crocking</td>
    <td class="byline">
          Perspiration</td>
  </tr>
  <tr align="center">
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-20.swf">
        <param name="quality" value="high">
        <embed src="videos/2-20.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
      </object> -->
      <video width="320" height="270" controls>
        <source src="/assets/videos/unit-2/2-20.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-21.swf">
        <param name="quality" value="high">
        <embed src="videos/2-21.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
      </object> -->
      <video width="320" height="270" controls>
        <source src="/assets/videos/unit-2/2-21.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
      <br>
    </td>
      </tr>
  <tr align="center">
    <td class="byline"><br>
      Light Fastness</td>
    <td>
      <p class="byline">
      </p>
    </td>
  </tr>
  <tr align="center">
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-22.swf">
        <param name="quality" value="high">
        <embed src="videos/2-22.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
      </object> -->
      <video width="320" height="270" controls>
        <source src="/assets/videos/unit-2/2-22.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
        <td>&nbsp;</td>
      </tr>
</table>
  <h3>Non-Technical Procedures and Observations </h3>
<p>When specialized testing equipment and facilities are not available, several measures can provide a reasonable assessment of the colorfastness of a fabric. To parallel the scientific tests previously shown, this section will focus on non-technical procedures for observing colorfastness to crocking.&nbsp;</p>

<p>Colorfastness to crocking can be examined very simply by rubbing a clean white
cloth or strong paper towel against the sample fabric. &nbsp;The observer should
wrap the white fabric or paper over a finger and then rub the white fabric against
the test fabric for several strokes. Pressure should be firm but not too strong.
If testing multiple fabrics, a separate test cloth should be used for each sample
fabric being tested and the observer should take care to use approximately the
same pressure and number of rubs. The amount of transfer or rub-off on the white
fabric indicates the colorfastness of the test fabric. More color rubbed-off
on the white fabric suggests that the fabric lacks colorfastness. Less color
transfer or rub-off indicates the fabric has better colorfastness.&nbsp;</p>
<p>A similar test can be conducted with a dampened white fabric. Note if the results differ whether a damp or dry cloth is used.</p>

<p>Observe the following video clip of three fabrics as they are tested for colorfastness
to crocking. Note what happens to the white fabrics as they are rubbed against
the sample fabric. Record your observations, results and conclusions on the
related worksheet. Note the differences in the colorfastness of the various
fabrics when tested with a dry test fabric. Note the differences in the colorfastness
of the various fabrics when tested with a damp test fabric. Which fabric tends
to have the best colorfastness properties? Which seems to have the poorest colorfastness
properties? Why? &nbsp;</p>
<h3>Non-Technical Tests</h3>
<table border="0" cellspacing="0" cellpadding="5">
  <tr align="center">
    <td class="byline">Home Crocking</td>
  </tr>
  <tr align="center">
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-25.swf">
        <param name="quality" value="high">
        <embed src="videos/2-25.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
      </object> -->
      <video width="320" height="270" controls>
        <source src="/assets/videos/unit-2/2-25.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
      </tr>
</table>
<p>&nbsp;          </p>
