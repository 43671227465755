<div align="right">
  <p><img src="/assets/images/unit-3/yarn_twist.gif" alt="" height="37" width="170" align="left" border="0"><a href="u_3_3.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  The fibers in most yarns are twisted or turned as the yarn is formed.  Even filament yarns may be twisted if multiple filament fibers are used to create the yarn.  	</p>

<p>When yarns are twisted they may be twisted to the right or the left. If twisted
to the left they form a Z pattern in the yarn and are said to have a Z twist.
If twisted to the right they form an S pattern in the yarn and are said to have
an S twist.</p>
<p>In single yarns, the direction of the twist provides no real advantage. However,
ply yarns gain strength when the individual yarns comprising the ply yarn are
twisted in opposing directions.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Z Twist Animation</td>
    <td class="byline">S Twist Animation</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="250" height="600">
            <param name="movie" value="..//assets/images/unit-3/animations/Z-Yarn.swf">
            <param name="quality" value="high">
            <embed src="..//assets/images/unit-3/animations/Z-Yarn.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="250" height="600"></embed>
      </object> -->
    </td>
  <td class="byline">
    <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="250" height="600">
              <param name="movie" value="..//assets/images/unit-3/animations/S-Yarn.swf">
              <param name="quality" value="high">
              <embed src="..//assets/images/unit-3/animations/S-Yarn.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="250" height="600"></embed>
      </object> -->
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><br>
      Z twist yarn</td>
    <td class="byline"><br>
      S twist yarn</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-3/u3large/3-20.jpg" target="_blank"><img src="/assets/images/unit-3/3-20.jpg" alt="" height="188" width="250" border="0"></a><br>
    </td>
    <td><a href="/assets/images/unit-3/u3large/3-21.jpg" target="_blank"><img src="/assets/images/unit-3/3-21.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<p>The number of twists per given length such as inches or centimeters allows
for a comparison of yarn twists. As the number of twists increases, so does
the fineness, strength, and tenacity of the yarn. In some cases the yarn has
so many twists that the yarn itself will twist if not held taut. These yarns
are given a special name, crepe yarns.</p>
<table width="64" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline"><br>
    Notice the tighter twist of the yarn on the left.			  </td>
  </tr>
  <tr align="center">
    <td class="byline">					<a href="/assets/images/unit-3/u3large/3-22.jpg" target="_blank"><img src="/assets/images/unit-3/3-22.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>This segment is designed to provide a general comparison of Z and S twists, as well as the apparent difference in yarns with varying numbers of twists.   After completing this segment,  you should be able to assess the basic differences in the twists of the sample yarns.  </p>
<h3>Objectives</h3>
<ol>
  <li>To observe the direction of yarn twists
  <li>To note differences between Z and S twists
  <li>To observe variations in the tightness of the twists
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you view the photographs of the different yarns.</p>
<blockquote>
  <p><span class="boldital">S Twist:</span>  Yarns where the fibers have been tightly turned to the right.  Does the yarn appear to have diagonal lines moving downward from left to right?</p>
  <p><span class="boldital">Z Twist:</span> Yarns where the fibers have been tightly turned to the left.   Does the yarn appear to have diagonal lines moving upward from left to right?</p>
</blockquote>
<h3>Observations</h3>
<p>This exercise is designed to illustrate the difference between Z and S twists in yarns as well as any apparent difference between yarns with high twist counts and low twist counts.  You will be shown examples of yarns with varying numbers of twists.  Note the characteristics of the yarns.  Classify the yarn as having either a Z or S twist.  Record your notes on the related worksheet.  Which of the yarns have Z twists?  Which of the yarns have S twists?  Determine if the yarns appear to be yarns with a low or high twist.  Which of the yarns shows the greatest number of twists?  Which of the yarns shows the least number of twists?  </p>
<h3>Tests</h3>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Yarn Twist 1</td>
    <td class="byline">Yarn Twist 2 </td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-3/u3large/3-23.jpg" target="_blank"><img src="/assets/images/unit-3/3-23.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-24.jpg" target="_blank"><img src="/assets/images/unit-3/3-24.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
    Yarn Twist 3 </td>
    <td class="byline"><br>
      Yarn Twist 4 </td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-3/u3large/3-25.jpg" target="_blank"><img src="/assets/images/unit-3/3-25.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-26.jpg" target="_blank"><img src="/assets/images/unit-3/3-26.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
    Yarn Twist 5</td>
    <td class="byline"><br>
      Yarn Twist 6 </td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-3/u3large/3-27.jpg" target="_blank"><img src="/assets/images/unit-3/3-27.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-3/u3large/3-28.jpg" target="_blank"><img src="/assets/images/unit-3/3-28.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
