<div align="right">
  <p><img src="/assets/images/unit-9/conclusion.gif" alt="" height="37" width="189" align="left" border="0"><a href="u_9_4.html" target="_blank">Printable Page</a></p>
</div>
<h3><br>
  Threads of History</h3>
<p>Dry cleaning, as it is known today, is a relatively new development. It had its beginnings in the treatment of spots and stains on a smaller scale. As far back as the days of Pompeii, a clay known as a &ldquo;fuller&rdquo; was used to remove greasy soiling on delicate garments. But it wasn&rsquo;t until the early 18th century that turpentine was used on a regular basis for removing greasy stains on textiles.</p>
<p>In the early 19th century, a Frenchman named Jean Baptiste Jolly observed stains being lifted from a tablecloth when a paraffin lamp was spilled on the stain. Shortly thereafter, Jean Baptiste Jolly was credited with starting the first dry cleaning business, named Jolly-Belin and located in Paris, France. After its introduction, the concept of dry cleaning quickly spread across Europe. In its early years, several other commercial dry cleaning solvents were introduced, including spirits of turpentine (also known as &ldquo;camphene&rdquo;) and benzene. Because of its origins, the dry cleaning process at this time was known as &ldquo;French cleaning&rdquo;.</p>
<p>Today, the term &ldquo;French cleaning&rdquo; is used to describe a special cleaning process that requires highly skilled handwork. Dry cleaning describes the more common, commercial cleaning method. Since the 1930s, perchloroethylene, a non-flammable fluid, has replaced benzene and other more hazardous chemicals as the primary dry cleaning solvent.</p>
<h3>Summary Questions	</h3>
<ol>
  <li>Why do different stains need to be treated differently?
  <li>Why is there such an extensive variety of laundry additives on the market?
  <li>How does washing differ from dry cleaning?
  <li>Why is it best not to wash all textiles in cold water?
  <li>Are there ways to safely care for textile products other than those identified on the care label?<li>What circumstances might lead a manufacturer to suggest dry cleaning as opposed to laundering on the care label?
</ol>
