import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-absorbency',
  templateUrl: './absorbency.component.html',
  styleUrls: ['./absorbency.component.css']
})
export class AbsorbencyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
