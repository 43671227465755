import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Unit7RoutingModule } from './unit-7-routing.module';


@NgModule({
  imports: [CommonModule, ReactiveFormsModule, Unit7RoutingModule],
  declarations: [Unit7RoutingModule.components]
})

export class Unit7Module { }
