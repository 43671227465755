import { Unit5ConclusionComponent } from './unit5-conclusion/unit5-conclusion.component';
import { Unit5IntroductionComponent } from './unit5-introduction/unit5-introduction.component';
import { KnitStitchesComponent } from './knit-stitches/knit-stitches.component';
import { KnittingProcessComponent } from './knitting-process/knitting-process.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'knitting-process', component: KnittingProcessComponent },
  { path: 'knit-stitches', component: KnitStitchesComponent },
  { path: 'introduction', component: Unit5IntroductionComponent },
  { path: 'conclusion', component: Unit5ConclusionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class Unit5RoutingModule {
  static components = [ KnittingProcessComponent, KnitStitchesComponent,
      Unit5IntroductionComponent, Unit5ConclusionComponent ]
}
