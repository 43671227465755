import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-knitting-process',
  templateUrl: './knitting-process.component.html',
  styleUrls: ['./knitting-process.component.css']
})
export class KnittingProcessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
