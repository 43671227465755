<div align="right">
  <p><img src="/assets/images/unit-6/solution.gif" alt="" height="37" width="329" align="left" border="0"><a href="u_6_2.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Some fabrics, known as solution based fabrics, are produced directly from chemical solutions or polymers. Skipping the steps of first producing fibers and yarns reduces the time and expense in fabric production. Solution based fabrics consist of films or foams.</p>
<h3>Film</h3>
<p>Films are produced by extruding or molding polymer solutions into a fabric. Some films have little or no air incorporated into the solution, producing a relatively smooth barrier to water and air. Other films have small air bubbles incorporated into the solution to develop a softer, more breathable fabric. By changing the mold used to form the film, the fabric can take on different textures, such as synthetic leather or other patterns.</p>

<p>Most films, by themselves, lack strength and durability. They are often combined
with other fabric to provide the necessary stability. However, because of their
low cost and often waterproof qualities, common uses of films include shower
curtains, waterproof upholstery, rain gear, shoes and fashion accessories.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Film</td>
    <td class="byline">Film&#151;synthetic leather</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-1.jpg" target="_blank"><img src="/assets/images/unit-6/6-1.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-6/u6large/6-2.jpg" target="_blank"><img src="/assets/images/unit-6/6-2.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Foam</h3>
<p>Like films, foams are produced by extruding or molding polymer solutions. They differ from films in that the polymer has elastic qualities and more air is incorporated in the process. The end result is a fabric or product that is cushiony but lacks strength. The low cost and cushiony quality makes foam an ideal product for padding and filling. Typical uses of foam include mattresses, cushion and pillow filling, and carpet padding or backing.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Foam</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-6/u6large/6-3.jpg" target="_blank"><img src="/assets/images/unit-6/6-3.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Objectives</h3>
<ol>
  <li>To observe the similarities and differences among various solution based fabrics
  <li>To assess differences between films and foams
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you observe the photographs showing different solution based fabrics.</p>
<blockquote>
  <p><span class="boldital">Film: </span>  Films are fabrics made directly from polymer solutions with little or no air incorporated into the product.  Does the surface appear to be relatively smooth and continuous?  Does the fabric seem to lack the appearance of fibers?  </p>
  <p><span class="boldital">Foam:</span> Foams are fabrics or products made directly from polymer solutions with air incorporated into the solution. Does the fabric appear sponge-like? Does the fabric appear to have a lot of small air bubbles?</p>
</blockquote>
<h3>Observations</h3>

<p>This exercise is intended to provide an understanding of solution based fabrics
as well as some of the varieties. You will be shown a photograph of a specific
solution based fabric with indications as to why it is classified as such. Next,
you will be shown a series of photographs of different solution based fabrics.
While viewing the fabrics note the qualities and describe the observable differences.
Classify the fabric as film or foam fabric based on your observations. Record
your notes on the related worksheet. </p>
<h3>Sample Test</h3>
<p>
  <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="80" height="40">
        <param name="movie" value="c6st.swf">
        <param name="quality" value="high">
        <embed src="c6st.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="80" height="40"></embed>
    </object> -->
    <video width="500" height="50" controls>
      <source src="/assets/videos/unit-6/c6st.mp4" type="video/mp4">
      Your browser does not support the video tag
    </video>
</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr>
    <td><a href="/assets/images/unit-6/u6large/6-4.jpg" target="_blank"><img src="/assets/images/unit-6/6-4.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Solution Based Fabric 1</td>
    <td class="byline">Solution Based Fabric 2</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-6/u6large/6-5.jpg" target="_blank"><img src="/assets/images/unit-6/6-5.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-6/u6large/6-6.jpg" target="_blank"><img src="/assets/images/unit-6/6-6.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
