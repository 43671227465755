<div align="right">
  <p><img src="/assets/images/unit-4/introduction.gif" alt="" height="37" width="204" align="left" border="0"><a href="u_4_1.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Most fabric construction begins with yarns, as examined in the previous unit. However, fabrics can be produced in a variety of ways and not all fabric construction requires fibers to be first produced into yarn. This unit will focus on weaving, the most common form of fabric construction. Knits and other non-woven composites will be examined in subsequent units.</p>
<p>Weaving is the process of interlacing two sets of yarns perpendicular to each other. Warp yarns run in the vertical direction whereas weft or filling yarns pass in the horizontal direction. The weaving process results in a panel of woven fabric.</p>
<p>You may recall as a child taking strips of construction paper and weaving them together. In the simplest sense, weaving yarns into fabric is much the same process. However, the weaving of most fabrics for commercial goods is accomplished with the use of specialized, high-speed looms. 	 </p>
<table width="540" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Modern weaving machine with close up view of basic woven fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-4/u4large/4-1.jpg" target="_blank"><img src="/assets/images/unit-4/4-1.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-4/u4large/4-2.jpg" target="_blank"><img src="/assets/images/unit-4/4-2.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-4/u4large/4-3.jpg" target="_blank"><img src="/assets/images/unit-4/4-3.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td align="left" class="byline">Modern high speed weaving machine which trims selvage edges</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><br>
      Weaving machine that produces intricate fabrics by varying yarns and weaving patterns</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-4/u4large/4-4.jpg" target="_blank"><img src="/assets/images/unit-4/4-4.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td align="left"><a href="/assets/images/unit-4/u4large/4-5.jpg" target="_blank"><img src="/assets/images/unit-4/4-5.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<p>In this unit, the focus is on basic and fancy weaves as well as some of the variations of each. Examples will be shown through illustrations and photographs. The examples in this unit are fairly simple and easily identifiable. Professional textile scientists and designers often work with more complex versions of these same weaves to create unique fabrics to meet an array of special aesthetic and performance criteria. </p>
<h3>Terminology</h3>
<p>Be aware of the following terms used in this unit.</p>

<blockquote>
<p><span class="boldital">Basic Weaves:</span> Basic weaves are the simplest
and most uniform of weaves and include plain, twill and satin weaves.</p>
<p><span class="boldital">Fancy Weaves: </span> Fancy weaves are more complex
than basic weaves and are usually identified by their distinctive pattern.</p>

<p><span class="boldital">Float: </span> Floats are relatively loose yarns that
span over the top of two or more yarns. </p>
<p><span class="boldital">Selvage:</span> The selvage edge is a natural edge
of the fabric created when weft yarns reverse in direction.</p>
<p><span class="boldital">Warp:</span> Warp yarns are the vertical yarns in
a weave that run parallel to the selvage edge of the fabric.</p>
<p><span class="boldital">Weaves:</span> Weaves are fabrics produced by weaving
or interlacing warp and weft yarns perpendicular to each other.</p>
<p><span class="boldital">Weft: </span> Weft yarns or filling yarns are the
horizontal yarns in a weave that run perpendicular to the selvage edge of
the fabric.</p>
</blockquote>
<h3>Reference to Worksheets</h3>

<p>To complete this unit, refer to the worksheet for Unit 4: Woven Fabrics to
record observations. The textbook and other reference materials may be helpful
in completing this unit.<br>
</p>
