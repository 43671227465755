<div align="right">
  <p><img src="/assets/images/unit-2/resiliency.gif" alt="" height="37" width="165" align="left" border="0"><a href="u_2_6.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
The ability of a fiber or fabric to bounce back to its original shape after
being folded, bent, twisted, crushed, creased or subjected to other means of
deformation is known as the resilience property. Resiliency is determined by
comparing the appearance of fibers before being manipulated with their appearance
after manipulation. Fibers that show strong signs of resilience will regain
their smooth appearance after being twisted and wrinkled; such fibers will also
regain their loft after being compressed or even regain their creased appearance
after being flattened or straightened. Fibers that lack resilience will show
dramatic signs of wrinkling after being twisted and appear compressed after
being crushed; or, they will lose their creases after being pulled or straightened.&nbsp;</p>
<p>Resiliency qualities are particularly valuable when trying to achieve a freshly pressed look with minimal or no ironing, such as in shirts, slacks and bed linens. Other applications where resilience is important include velvety surfaced upholstery, where crushing and wrinkling from use would detract from the appearance. Yet, there are times when retaining a crease is important, such as in pleated fabrics in apparel or home furnishings.</p>

<p>The following experiments will provide a general comparison of the resilience
of several fabrics using both scientific and non-technical tests. The tests
will illustrate how the resiliency of fabrics can be tested, measured against
a standard, and compared. After viewing the tests, you should be able to assess
the resiliency properties of the sample fabrics. Advanced technical analysis,
as might be performed by a textile scientist, would be necessary to measure
smaller increments of variation in resilience between various fabrics.</p>
<h3>Objectives</h3>
<ol>
  <li>To observe the resiliency of sample fabrics
  <li>To assess differences in the resiliency of various fabrics
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you watch the video clips showing different fabrics being tested for resiliency.</p>
<blockquote>

<p><b><i>Resiliency</i></b><b>:</b> Resiliency is the ability of fibers or fabrics
to bounce back or regain their original form after being folded, bent, twisted,
crushed, creased or after undergoing any other forms of deformation. Do the
fibers return to their original shape or appearance? Does the fabric stay
in its manipulated state? To what extent does the fabric change between its
original and manipulated states?</p>
  <p><b><i>Wrinkle Resistance:</i></b> Wrinkle resistance is the ability of a fabric to retain a smooth, wrinkle free appearance after use and care. Does the fabric maintain a smooth appearance after use? Does the fabric maintain a smooth appearance after laundering? To what extent does the fabric retain its smooth appearance?</p>
</blockquote>
<h3>Procedures and Standards</h3>
<p>Several tests for resiliency properties can be conducted. One of the most commonly used resiliency tests is the wrinkle recovery test shown in this section. In this examination, the test fabric is laundered and compared to a set of smoothness appearance replicas according to the standards established by the American Association of Textile Chemists and Colorists (AATCC). The smoothness appearance (SA) charts are plastic replicas showing a range of wrinkling from very wrinkled (SA-1) to very smooth (SA-5). &nbsp;The color and lighting in the observation room are controlled, as are the distances between the test fabric, smoothness appearance (SA) replicas, and the observer. From this test, fabrics can be given a smoothness appearance rating between one and five. However, even with the various controls, results are still somewhat subjective.</p>

<p>The wrinkle recovery test shown in this section took place in a controlled
environment at the J.C. Penney's Product Research and Technical Laboratories
using the AATCC methods previously outlined. In this example, you will observe
a video clip of a fabric being tested for wrinkle recovery. Carefully note the
degree of smoothness and amount of wrinkling that can be observed in the fabric.
For this exercise, record all observations and conclusions on the related worksheet.
Does the fabric appear to have good wrinkle resistance? Does the fabric appear
to have good resilience?</p>
<h3>Standardized Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Wrinkle Recovery </td>
  </tr>
  <tr align="center">
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-50.swf">
        <param name="quality" value="high">
        <embed src="videos/2-50.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
      </object> -->
      <video width="320" height="270" controls>
        <source src="/assets/videos/unit-2/2-50.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
      </tr>
</table>
<h3>Non-Technical Procedures </h3>
<p>As is often the case, a controlled testing environment and set of standardized smoothness appearance replicas may not be readily available. Yet, several possible methods can be used to provide an indication of the wrinkle recovery properties of fabrics. In this section two relatively simple alternative means of testing for wrinkle recovery will be shown. &nbsp;</p>
<p>First, wrinkle recovery can most simply be examined by tightly crumpling, wringing and/or twisting a fabric by hand. When the fabric is released, does the fabric retain its original character, including smoothness and shape? Ideally the tightness and length of time of the crumpling, wringing or twisting should be somewhat consistent to be able to accurately compare the wrinkle recovery of several fabrics at one time.</p>
<p>A second test can assess wrinkle recovery by folding a sample of fabric and placing a weight on the sample for a set period of time, such as five minutes. After the set time, the weight is removed and the fabric is gently unfolded without any attempt to smooth out the crease. The fabric should be observed for a set amount of time to allow it to recover. Does the fabric immediately bounce back to its original shape? Does the fabric show signs of the crease after a minute? Does the fabric still retain the crease after five minutes?&nbsp;</p>
<p>You will first observe a video clip of three fabrics as they are informally tested by wringing the fabrics. Note the process and describe your observations. Which of the three fabrics appears to have better wrinkle recovery? &nbsp;</p>
<p>You will then be shown video clips of three different fibers as they are tested for crease resistance. Record what you observe. Note the conclusions that you can draw from your observations. Which fabric appears to have the best crease resistance or wrinkle recovery? Which fabric appears to have the poorest crease resistance or wrinkle recovery?</p>
<h3>Non-Technical Tests</h3>
<table border="0" cellspacing="0" cellpadding="5">
  <tr align="center">
    <td class="byline">
        Home Wrinkle Recovery </td>
    <td class="byline">
        Home Crease Resistance </td>
  </tr>
  <tr align="center">
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-55.swf">
        <param name="quality" value="high">
        <embed src="videos/2-55.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
      </object> -->
      <video width="320" height="270" controls>
        <source src="/assets/videos/unit-2/2-55.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-56.swf">
        <param name="quality" value="high">
        <embed src="videos/2-56.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
      </object> -->
      <video width="320" height="270" controls>
        <source src="/assets/videos/unit-2/2-56.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
      </tr>
</table>
<p>&nbsp;</p>
