import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TenacityComponent } from './tenacity/tenacity.component';
import { ColorfastnessComponent } from './colorfastness/colorfastness.component';
import { DimensionalStabilityComponent } from './dimensional-stability/dimensional-stability.component';
import { AbsorbencyComponent } from './absorbency/absorbency.component';
import { ResiliencyComponent } from './resiliency/resiliency.component';
import { Unit2IntroductionComponent } from './unit2-introduction/unit2-introduction.component';
import { Unit2ConclusionComponent } from './unit2-conclusion/unit2-conclusion.component';

const routes: Routes = [
  { path: 'tenacity', component: TenacityComponent },
  { path: 'colorfastness', component: ColorfastnessComponent },
  { path: 'dimentional-stability', component: DimensionalStabilityComponent },
  { path: 'absorbency', component: AbsorbencyComponent },
  { path: 'resiliency', component: ResiliencyComponent },
  { path: 'introduction', component: Unit2IntroductionComponent },
  { path: 'conclusion', component: Unit2ConclusionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class Unit2RoutingModule {
  static components = [ TenacityComponent, ColorfastnessComponent, DimensionalStabilityComponent,
    AbsorbencyComponent, ResiliencyComponent, Unit2IntroductionComponent, Unit2ConclusionComponent ]
}
