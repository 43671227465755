<div align="right">
  <p><img src="/assets/images/unit-2/dimensional_stability.gif" alt="" height="37" width="308" align="left" border="0"><a href="u_2_4.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
The ability of a fabric to retain its size and shape through general use and
care, including suggested laundering or dry cleaning procedures, is known as
its dimensional stability. A textile with high dimensional stability will retain
its size and shape. But, a textile with poor dimensional stability will stretch
or shrink after normal use or as a result of the laundering or dry cleaning
processes. Often these processes change the size or shape of a product so drastically
that the item is deemed unsuitable, unattractive, or unfit for its intended
use.&nbsp;</p>
<p>Sometimes a particular fabric will maintain its size in one direction but change considerably in another direction. For example, a t-shirt may seem to fit around the body much as before it was laundered, but seem considerably shorter than before the laundering process. In other cases, the textile may shrink or stretch in both directions. In either case, the fabric is not considered to be dimensionally stable. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Some fabric constructions, such as knits and twill weaves, have a propensity to skew in a particular direction after laundering. Skewing is most often seen in jeans and t-shirts. Fabric skewing is commonly evident in the twisting of the side seam of jeans typically made from twill weaves. The seam starts at the side toward the top of the jeans; but after repeated laundering, the seam at the bottom may be almost in the back of the pant due to the skewing or twisting of the fabric. &nbsp;The side seams in some t-shirts also show evidence of fabric skewing as the seam twists from the top to the bottom.</p>
<p>A variety of tests can be used to measure the specific aspects of dimensional stability. The following tests will provide a general assessment of the dimensional stability of fabrics. After viewing the dimensional stability tests, you should be able to measure, calculate, compare and assess the dimensional stability of fabrics. More detailed analysis, as might be performed by a textile scientist, would be necessary to measure smaller degrees of variation in dimensional stability under more controlled circumstances.</p>
<h3>Objectives</h3>
<ol>
  <li>To observe the dimensional stability of sample fabrics
  <li>To note differences in the dimensional stability properties of various fibers and fabrics
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following term as you watch the video clips showing fabric dimensional stability.</p>
<blockquote>

<p><b><i>Dimensional Stability</i></b><b>:</b> Dimensional stability is the
ability of a fabric to retain its shape after general use and care, including
stretching and laundering. Does the length of the fabric remain relatively
the same? Does the width of the fabric remain relatively the same? Is the
dimensional stability of the fabric insignificantly or greatly affected by
the laundering process?&nbsp;</p>
</blockquote>
<h3>Standardized Procedures and Observations</h3>
<p>In the textile testing laboratory, dimensional stability of fabrics can be accurately measured after specified and controlled laundering conditions. The example shown in this section was conducted at the J.C Penney&rsquo;s Product Research and Technical Laboratories according to ASTM standards for dimensional stability. In this test, the longest possible standardized length and width measurements are permanently marked on a sample product. The sample product is then carefully laundered a prescribed number of times, according to suggested laundry instructions in highly controlled conditions. After the product has been laundered, the distance between the markings is remeasured
    <font face="Arial" size="2">
    <span style="FONT-SIZE: 10pt; FONT-FAMILY: Arial">showing a new
    lengthwise dimension of 19 inches and a new crosswise dimension of 19.5
    inches</span></font>. The percentage of dimensional change is calculated.</p>
<p>The percentage of dimensional change can be calculated using the following formula:</p>
<blockquote>
  <p><font color="#ff9900">Percent Change = (after laundering measure - before laundering measure) / before laundering measure</font></p>
</blockquote>
<p>As a result, shrinkage occurs when the calculated percent change is a negative number. Stretching or elongation occurs when the calculated percent is a positive number.</p>
<p>While observing the following video, note the dimensional stability of the fabric. Does the fabric show a reasonable level of dimensional stability? Which dimension shows the greatest percentage of dimensional stability? &nbsp;Are there any applications where the fabric used in this test would not be appropriate based on the observed dimensional stability? What other conclusions can you draw?</p>
<h3>Standardized Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Dimensional Stability</td>
  </tr>
  <tr align="center">
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-30.swf">
        <param name="quality" value="high">
        <embed src="videos/2-30.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270">
        </object> -->
        <video width="320" height="270" controls>
          <source src="/assets/videos/unit-2/2-30.mp4" type="video/mp4">
          Your browser does not support the video tag
        </video>
      </td>
      </tr>
</table>
<h3>Non-Technical Procedures </h3>
<p>When technical testing equipment is not readily available, some basic alternative tests can be used to assess the dimensional stability of fabrics. In this section, non-technical tests to measure dimensional stability will be examined.&nbsp;</p>

<p>As in the scientific test previously shown, measurements can be taken on a
fabric sample or textile product. Just as before, the longest possible length
and width measurements should be taken, preferably in more than one location.
Because this is a simplified version of the previous test, markings can be made
using stitches of thread rather than a permanent marker, which would render
the product unsuitable for most uses. Like before, the fabric should be cleaned
according to laundering instructions; however, the exact conditions of the laundering
process will be less controlled. After the fabric or product has been cleaned
and dried, the distance between the markings should be remeasured. Using the
before and after measurements, the percentage of dimensional changes should
be calculated as in the previous example.&nbsp;</p>
<p>Observe the following video clips and note the process used to measure and mark the various dimensions before and after laundering. From the measurements provided, calculate the percent change in the lengthwise dimension. Then, calculate the percent change in the width-wise direction. From these calculations, how would you rate the dimensional stability of the fabric?&nbsp;</p>
<h3>Non-Technical Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Skirt Dimensional Stability</td>
    <td class="byline">Shirt Dimensional Stability</td>
  </tr>
  <tr align="center">
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-35.swf">
        <param name="quality" value="high">
        <embed src="videos/2-35.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270"></object> -->
          <video width="320" height="270" controls>
            <source src="/assets/videos/unit-2/2-35.mp4" type="video/mp4">
            Your browser does not support the video tag
          </video>
        </td>
        <td>
          <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="320" height="270">
        <param name="movie" value="videos/2-36.swf">
        <param name="quality" value="high">
        <embed src="videos/2-36.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="320" height="270"></object> -->
          <video width="320" height="270" controls>
            <source src="/assets/videos/unit-2/2-36.mp4" type="video/mp4">
            Your browser does not support the video tag
          </video>
        </td>
      </tr>
</table>
