import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dimensional-stability',
  templateUrl: './dimensional-stability.component.html',
  styleUrls: ['./dimensional-stability.component.css']
})
export class DimensionalStabilityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
