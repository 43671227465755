<div align="right">
  <p><img src="/assets/images/unit-3/introduction.gif" alt="" height="37" width="204" align="left" border="0"><a href="u_3_1.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
What are yarns? Yarns are long strands of interlocked fibers that are used as threads, cords or components in the production of fabrics. Yarns are formed by combining staple fibers or using filament fibers in a long strand. Yarns may be classified through a variety of means:</p>
<ul>
  <li>by the length of fibers and processes used to form the yarn
  <li>by the twist in the yarn
  <li>by the size of the yarn
  <li>by the number of parts used in the development of the yarn
  <li>by the complexity of the structure of the yarn
</ul>

<p>Some variations will lead to finer yarns while others will lead to bulkier
yarns; some produce stronger yarns while others will produce weaker yarns; and
some will produce fancier yarns while others will produce more ordinary yarns.
By varying characteristics in each of the classifications, yarns take on a unique
set of qualities. For some end uses a certain set of properties is highly desirable;
yet for other end uses the same set of properties may not be as compelling.</p>

<p>Textile products can be enhanced and made more serviceable by selecting yarns
that feature a set of desirable properties. For example, a designer could create
a completely different look by using a fancy, bulky yarn or by using a fine,
lightweight, simple yarn. Likewise, the yarns selected can enhance the durability,
performance, and overall suitability of products such as uniforms and commercial
linens. </p>
<p>In this unit, examples of the various classifications of yarns are shown through illustrations and photographs. The examples shown in this unit are fairly simple and easily identifiable. Professional textile scientists often work with more complex versions of these same basic fibers to create an entire array of unique yarns to meet the aesthetic and performance characteristics needed for modern day commercial, industrial, home and personal uses.</p>
<h3>Terminology</h3>
<p>Be aware of the following terms used in this unit.</p>

<blockquote>
<p><span class="boldital">Yarn:</span> Yarns are long strands of fiber that
can be used for some functional purpose.</p>
  <p><span class="boldital">Yarn Formation: </span> Yarn formation refers to how a yarn was produced from its base fibers and whether it was developed with filament or staple fibers.</p>

<p><span class="boldital">Yarn Twist:</span> Yarn twist refers to the spiral
organization of fibers along an axis.</p>
</blockquote>
<h3>Reference to Worksheets</h3>

<p>To complete this unit, refer to the worksheet for Unit 3: Yarns to record observations.
The textbook and other reference materials may be helpful in completing this
unit.<br>
</p>
