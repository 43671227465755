import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from "@angular/flex-layout";

import { MenuListItemComponent } from './shared/menu-list-item/menu-list-item.component';

import { NavService } from './services/nav.service';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import { Unit1Module } from './pages/unit_1/unit-1.module';
import { Unit2Module } from './pages/unit_2/unit-2.module';
import { Unit3Module } from './pages/unit_3/unit-3.module';
import { Unit4Module } from './pages/unit_4/unit-4.module';
import { Unit5Module } from './pages/unit_5/unit-5.module';
import { Unit6Module } from './pages/unit_6/unit-6.module';
import { Unit7Module } from './pages/unit_7/unit-7.module';
import { Unit8Module } from './pages/unit_8/unit-8.module';
import { Unit9Module } from './pages/unit_9/unit-9.module';
import { Unit10Module } from './pages/unit_10/unit-10.module';
@NgModule({
  declarations: [
    AppComponent,
    MenuListItemComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    MaterialModule,
    Unit1Module,
    Unit2Module,
    Unit3Module,
    Unit4Module,
    Unit5Module,
    Unit6Module,
    Unit7Module,
    Unit8Module,
    Unit9Module,
    Unit10Module
  ],
  providers: [
    NavService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
