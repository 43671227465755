import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Unit6RoutingModule } from './unit-6-routing.module';


@NgModule({
  imports: [CommonModule, ReactiveFormsModule, Unit6RoutingModule],
  declarations: [Unit6RoutingModule.components]
})

export class Unit6Module { }
