<div align="right">
  <p><img src="/assets/images/unit-1/conclusion.gif" alt="Fiber Power" height="37" width="189" align="left" border="0"><a href="u_1_6.html" target="_blank">Printable Page</a></p>
</div>
<h3><br>
  Threads of History</h3>
<p>Silk was discovered in 2640 B.C. by a Chinese empress named Si-Ling-Chi. The legend has it that the empress was in her garden drinking tea under a mulberry tree when a fuzzy white cocoon accidentally fell into her tea. Once the cocoon softened, she was able to pull out long, continuous strands of a fiber now identified as silk. It is recorded that the empress experimented with raising silkworms. The silk industry began when Si-Ling-Chi taught her serving ladies the art of weaving the silk fibers into a rich, beautiful fabric.<sup>1</sup> She was named the Goddess of Silk and the Chinese people celebrated a day in her honor until 1911.<sup>7</sup>  Another Chinese legend suggests that the Emperor Fo-Hi used silk for fishing lines and strings on musical instruments.<sup>11</sup></p>
<p>The Chinese kept this discovery a secret for many years while other countries wanted to obtain silkworms and the sericulture secret. It is believed that the Japanese captured four Chinese maidens and forced them to reveal the sericulture secret.<sup>2</sup> The secret was smuggled into India when a Chinese princess carried silkworm eggs and mulberry tree seeds in the lining of her headdress.<sup>1</sup> About 550 A.D., Byzantine emperor Justinian sent two monks to China to uncover this extraordinary process. They returned to Constantinople with silkworm eggs and mulberry seeds concealed in their hollow bamboo walking sticks. From these eggs and seeds a flourishing silk industry developed in the Western World.<sup>11</sup></p>
<p>Silk is now universally known as a luxury fiber. &ldquo;Only silk is silk&rdquo; is the slogan for this unique fiber used by the International Silk Association of the United States.<sup>4</sup> Raw silk is the longest natural fiber and may be 500 to 1200 yards in length. Silk has a rich luster and is also the strongest natural fiber.<sup>11</sup> Predominantly, silk is used in apparel and home furnishings because of its very unique properties. Silk is used to create a vast array of fabrics, from sheer chiffons to heavy velvets. The medical industry also uses silk in sutures and prosthetic arteries. Today, China is the largest producer of silk, while Japan and India also play a significant part in the industry.<sup>4</sup></p>
<h3>Summary Questions</h3>
<ol>
  <li>How specific can one be in fiber identification based on a burning test?</li>

<li>How are aspects of fiber structure and burning characteristics related?</li>
  <li>What group(s) of fibers is/are most easily identified by the microscopic test?</li>
  <li>What groups of fibers can be identified based on their reaction to an acidic solution?</li>
  <li>How would one determine that a man-made fiber has been delustered? </li>
</ol>
<p>&nbsp;</p>
