import { Unit7ConclusionComponent } from './unit7-conclusion/unit7-conclusion.component';
import { Unit7IntroductionComponent } from './unit7-introduction/unit7-introduction.component';
import { PrintingComponent } from './printing/printing.component';
import { DyeingComponent } from './dyeing/dyeing.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'dyeing', component: DyeingComponent },
  { path: 'printing', component: PrintingComponent },
  { path: 'introduction', component: Unit7IntroductionComponent },
  { path: 'conclusion', component: Unit7ConclusionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class Unit7RoutingModule {
  static components = [ DyeingComponent, PrintingComponent, Unit7IntroductionComponent, Unit7ConclusionComponent ]
}
