import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit9-conclusion',
  templateUrl: './unit9-conclusion.component.html',
  styleUrls: ['./unit9-conclusion.component.css']
})
export class Unit9ConclusionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
