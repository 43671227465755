<div align="right">
  <p><img src="/assets/images/unit-6/yarn.gif" alt="" height="37" width="280" align="left" border="0"><a href="u_6_4.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Fabrics are produced from yarns through methods other than weaving or knitting.  Many of these processes have ancient origins, but variations are still used today.  Most are used for the decorative qualities that they impart.  The yarn based processes for fabric construction examined in this section include the making of lace, crochet, net, macrame and braid.  </p>
<h3>Lace</h3>
<p>Lace is an exquisitely detailed fabric. Early versions were handmade but today both handmade and machine made varieties are available. In fact, several types of both handmade and machine made lace are found in the marketplace. Traditional handmade lace appears in different styles depending on the method of lace construction and the area of origin. For example, handmade laces may be of a needlepoint, bobbin or Battenburg style. Machine made laces are of a Raschel or Leavers style. Yet, depending on the yarns, detail of the pattern and specific means of producing the lace, a seemingly endless variety of lace is possible. Lace is commonly used in lingerie, formal wear, and decorative trim. It is also used for accents on table and bed linens as well as for tablecloths and window treatments.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Lace</td>
    <td class="byline">Beaded lace</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-20.jpg" target="_blank"><img src="/assets/images/unit-6/6-20.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-6/u6large/6-21.jpg" target="_blank"><img src="/assets/images/unit-6/6-21.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><br>
      Battenburg lace</td>
    <td class="byline"><br>
      Machine made lace&#151;Leavers style</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-22.jpg" target="_blank"><img src="/assets/images/unit-6/6-22.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-6/u6large/6-23.jpg" target="_blank"><img src="/assets/images/unit-6/6-23.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Crochet</h3>
<p>Crochet replicates lace. It is constructed by pulling loops of yarn through other loops to create a lacelike, patterned fabric. Crochet materials are typically created by hand much in the same manner as knitting. The primary difference between the hand production of crochet and knits is that in crocheting a single, hooked needle is used, whereas in knitting two or more straight needles are used. Crochet is predominately used for decorative accents in home accessories such as doilies and table runners, or in apparel such as in collars and cuffs.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Handmade crochet</td>
    <td class="byline">Crochet trim</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-25.jpg" target="_blank"><img src="/assets/images/unit-6/6-25.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-6/u6large/6-26.jpg" target="_blank"><img src="/assets/images/unit-6/6-26.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Net</h3>

<p>Net is made by looping and knotting yarns in an open pattern. The pattern usually
takes on a characteristic geometric design of rectangular, square or diamond
shapes. Originally, net was made by hand but most contemporary versions are
produced on lace machines. Depending on the size of the yarn, nets can range
from very fine fabrics to heavy, coarse materials. The open pattern of the netting
makes it useful for decorative accents as well as functional purposes such as
fish nets.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Net</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-27.jpg" target="_blank"><img src="/assets/images/unit-6/6-27.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<h3>Macram&eacute;</h3>

<p>Macram&eacute; is similar to net in that it is produced using a combination
of loops and knots to form the fabric or object. However, most macram&eacute;
is produced by hand and the end result tends to be more decorative in nature.
Intricate patterns and designs are created in the cloth by manipulating the
type and location of knots. Because of the extensive number of knots and the
size of the yarn, macrame tends to be heavy in appearance. Yet, macram&eacute;
can range from very large, detailed wall hangings to relatively small but intricate
trims for home furnishings and apparel.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">

<td class="byline">Macram&eacute; knotting</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-28.jpg" target="_blank"><img src="/assets/images/unit-6/6-28.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Braid</h3>
<p>Braid is generally a narrow strip of fabric produced by lacing yarns together. The appearance takes on a uniform, repetitive, but distinctly diagonal look. Braids tend to be stretchy and can conform to a variety of shapes. Because of their flexibility, clean edges and durability, braids are frequently used domestically for trims and shoe laces and commercially for water hose and electrical wire insulation.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Braid</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-29.jpg" target="_blank"><img src="/assets/images/unit-6/6-29.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Objectives</h3>
<ol>
  <li>To observe the similarities and differences among various yarn based fabrics<li>To assess differences between lace, crochet, macrame, braid and net fabrics
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you observe the photographs showing different yarn based fabrics.</p>
<blockquote>
  <p><span class="boldital">Braid:</span>   Braid is a narrow fabric created by lacing yarns in a diagonal manner.  Does the fabric seem to bend or flex to a desired shape?  Does the fabric have uniform but obvious diagonal appearance?</p>
  <p><span class="boldital">Crochet:</span> Crochet is a lacelike fabric created by pulling loops through other loops using a single hooked needle. Does the fabric appear to have a lacey quality? Does the fabric appear to be made entirely from loops?</p>
  <p><span class="boldital">Lace: </span> Lace is an intricate fabric produced from looping and knotting, and possibly embroidering.   Does the fabric have an intricate pattern?  Does the pattern appear to be made by looping and knotting the yarns?</p>

<p><span class="boldital">Macram&eacute;:</span> Macram&eacute; is a decorative
fabric made from an intricate pattern of loops and knots. Does the fabric
appear to have a variety of decorative knots? </p>
  <p><span class="boldital">Net: </span>  Net is an open fabric created from loops and knots generally arranged in a repetitive geometric pattern.  Does the fabric appear to be made from knotted yarn?  Does the pattern seem very open and regular?  </p>
</blockquote>
<h3>Observations</h3>

<p>This exercise is intended to provide an understanding of yarn based fabrics
as well as a comparison between lace, crochet, macram&eacute;, braid and net
fabrics. You will be shown a series of photographs of different yarn based fabrics.
While viewing the fabrics note the qualities and describe the observable differences.
Classify the fabric as lace, crochet, macram&eacute;, braid or net fabric based
on your observations. Record your notes on the related worksheet.</p>
<h3> Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Yarn Based Fabric 1</td>
    <td class="byline">Yarn Based Fabric 2</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-30.jpg" target="_blank"><img src="/assets/images/unit-6/6-30.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-6/u6large/6-31.jpg" target="_blank"><img src="/assets/images/unit-6/6-31.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><br>
      Yarn Based Fabric 3</td>
    <td class="byline"><br>
      Yarn Based Fabric 4</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-32.jpg" target="_blank"><img src="/assets/images/unit-6/6-32.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-6/u6large/6-33.jpg" target="_blank"><img src="/assets/images/unit-6/6-33.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline"><br>
      Yarn Based Fabric 5</td>
    <td class="byline"><br>
      Yarn Based Fabric 6</td>
  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-6/u6large/6-34.jpg" target="_blank"><img src="/assets/images/unit-6/6-34.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-6/u6large/6-35.jpg" target="_blank"><img src="/assets/images/unit-6/6-35.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
