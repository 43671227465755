<div align="right">
  <p><img src="/assets/images/unit-4/fancy.gif" alt="" height="37" width="233" align="left" border="0"><a href="u_4_3.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Fancy weaves have an appearance distinctly different from basic, woven fabrics. However, the construction of fancy weaves is merely a variation of basic weave methods. Fancy weaves have patterns that are an integral part of the construction of the fabric. Fancy weaves are created by adding extra warp or weft yarns to the fabric structure and/or by using special looms, which add to the uniqueness and costliness of the fabric. As a result, most fancy weaves are designed for specific applications. Several variations of fancy weaves will be discussed in this unit.</p>
<h3>Dobby Weaves</h3>
<p>Dobby weaves have small, but intricate designs created using a dobby attachment to the loom. This attachment allows fabrics to be created with an allover pattern that is often geometric in style. The designs are created by adding extra yarns or by apparently raising portions of the design. The extra yarn weave, a variation of the dobby weave, has an extra yarn running in either the warp or filling direction that is used to create a design. This allows the fabric to have designs such as dots added to the base fabric. The pique weave, another variety of the dobby weave, has apparent raised ridges created by floats on the reverse side of the fabric. Note the various types of dobby weaves in the chart.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Dobby weave construction</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-50.jpg" target="_blank"><img src="/assets/images/unit-4/4-50.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-51.jpg" target="_blank"><img src="/assets/images/unit-4/4-51.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
<table border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>DOBBY WEAVES</caption>
  <tr align="center">
    <td>Extra Yarn Weaves</td>
  </tr>
  <tr align="center">
    <td>Pique Weaves</td>
  </tr>
  <tr align="center">
    <td>Bedford cloth</td>
  </tr>
  <tr align="center">
    <td>Bird&rsquo;s eye</td>
  </tr>
  <tr align="center">
    <td>Pinwale</td>
  </tr>
  <tr align="center">
    <td>Wide wale</td>
  </tr>
  <tr align="center">
    <td>White-on-white</td>
  </tr>
</table>
<h3>Jacquard Weaves</h3>
<p>Jacquard weaves are complex fabrics produced on a jacquard loom that requires more than 25 different harness arrangements. This weave produces fabrics with pictures or intricate designs that typically have a sizable repeat.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline">Older model jacquard loom</td>
    <td class="byline">Cards indicating pattern for jacquard loom</td>
    <td class="byline">Fabric produced on a jacquard loom</td>

  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-4/u4large/4-52.jpg" target="_blank"><img src="/assets/images/unit-4/4-52.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-4/u4large/4-53.jpg" target="_blank"><img src="/assets/images/unit-4/4-53.jpg" alt="" height="188" width="250" border="0"></a></td>
                    <td><a href="/assets/images/unit-4/u4large/4-54.jpg" target="_blank"><img src="/assets/images/unit-4/4-54.jpg" alt="" height="188" width="250" border="0"></a></td>

  </tr>
  <tr align="center" valign="top">
    <td class="byline">Modern jacquard looms</td>
    <td class="byline">Pattern without repeat</td>
    <td class="byline">Pattern with repeat</td>

  </tr>
  <tr align="center" valign="top">
    <td><a href="/assets/images/unit-4/u4large/4-52a.jpg" target="_blank"><img src="/assets/images/unit-4/4-52a.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-4/u4large/4-53a.jpg" target="_blank"><img src="/assets/images/unit-4/4-53a.jpg" alt="" height="188" width="250" border="0"></a></td>
                    <td><a href="/assets/images/unit-4/u4large/4-54a.jpg" target="_blank"><img src="/assets/images/unit-4/4-54a.jpg" alt="" height="188" width="250" border="0"></a></td>


    <td></td>
  </tr>

</table>
<p>Several varieties of jacquard weaves are produced. Damask is a jacquard weave that is a fabric with designs created from satin floats on a relatively smooth background. Brocade is a three dimensional design created using different weaves for the pattern and background. Tapestry weaves are yet another version of the jacquard weave. Tapestry weaves use two or more sets of warp and two or more sets of weft yarns to create large, intricate designs. The entire piece of fabric may consist of a single picture with no repeat, or it may have a pattern with a relatively large repeat. Note the various types of jacquard weaves in the chart.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Tapestry weave construction</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-55.jpg" target="_blank"><img src="/assets/images/unit-4/4-55.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-56.jpg" target="_blank"><img src="/assets/images/unit-4/4-56.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face<br>
      <br>
    </td>
    <td class="byline">reverse<br>
      <br>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Brocade weave fabric</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-57.jpg" target="_blank"><img src="/assets/images/unit-4/4-57.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-58.jpg" target="_blank"><img src="/assets/images/unit-4/4-58.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
<table width="162" border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>JACQUARD WEAVES</caption>
  <tr align="center">
    <td>	Brocade</td>
  </tr>
  <tr align="center">
    <td>Brocatelle</td>
  </tr>
  <tr align="center">
    <td>	Damask</td>
  </tr>
  <tr align="center">
    <td>	Tapestry</td>
  </tr>
</table>
<h3>Leno Weaves</h3>

<p>The characteristic sheerness and openness of the design distinguishes leno
weaves from other fancy wovens. In leno weaves, warp yarns are paired so that
once the weft yarns have passed through the opening, the warp yarns are crossed
over each other. Doup attachments are added to looms to create the leno weave.
Note the various types of leno weaves in the chart. </p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2">Leno weave</td>
  </tr>
  <tr align="center" valign="top">
    <td colspan="2">
      <p>
        <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="250" height="330">
                    <param name="movie" value="..//assets/images/unit-4/animations/Leno%20Folder/Leno.swf">
                    <param name="quality" value="high">
                    <embed src="..//assets/images/unit-4/animations/Leno%20Folder/Leno.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="250" height="330"></embed>
          </object> -->
    </p></td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-59.jpg" target="_blank"><img src="/assets/images/unit-4/4-59.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-60.jpg" target="_blank"><img src="/assets/images/unit-4/4-60.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
<table width="162" border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>LENO WEAVES</caption>
  <tr align="center">
    <td>	Grenadine</td>
  </tr>
  <tr align="center">
    <td>	Marquisette</td>
  </tr>
</table>
<h3>Double Cloth</h3>
<p>Double cloth fabrics appear as different fabrics on the face and reverse side of the cloth. Double cloths take on their unique appearance by using multiple sets of yarns in the weaving process. True double cloths are woven such that they can be separated into two fabrics by merely removing the few yarns holding the fabrics together. Double weaves are constructed from four sets of yarns which appear on the face of the fabric in various intervals, thus creating pockets in the fabric. Double-faced fabrics are constructed with three sets of yarns: two sets in one direction and one set in the other, allowing the fabric to have two serviceable surfaces, often with varying yarns or colors. Because of the added yarns in the construction process, double weave fabrics tend to be heavier and more costly fabrics. Note the various types of double cloth in the chart.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center" valign="top">
    <td class="byline" colspan="2"><span>Double cloth</span></td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-61.jpg" target="_blank"><img src="/assets/images/unit-4/4-61.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-62.jpg" target="_blank"><img src="/assets/images/unit-4/4-62.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center" valign="top">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-63.jpg" target="_blank"><img src="/assets/images/unit-4/4-63.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td class="byline">&nbsp;</td>
  </tr>
  <tr align="center" valign="top">
    <td class="byline">edge of fabric</td>
    <td class="byline">

    </td>
  </tr>
</table>
<p></p>
<table width="162" border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>DOUBLE CLOTH</caption>
  <tr align="center">
    <td>
  Double cloth</td>
  </tr>
  <tr align="center">
    <td>
  Double weave</td>
  </tr>
  <tr align="center">
    <td>
  Matelasse</td>
  </tr>
  <tr align="center">
    <td>
  Double faced</td>
  </tr>
  <tr align="center">
    <td>
  Satin ribbon</td>
  </tr>
</table>
<h3>Pile Weaves</h3>
<p>Pile weaves have a three dimensional raised surface derived from loops. In some cases, such as in terry cloth, the loops are left uncut, while in fabrics such as velvets the loops are cut and brushed. The loops are densely formed from extra yarn used in the weaving process. If the additional yarn is in the warp direction, the fabric is known as a warp-pile, whereas if the additional yarn is in the weft direction, the fabric is known as a filling-pile. Piles may be uniform across the entire surface of the fabric, or woven in such a way as to create patterns. Piles may also have a low profile such as in pinwale corduroy or a high profile such as in plush or faux furs. Although piles can be produced through other methods, common pile weaves are noted in the chart.</p>
<p></p>

<table width="122" border="0" cellspacing="0" cellpadding="10">
<tr align="center" valign="top">
<td class="byline" colspan="2"><br>
  Uncut pile fabric</td>
</tr>
<tr align="center" valign="top">
<td colspan="2"><a href="/assets/images/unit-4/animations/Pile_Folder/pile_large.jpg" target="_blank"><img src="..//assets/images/unit-4/animations/Pile_Folder/pile.jpg" alt="" height="134" width="250" border="0"></a></td>
</tr>
<tr align="center" valign="top">
<td> <p><a href="/assets/images/unit-4/u4large/4-64.jpg" target="_blank"><img src="/assets/images/unit-4/4-64.jpg" alt="" height="188" width="250" border="0"></a></p></td>
<td> <p><a href="/assets/images/unit-4/u4large/4-65.jpg" target="_blank"><img src="/assets/images/unit-4/4-65.jpg" alt="" height="188" width="250" border="0"></a></p></td>
</tr>
<tr align="center" valign="top">
<td class="byline">face<br> <br> </td>
<td class="byline">reverse<br> <br> </td>
</tr>
<tr align="center" valign="top">
<td class="byline" colspan="2">Cut pile fabric</td>
</tr>
<tr align="center" valign="top">
<td colspan="2"><a href="/assets/images/unit-4/animations/Pile_Folder/cut_pile_large.jpg" target="_blank"><img src="..//assets/images/unit-4/animations/Pile_Folder/cut_pile.jpg" alt="" height="128" width="250" border="0"></a></td>
</tr>
<tr align="center">
<td> <p><a href="/assets/images/unit-4/u4large/4-66.jpg" target="_blank"><img src="/assets/images/unit-4/4-66.jpg" alt="" height="188" width="250" border="0"></a></p></td>
<td> <p><a href="/assets/images/unit-4/u4large/4-67.jpg" target="_blank"><img src="/assets/images/unit-4/4-67.jpg" alt="" height="188" width="250" border="0"></a></p></td>
</tr>
<tr align="center">
<td class="byline">face</td>
<td class="byline">reverse</td>
</tr>
</table>

<table width="270" border="0" cellpadding="10" cellspacing="0">
<tr align="center" valign="top">
<td width="250"> <p><a href="/assets/images/unit-4/u4large/4-68.jpg" target="_blank"><img src="/assets/images/unit-4/4-68.jpg" alt="" height="188" width="250" border="0"></a></p></td>
</tr>
<tr align="center" valign="top">
<td class="byline">edge of fabric</td>
</tr>
<tr align="center" valign="top"> </tr>
<tr align="center" valign="top">
<td>&nbsp;</td>
</tr>
</table>
<p></p>
<table border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>PILE WEAVES</caption>
  <tr align="center">
    <th>Warp Pile</th>
    <th>Filling Pile</th>
  </tr>
  <tr align="center">
    <td>Crushed velvet</td>
    <td>Corduroy</td>
  </tr>
  <tr align="center">
    <td>Panne velvet</td>
    <td>Velveteen</td>
  </tr>
  <tr align="center">
    <td>Plush</td>
    <td>&nbsp;</td>
  </tr>
  <tr align="center">
    <td>Terry cloth</td>
    <td>&nbsp;</td>
  </tr>
  <tr align="center">
    <td>Velour</td>
    <td>&nbsp;</td>
  </tr>
  <tr align="center">
    <td>Velvet</td>
    <td>&nbsp;</td>
  </tr>
</table>
<p>This segment is designed to provide a general understanding of fancy weaves in contrast to basic weaves.  It is also intended to provide a comparison of dobby, jacquard, leno, double cloth and pile weaves.  After completing this segment, you should be able to assess differences among the various fancy weaves.   </p>
<h3>Objectives</h3>
<ol>
  <li>To observe various fancy weaves

  <li>To distinguish between dobby, jacquard, leno, double cloth and pile weaves
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you view the photographs showing different fancy weaves.</p>
<blockquote>
  <p><span class="boldital">Dobby Weave:</span> Dobby weaves are fancy weaves that have small-scale designs, often geometric in shape, woven in the fabric. Does the fabric appear to have a raised ridge? Does there appear to be a small design woven into the fabric?</p>
  <p><span class="boldital">Jacquard Weave:</span> Jacquard weaves are fancy weaves that have large-scale, complex designs created on a jacquard loom.  Does the pattern have a single picture or large repeating design?  Does the pattern have a lot of detail?  </p>
  <p><span class="boldital">Leno Weave:</span>  Leno weaves are open, lacy weaves made with paired sets of warp yarns.  Do the warp yarns in the fabric appear to be grouped in pairs?  Does the fabric have an open quality?  </p>
  <p><span class="boldital">Double Cloth: </span> Double cloth is a fancy weave fabric with each side having a different appearance and made from multiple sets of yarns.  Does the fabric appear to be made of several different sets of yarns?  Does the front and back side of the fabric appear as two different fabrics?</p>
  <p><span class="boldital">Pile Weave:</span> Pile weaves have a raised surface produced from cut or uncut loops. Does the fabric have a three dimensional quality? Does the fabric have loops or what appears to be a fuzzy surface from cut loops?</p>
</blockquote>
<h3>	Observations</h3>
<p>This exercise is intended to provide an understanding of basic woven fabrics and a comparison of dobby, jacquard, leno, double cloth and pile weaves. You will be shown a series of photographs of fancy weaves. While viewing the weaves note the qualities and describe the apparent differences. Classify the weave as a dobby, jacquard, leno, double cloth or pile weave based on your observations. Record your notes on the related worksheet. Can you identify the name of the specific type of weave shown?</p>
<h3>Tests</h3>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline" colspan="2">Fancy Weave 1</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-71.jpg" target="_blank"><img src="/assets/images/unit-4/4-71.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-72.jpg" target="_blank"><img src="/assets/images/unit-4/4-72.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-73.jpg" target="_blank"><img src="/assets/images/unit-4/4-73.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td></td>
  </tr>
  <tr align="center">
    <td class="byline">edge of fabric<br>
      <br>
    </td>
    <td class="byline">

    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">Fancy Weave 2</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-74.jpg" target="_blank"><img src="/assets/images/unit-4/4-74.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-75.jpg" target="_blank"><img src="/assets/images/unit-4/4-75.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline">face<br>
      <br>
    </td>
    <td class="byline">reverse<br>
      <br>
    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">Fancy Weave 3</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-76.jpg" target="_blank"><img src="/assets/images/unit-4/4-76.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-77.jpg" target="_blank"><img src="/assets/images/unit-4/4-77.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline">face<br>
      <br>
    </td>
    <td class="byline">reverse<br>
      <br>
    </td>
  </tr>
  <tr align="center">
    <td colspan="2">
      <p>Fancy Weave 4</p>
    </td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-78.jpg" target="_blank"><img src="/assets/images/unit-4/4-78.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-79.jpg" target="_blank"><img src="/assets/images/unit-4/4-79.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-80.jpg" target="_blank"><img src="/assets/images/unit-4/4-80.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td></td>
  </tr>
  <tr align="center">
    <td class="byline">edge of fabric<br>
      <br>
    </td>
    <td class="byline">

    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">Fancy Weave 5</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-81.jpg" target="_blank"><img src="/assets/images/unit-4/4-81.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-82.jpg" target="_blank"><img src="/assets/images/unit-4/4-82.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline">face<br>
      <br>
    </td>
    <td class="byline">reverse<br>
      <br>
    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">Fancy Weave 6</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-83.jpg" target="_blank"><img src="/assets/images/unit-4/4-83.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-84.jpg" target="_blank"><img src="/assets/images/unit-4/4-84.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-85.jpg" target="_blank"><img src="/assets/images/unit-4/4-85.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td></td>
  </tr>
  <tr align="center">
    <td class="byline">edge of fabric<br>
      <br>
    </td>
    <td></td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">Fancy Weave 7</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-86.jpg" target="_blank"><img src="/assets/images/unit-4/4-86.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-4/u4large/4-87.jpg" target="_blank"><img src="/assets/images/unit-4/4-87.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
