<div align="right">
  <p><img src="/assets/images/unit-1/burn_test.gif" alt="Burn Test" height="37" width="162" align="left" border="0"><a href="u_1_3.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  The second method of fiber identification is the burn test. Fibers burn differently based on their chemical composition. The reaction of fibers as they approach the flame, as they are exposed to the flame and as they are removed from the flame reveals identifying clues. The odor and residue that result from the burning process are also keys to the specific type of fiber.</p>

<p>Cellulosic fibers such as cotton and rayon tend to ignite, burn quickly, and
then continue to burn after the flame source has been removed. They both produce
an odor similar to burnt paper and a residue that is light in weight and color.
Modified cellulosic fibers such as acetate burn in a similar manner; however,
they tend to melt before igniting. They are distinguished by a vinegar-type
odor and a black, beadlike residue.</p>

<p>Protein fibers, such as silk and wool, tend to shrink away from the flame,
burn slowly, and then extinguish when the flame source has been removed. Both
produce an odor like burning hair and a residue that consists of black beads.
&nbsp;&nbsp;&nbsp;</p>

<p>Synthetic fibers, such as nylon and polyester, curl or shrink away from the
flame, burn slowly, melt and then extinguish when removed from the flame. Both
yield a chemical odor and a hard, gray residue.</p>
    <p>After viewing the burn tests, you should be able to classify the sample fibers as either natural or manufactured. More detailed chemical analysis, as might be performed by a textile scientist, would normally be necessary to identify by name the specific type of fiber being tested.</p>
<p>You will observe video clips of seven different fibers as they burn. Record what you observe. All fibers were burned in a controlled laboratory environment according to standards similar to those used by professional textile testing laboratories.</p>
<h3>Objectives</h3>
<ol>
  <li>To observe the characteristics exhibited by different sample fibers as they burn
  <li>To classify the samples as either natural or manufactured based upon those characteristics
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you watch the video clips showing different fibers burning and observe photographs of the residue.</p>
<blockquote>
      <p><em><strong>Close to the flame:</strong></em> As the fibers approach the flame, what happens to the fiber sample? Does it curl away? Fuse away or shrink? Do the fibers do anything at all?</p>
      <p><em><strong>In the flame:</strong></em> As the fiber sample is placed in the flame, what do the fibers do? Do they burn quickly or slowly? Do they burn with melting, or is there no reaction at all?</p>
      <p><em><strong>Outside the flame:</strong></em> When the fiber sample is removed from the flame, does it continue to burn with an afterglow, self-extinguish, continue to burn, or have no reaction?</p>

<p><em><strong>Residue:</strong></em> After the flame has been extinguished,
what remains? What color is it: black, grey or tan? What is the texture and
consistency of the remains? Are they feathery, crushable, hard or brittle?</p>
    </blockquote>
    <h3>Procedures and Standards</h3>
    <p>This test is used to evaluate the burning characteristics of a bundle of fibers as they are introduced to an open flame. The data obtained are a measure of the degree of destruction of a material when exposed for mere seconds to a small, open flame.</p>
    <p>For this exercise, record all observations, results and conclusions on the related worksheet. Carefully observe and record what happens to the test fibers as they are:</p>
    <ol>
      <li>Close to the flame</li>
      <li>In the flame</li>
      <li>Outside the flame</li>
    </ol>
    <p>Note the residue and any significant characteristics that it appears to have.</p>
    <h3>Tests</h3>
<table width="122" border="1" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline"><font Size ="5">Fiber 1 </font></td>
    <td class="byline"><font Size ="5">Fiber 1 Residue</font></td>
  </tr>
  <tr align="center">
    <td>
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="450" height="400">
              <param name="movie" value="videos/nylon.swf">
              <param name="quality" value="high">
              <param name="autoPlay" value="false">
              <embed src="videos/nylon.swf" quality="high" play="false" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="500" height="450"></embed>
      </object> -->
      <video width="500" height="450" controls>
        <source src="/assets/videos/unit-1/nylon.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
    <td><img src="/assets/images/unit-1/1-20b.jpg" alt="" height="250" width="312" ></td>
  </tr>
  <tr align="center">
    <td></td>
    <td></td>
  </tr>
  <tr align="center">
    <td class="byline"><font Size ="5"><br>
      Fiber 2 </font></td>
    <td class="byline"><font Size ="5"><br>
      Fiber 2 Residue</font></td>
  </tr>
  <tr align="center">
    <td>
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="500" height="450">
              <param name="movie" value="videos/wool.swf">
              <param name="quality" value="high">
              <embed src="videos/wool.swf" quality="high" autostart="false" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="500" height="450"></embed>
      </object> -->
      <video width="500" height="450" controls>
        <source src="/assets/videos/unit-1/wool.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
    <td><p><a href="/assets/images/unit-1/u1large/wool.jpg" target="_blank"><img src="/assets/images/unit-1/wool.jpg" width="312" height="250" border="0"></a></p>
  </tr>
  <tr align="center">
    <td></td>
    <td></td>
  </tr>
  <tr align="center">
    <td class="byline"><font Size ="5"><br>Fiber 3 </font></td>
    <td class="byline"><font Size ="5"><br>Fiber 3 Residue</font></td>
  </tr>
  <tr align="center">
    <td>
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="500" height="450">
              <param name="movie" value="videos/acetate.swf">
              <param name="quality" value="high">
              <embed src="videos/acetate.swf" quality="high" autostart="false" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="500" height="450"></embed>
      </object> -->
      <video width="500" height="450" controls>
        <source src="/assets/videos/unit-1/acetate.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
    <td><p><a href="/assets/images/unit-1/u1large/acetate.jpg" target="_blank"><img src="/assets/images/unit-1/acetate.jpg" width="312" height="250" border="0"></a></p>
  </tr>
  <tr align="center">
    <td></td>
    <td></td>
  </tr>
  <tr align="center">
    <td class="byline"><font Size ="5"><br>Fiber 4</font></td>
    <td class="byline"><font Size ="5"><br>Fiber 4 Residue</font></td>
  </tr>
  <tr align="center">
    <td>
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="500" height="450">
              <param name="movie" value="videos/cotton.swf">
              <param name="quality" value="high">
              <embed src="videos/cotton.swf" quality="high" autostart="false" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="500" height="450"></embed>
      </object> -->
      <video width="500" height="450" controls>
        <source src="/assets/videos/unit-1/cotton.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
    <td><p><a href="/assets/images/unit-1/u1large/1-23b.jpg" target="_blank"><img src="/assets/images/unit-1/1-23b.jpg" width="312" height="250" border="0"></a></p>
  </tr>
  <tr align="center">
    <td></td>
    <td></td>
  </tr>
  <tr align="center">
    <td class="byline"><font Size ="5"><br>Fiber 5 </font></td>
    <td class="byline"><font Size ="5"><br>Fiber 5 Residue</font></td>
  </tr>
  <tr align="center">
    <td>
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="500" height="450">
              <param name="movie" value="videos/polyester.swf">
              <param name="quality" value="high">
              <embed src="videos/polyester.swf" quality="high" autostart="false" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="500" height="450"></embed>
      </object> -->
      <video width="500" height="450" controls>
        <source src="/assets/videos/unit-1/polyester.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
    <td><p><a href="/assets/images/unit-1/u1large/1-24b.jpg" target="_blank"><img src="/assets/images/unit-1/1-24b.jpg" width="312" height="250" border="0"></a></p>
  </tr>
  <tr align="center">
    <td></td>
    <td></td>
  </tr>
  <tr align="center">
    <td class="byline"><font Size ="5"><br>Fiber 6 </font></td>
    <td class="byline"><font Size ="5"><br>Fiber 6 Residue</font></td>
  </tr>
  <tr align="center">
    <td>
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="500" height="450">
              <param name="movie" value="videos/silk.swf">
              <param name="quality" value="high">
              <embed src="videos/silk.swf" quality="high" autostart="false" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="500" height="450"></embed>
      </object> -->
      <video width="500" height="450" controls>
        <source src="/assets/videos/unit-1/silk.mp4" type="video/mp4">
        Your browser does not support the video tag
      </video>
    </td>
    <td><p><a href="/assets/images/unit-1/u1large/silk.jpg" target="_blank"><img src="/assets/images/unit-1/silk.jpg" width="312" height="250" border="0"></a></p>
  </tr>
  <tr align="center">
    <td></td>
    <td></td>
  </tr>
  <tr align="center">
    <td class="byline"><font Size ="5"><br>Fiber 7 </font></td>
    <td class="byline"><font Size ="5"><br>Fiber 7 Residue</font></td>
  </tr>
  <tr align="center">
    <td>
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="500" height="450">
              <param name="movie" value="videos/rayon.swf">
              <param name="quality" value="high">
              <embed src="videos/rayon.swf" quality="high" autostart="false" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="500" height="450"></embed>			    </object> -->
              <video width="500" height="450" controls>
                <source src="/assets/videos/unit-1/rayon.mp4" type="video/mp4">
                Your browser does not support the video tag
              </video>
    </td>
    <td><p><a href="/assets/images/unit-1/u1large/1-26b.jpg" target="_blank"><img src="/assets/images/unit-1/1-26b.jpg" width="312" height="250" border="0"></a></p>
  </tr>
</table>

