import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resiliency',
  templateUrl: './resiliency.component.html',
  styleUrls: ['./resiliency.component.css']
})
export class ResiliencyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
