<div align="right">
  <p><img src="/assets/images/unit-8/introduction.gif" alt="" height="37" width="204" align="left" border="0"><a href="u_8_1.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
Finishes are applied to many fabrics to enhance their appearance, texture or
performance. Although finishes may be applied during different stages of the
development of the fabric, finishes are often applied after the fabric has been
produced and color has been added.</p>

<p>What are finishes? Finishes are mechanically, thermally or chemically applied
to the surface of a fiber, yarn or fabric to enhance the aesthetic, tactile
or performance characteristics of the fabric. Finishes may permanently alter
the fabric or may provide a temporary effect. </p>
<p>Finishes affect the inherent characteristics or properties of a base fabric to a greater or lesser degree. Some finishes allow fabrics to become rigid while others result in soft, supple fabrics. Some finishes allow fabrics to appear shiny and smooth while others produce a dull, textured surface. Other finishes enable fabrics to retain their shape, repel water, or resist fire. For some end uses, a certain set of properties is highly desirable; yet, for other end uses the same set of properties may be a disadvantage. For example, it is desirable for most athletic wear to absorb moisture, while it would not be advantageous for an awning, umbrella or rain gear to absorb water.</p>
<p>Textile products can be enhanced and made more serviceable by applying finishes that combine a certain set of desirable properties. Take the example of a fireman&rsquo;s uniform, where fire retardation is critical. Another example is a chef's uniform where fire retardation, water repellency and soil resistance are also important factors.</p>
<p>Many properties are easily achieved through the use of relatively basic finishes and finishing techniques. However, in specialized applications a certain set of finish properties may be achieved only after extensive research and development of specialized finishes and finishing techniques.</p>
<p>This unit focuses on the evaluation of both aesthetic and performance finishes that can be observed in the student laboratory. Variations of each of these general types of finishes will be observed. The examples shown in this unit are fairly simple versions of fabric finishes. Professional textile scientists and designers often use complex versions of these finishes to produce a specific set of desired aesthetic, tactile or performance characteristics on a particular base fabric.</p>
<h3>Terminology</h3>
<p>Be aware of the following terms used in this unit.</p>
<blockquote>
  <p><span class="boldital">Aesthetic Finishes:</span>  Aesthetic finishes are applied to change the appearance of the base fabric. </p>
  <p><span class="boldital">Performance Finishes:</span>  Performance finishes are applied to alter the functional or performance qualities of the base fabric.  </p>
</blockquote>
<h3>Reference to Worksheets</h3>

<p>To complete this unit, refer to the worksheet for Unit 8: Finishes to record
observations. The textbook and other reference materials may be helpful in completing
this unit.</p>
