import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit9-introduction',
  templateUrl: './unit9-introduction.component.html',
  styleUrls: ['./unit9-introduction.component.css']
})
export class Unit9IntroductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
