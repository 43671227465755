<div align="right">
  <p><img src="/assets/images/unit-6/conclusion.gif" alt="" height="37" width="189" align="left" border="0"><a href="u_6_7.html" target="_blank">Printable Page</a></p>
</div>
<h3><br>
  Threads of History</h3>
<p>Felting is believed to be the first method of fabricating fibers into cloth. Europe, Asia and South America are just a few places where prehistoric remains of felt have been found. True felt is made out of wool but also can be formed from cotton, spun rayon, casein and soybean, or synthetic fibers.<sup>1,2</sup> The natural crimp of the fibers holds them together when pressure is added. The fibers are then compressed to produce a web of tightly interlocked fibers called felt.</p>
<p>Another fabric similar to felt is bark cloth, also called tapa in Polynesia or kapa in Hawaii. Bark cloth is made by removing the inner bark from trees such as mulberry, fig or breadfruit. The bark is soaked in sea water for a week. The pulpy strips are laid over a stone anvil, beaten with rounded clubs, and then dried to form sheets of bark cloth. Other tools are used to give certain textures and surface finishes. Primitive people typically dyed and painted designs on tapa. They also scented tapa by sprinkling sandalwood powder or putting fragrant ferns or flowers between the sheets. The end fabric was soft, yet had many of the same characteristics of paper. Tapa pieces can be joined simply by wetting the ends and beating them together. Tapa does not drape well; therefore, it was used primarily for garments such as sarongs, loin cloths, turbans and ponchos.<sup>2</sup></p>
<p>Today non-woven fabrics are still being made, but with new technological advances. Bark cloth is primarily used in furnishings. The rough texture of the fabric adds visual interest and also lessens the visibility of soiling. Felt has many industrial uses such as insulation, soundproofing materials and padding. Felt lacks flexibility and elasticity so it is rarely used for clothing but is great for hats, slippers and clothing appliques.<sup>4</sup></p>
<h3>Summary Questions	</h3>

<ol>
<li>What applications would be most appropriate for the use of film and foam
fabrics?
<li>Why are fiber webs more commonly used than bark cloth or felt?
<li>What characteristics distinguish net, lace, crochet, macram&eacute; and
braid?
<li>What are some of the potential benefits of combining fabric construction
methods in composites?
<li>What makes fur a more controversial material than leather?<br>
</ol>
