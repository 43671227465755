<div align="right">
  <p><img src="/assets/images/unit-5/process.gif" alt="" height="37" width="248" align="left" border="0"><a href="u_5_2.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Knitting, like weaving, can be accomplished in a variety of specialized ways.  In the most general sense, knits are classified based on the direction of the knitting process.  However, each general classification can be further subdivided based on more specialized knitting techniques and resulting knits.     </p>
<p>Weft or filling knits are the most common form of knit construction. In weft knits, one or more yarns are utilized for each crosswise row of stitches, and the yarns move horizontally or across the filling or weft direction of the fabric. Weft knits are further divided by the structure that results from the use of different knitting equipment. Single knits are produced on knitting machines that use a single set of needles. Double knits are produced on knitting machines that use two sets of needles. Double knits tend to have greater dimensional stability and are more sturdy than single knits. See the chart on specific types of single and double weft knits.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline" colspan="2">
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="250" height="250">
            <param name="movie" value="..//assets/images/unit-5/animations/Weft_Folder/weft.swf">
            <param name="quality" value="high">
            <embed src="..//assets/images/unit-5/animations/Weft_Folder/weft.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="250" height="250"></embed>
      </object> -->

    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2">Weft knit&#151;Single knit jersey</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-5/u5large/5-10.jpg" target="_blank"><img src="/assets/images/unit-5/5-10.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-5/u5large/5-11.jpg" target="_blank"><img src="/assets/images/unit-5/5-11.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline"><span>face<br>
        <br>
      </span></td>
    <td class="byline">reverse<br>
      <br>
    </td>
  </tr>
  <tr align="center">
    <td colspan="2" align="center" class="byline">
      Weft knit&#151;Double knit</td>
  </tr>
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-5/u5large/5-12.jpg" target="_blank"><img src="/assets/images/unit-5/5-12.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-5/u5large/5-13.jpg" target="_blank"><img src="/assets/images/unit-5/5-13.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline">&nbsp;face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
<table border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>WEFT (FILLING) KNITS</caption>
  <tr valign="top">
    <th>Single Knits</th>
    <th>Double Knits</th>
  </tr>
  <tr valign="top">
    <td>Jersey</td>
    <td>Double jersey</td>
  </tr>
  <tr valign="top">
    <td>Jacquard jerseys</td>
    <td>Interlock</td>
  </tr>
  <tr valign="top">
    <td>Pile jerseys
      <blockquote>
        <p>Fleece<br>
          Terry cloth<br>
          Velour</p>
      </blockquote>
    </td>
    <td>Rib</td>
  </tr>
  <tr valign="top">
    <td>Weft-insertion jersey</td>
    <td>&nbsp;</td>
  </tr>
</table>

<p>Knits may also be constructed in the warp direction. In warp knits, one or
more yarns are allotted to each needle on the machine and these yarns follow
the vertical or warp direction of the fabric. The result is a fabric that has
both dimensional stability and stretch. Warp knitting is the fastest means of
producing fabric from yarns. In some knits, loops are diagonally interlocked
adding dimensional stability and preventing runs. See the chart on specific
types of warp knits.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td colspan="2" align="center">
      <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="250" height="250">
              <param name="movie" value="..//assets/images/unit-5/animations/Warp_Folder/warp.swf">
              <param name="quality" value="high">
              <embed src="..//assets/images/unit-5/animations/Warp_Folder/warp.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="250" height="250"></embed>
      </object> -->
    </td>
    </tr>
  <tr align="center">
    <td class="byline" colspan="2" align="center"><br>
      Warp knit&#151;Raschel</td>
  </tr>
  <tr align="center">
    <td align="center">
      <p><a href="/assets/images/unit-5/u5large/5-15.jpg" target="_blank"><img src="/assets/images/unit-5/5-15.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-5/u5large/5-16.jpg" target="_blank"><img src="/assets/images/unit-5/5-16.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline" align="center">face</td>
    <td class="byline">reverse</td>
  </tr>
</table>
<p></p>
<table width="134" border="1" cellspacing="0" cellpadding="5" bgcolor="white">
  <caption>WARP KNITS</caption>
  <tr align="center" valign="top">
    <td>Raschel</td>
  </tr>
  <tr align="center" valign="top">
    <td>Simplex</td>
  </tr>
  <tr align="center" valign="top">
    <td>Tricot</td>
  </tr>
</table>

<p>This section is designed to illustrate various types of knits. You will observe
photographs of different weft and warp knits in an effort to familiarize yourself
with the basic differences.</p>
<h3>Objectives</h3>
<ol>
  <li>To observe various types of knits
  <li>To note differences in weft and warp knits</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you view the photos showing different knits. </p>
<blockquote>
  <p><span class="boldital">Warp Knit:</span> Warp knits are produced on a knitting machine that loops and intertwines yarns in a vertical or lengthwise direction. If you follow a single yarn, does it run in the vertical or warp direction? Does the knit appear to have dimensional stability?</p>
  <p><span class="boldital">Weft Knit:</span> Weft knits are produced by looping and intertwining yarns in a horizontal direction. If you follow a single yarn, does it run in the horizontal or weft direction?</p>
</blockquote>
<h3>Observations</h3>
<p>The observations in this section are intended to illustrate examples of weft and warp knitting. You will first be shown a photograph of the face and reverse of a knit and provided an explanation as to how it might be classified. Then, you will be shown a series of photographs of various knits. While viewing the photographs, note the knit pattern and any observable differences. Record all observations, results and conclusions on the related worksheet. Determine the basic type knit shown in each photo and identify the specific variation. Note the differences in the knits shown. Which examples are weft knits? Which examples are warp knits? If the photo represents a weft knit, what type of weft knit is it? Can you determine the specific type of knit shown?</p>
<h3>Sample Test</h3>
<p>
  <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="80" height="40">
        <param name="movie" value="c5st.swf">
        <param name="quality" value="high">
        <embed src="c5st.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="80" height="40"></embed>
    </object> -->
    <video width="500" height="50" controls>
      <source src="/assets/videos/unit-5/c5st.mp4" type="video/mp4">
      Your browser does not support the video tag
    </video>
</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td>
      <p><a href="/assets/images/unit-5/u5large/5-17.jpg" target="_blank"><img src="/assets/images/unit-5/5-17.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td>
      <p><a href="/assets/images/unit-5/u5large/5-18.jpg" target="_blank"><img src="/assets/images/unit-5/5-18.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline"><span>face</span></td>
    <td class="byline"><span>reverse</span></td>
  </tr>
</table>
<h3>Tests</h3>
<table width="52" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline" colspan="2">Knit 1</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-19.jpg" target="_blank"><img src="/assets/images/unit-5/5-19.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/5-20.jpg" target="_blank"><img src="/assets/images/unit-5/5-20.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><span>face</span></td>
    <td class="byline"><span>reverse</span></td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Knit 2</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-21.jpg" target="_blank"><img src="/assets/images/unit-5/5-21.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/5-22.jpg" target="_blank"><img src="/assets/images/unit-5/5-22.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><span>face</span></td>
    <td class="byline"><span>reverse</span></td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-23.jpg" target="_blank"><img src="/assets/images/unit-5/5-23.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td class="byline">edge of fabric<span><br>
      </span></td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Knit 3</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-24.jpg" target="_blank"><img src="/assets/images/unit-5/5-24.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>
      <p><a href="/assets/images/unit-5/u5large/5-25.jpg" target="_blank"><img src="/assets/images/unit-5/5-25.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline"><span>face</span></td>
    <td class="byline"><span>reverse</span></td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-26.jpg" target="_blank"><img src="/assets/images/unit-5/5-26.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td class="byline">edge of fabric</td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Knit 4</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-27.jpg" target="_blank"><img src="/assets/images/unit-5/5-27.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>
      <p><a href="/assets/images/unit-5/u5large/5-28.jpg" target="_blank"><img src="/assets/images/unit-5/5-28.jpg" alt="" height="188" width="250" border="0"></a>
      </p>
    </td>
  </tr>
  <tr align="center">
    <td class="byline"><span>face</span></td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2"><br>
      Knit 5</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-29.jpg" target="_blank"><img src="/assets/images/unit-5/5-29.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/5-30.jpg" target="_blank"><img src="/assets/images/unit-5/5-30.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline">face</td>
    <td class="byline">reverse</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-31.jpg" target="_blank"><img src="/assets/images/unit-5/5-31.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td class="byline">edge of fabric</td>
    <td>

    </td>
  </tr>
</table>
