<div align="right">
  <p><img src="/assets/images/unit-6/introduction.gif" alt="" height="37" width="204" align="left" border="0"><a href="u_6_1.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Although the vast majority of fabrics are produced by weaving or knitting, as observed in the previous units, other methods may be used to develop specialized fabrics.  This unit will focus on the other means of fabric construction.   </p>
<p>The methods of fabric construction discussed in this unit have been organized by the base materials that are used in the development of the fabric. Some fabrics are made before the synthetic polymer is developed into a fiber. Some fabrics are created directly from fibers, while others are made after yarns have been formed. Some fabrics are composites created from existing fabrics. In addition, the development of leathers and furs is a process with unique characteristics.</p>
<p>This unit focuses on the various means of producing fabric other than through weaving or knitting. Examples will be shown through photographs. The examples in this unit are simple and easily identifiable. Yet, professional textile scientists and designers may work with more complex versions of these same basic types of fabric construction to create unique fabrics to meet a variety of aesthetic and performance criteria for a wide array of applications.</p>
<h3>Terminology</h3>
<p>Be aware of the following terms used in this unit.</p>

<blockquote>
<p><span class="boldital">Animal Hides:</span> Animal hides and skins are processed
into leather, suede or fur for specialized uses.</p>
<p><span class="boldital">Composites: </span> Composite fabrics are produced
by combining two or more methods of fabric construction.</p>
<p><span class="boldital">Fiber Based Fabrics:</span> Fabrics produced by bonding
and compressing fibers. </p>
<p><span class="boldital">Solution Based Fabric:</span> Fabrics constructed
directly from a textile polymer or solution. </p>
<p><span class="boldital">Yarn Based Fabrics:</span> Fabrics developed by looping,
intertwining and knotting yarns. </p>
</blockquote>
<h3>Reference to Worksheets</h3>

<p>To complete this unit, refer to the worksheet for Unit 6: Other Fabric Construction
to record observations. The textbook and other reference materials may be helpful
in completing this unit.</p>
