import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yarn-based-fabrics',
  templateUrl: './yarn-based-fabrics.component.html',
  styleUrls: ['./yarn-based-fabrics.component.css']
})
export class YarnBasedFabricsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
