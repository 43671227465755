import { Unit10ConclusionComponent } from './unit10-conclusion/unit10-conclusion.component';
import { Unit10IntroductionComponent } from './unit10-introduction/unit10-introduction.component';
import { VoluntaryStandardsComponent } from './voluntary-standards/voluntary-standards.component';
import { InvoluntaryRegulationsComponent } from './involuntary-regulations/involuntary-regulations.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'involuntary-regulations', component: InvoluntaryRegulationsComponent },
  { path: 'voluntary-standards', component: VoluntaryStandardsComponent },
  { path: 'introduction', component: Unit10IntroductionComponent },
  { path: 'conclusion', component: Unit10ConclusionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class Unit10RoutingModule {
  static components = [ InvoluntaryRegulationsComponent, VoluntaryStandardsComponent, Unit10IntroductionComponent,
      Unit10ConclusionComponent ]
}
