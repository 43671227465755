import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Unit9RoutingModule } from './unit-9-routing.module';


@NgModule({
  imports: [CommonModule, ReactiveFormsModule, Unit9RoutingModule],
  declarations: [Unit9RoutingModule.components]
})

export class Unit9Module { }
