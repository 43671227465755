<div align="right">
  <p><img src="/assets/images/unit-5/knit.gif" alt="" height="37" width="196" align="left" border="0"><a href="u_5_3.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Knitting uses variations of a few basic knit stitches to create the knitted product.  By varying the type and combination of knit stitches, knits can go from smooth and regular to having a myriad of textures and patterns.   Although knitting appears rather complex, the process revolves around the use and adaptation of only a few basic stitches.  The most basic stitches are used in the development of single weft knits.  Single weft knits use four basic knit stitches:  knit, purl, tuck, and float or miss.</p>
<p>The basic knit stitch leaves a very smooth surface on the face of the knit. Purl stitches are essentially the reverse of the knit stitch. The purl stitch ordinarily leaves a regular but more textured surface on the face of the knit. The knit and purl stitches help to provide structure as well as interest in the knit product. Other stitches are used predominately to create patterns in the knit. The tuck stitch creates an opening or apparent change in the texture of the surface of the knit. The tuck stitch is achieved in the knitting process when one knit stitch is not dropped before another is picked up. Floats are also commonly used for effect in a knit. Floats allow certain yarns such as colored yarns to be hidden in portions of the knit and later revealed in other areas. By controlling when the float yarn can be seen on the surface, complex decorative patterns can be created.</p>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td class="byline">Knit Stitch</td>
    <td class="byline">Purl Stitch</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/Plain_large.jpg" target="_blank"><img src="/assets/images/unit-5/Plain.jpg" alt="" height="250" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/Purl_large.jpg" target="_blank"><img src="/assets/images/unit-5/Purl.jpg" alt="" height="250" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Tuck Stitch</td>
    <td class="byline"><br>
      Float Stitch</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/Tuck_large.jpg" target="_blank"><img src="/assets/images/unit-5/Tuck.jpg" alt="" height="250" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/Float_large.jpg" target="_blank"><img src="/assets/images/unit-5/Float.jpg" alt="" height="250" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline"><br>
      Knit stitch</td>
    <td class="byline"><br>
      Purl stitch</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-50.jpg" target="_blank"><img src="/assets/images/unit-5/5-50.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/5-51.jpg" target="_blank"><img src="/assets/images/unit-5/5-51.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td colspan="2" align="center" class="byline"><br>
      Float stitch</td>
  </tr>
  <tr align="center">
    <td><a href="/assets/images/unit-5/u5large/5-52a.jpg" target="_blank"><img src="/assets/images/unit-5/5-52a.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/5-52b.jpg" target="_blank"><img src="/assets/images/unit-5/5-52b.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td align="center" class="byline">

        float</td>
    <td class="byline">

        reverse</td>
  </tr>
  <tr align="center">
    <td class="byline" align="center"><br>
      Tuck stitch</td>
    <td align="center">

    </td>
  </tr>
  <tr align="center">
    <td align="center"><a href="/assets/images/unit-5/u5large/5-53.jpg" target="_blank"><img src="/assets/images/unit-5/5-53.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td align="center">

    </td>
  </tr>
</table>
<p>This segment is designed to provide a comparison of the various types of knit stitches. After completing this segment, you should be able to assess basic differences between various knit stitches and be able to identify some of the distinct variations of weft and warp knits.</p>
<h3>Objectives</h3>
<ol>
  <li>To observe basic knit stitches
  <li>To assess differences among the various knit stitches
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you view the photographs showing different knit stitches.</p>
<blockquote>

<p><span class="boldital">Float: </span>A float stitch is a stitch that is passed
over or behind another stitch, as if it were missed in the knitting process.
Does the stitch appear to have been skipped or missed in the knitting process?
Does the stitch float or span over another knit stitch?</p>
  <p><span class="boldital">Knit:</span> Knit stitches are the basic, smooth surfaced
stitches used to form a knit. Does the surface appear very smooth and regular?
</p>
<p><span class="boldital">Purl:</span> Purl stitches are the reverse of knit
stitches, and create a regular but textured surface on the face of a knit.
Does the surface appear regular but textured? Does the face of the knit appear
similar to the back of a basic knit?</p>
  <p><span class="boldital">Tuck:</span> A tuck stitch is a knit stitch that has not been dropped before knitting the subsequent stitch. Does the stitch appear to be intertwined with another stitch? Does the knit appear to have an opening on the surface?</p>
</blockquote>
<h3>Observations</h3>
<p>This exercise is designed to illustrate the basic knit stitches. You will be shown a series of photographs of knits depicting examples of different stitches.  While viewing the photographs describe the appearance of the stitches.   Classify the stitches as basic knit stitches, purl stitches, float stitches or tuck stitches.   Record your notes on the related worksheet.  Are the stitches basic knit stitches?   Are the stitches purl stitches?  Are they tuck stitches?  Does the yarn float across other stitches?  How were you able to distinguish between the various stitches? </p>
<h3>Tests</h3>
<table width="122" border="0" cellspacing="0" cellpadding="10">
  <tr align="center">
    <td align="center" class="byline">Knit Stitch 1</td>
    <td class="byline">Knit Stitch 2</td>
  </tr>
  <tr align="center">
    <td align="center"><a href="/assets/images/unit-5/u5large/5-54.JPG" target="_blank"><img src="/assets/images/unit-5/5-54.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/5-55.jpg" target="_blank"><img src="/assets/images/unit-5/5-55.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline" align="center"><br>Knit Stitch 3</td>
    <td class="byline"></td>
  </tr>
  <tr align="center">
    <td align="center"><a href="/assets/images/unit-5/u5large/5-57.jpg" target="_blank"><img src="/assets/images/unit-5/5-57.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td>

    </td>
  </tr>
  <tr align="center">
    <td class="byline" colspan="2" align="center"><br>Knit Stitch 4</td>
  </tr>
  <tr align="center">
    <td align="center"><a href="/assets/images/unit-5/u5large/5-58.jpg" target="_blank"><img src="/assets/images/unit-5/5-58.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td><a href="/assets/images/unit-5/u5large/5-59.jpg" target="_blank"><img src="/assets/images/unit-5/5-59.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr align="center">
    <td class="byline" align="center">

        face</td>
    <td class="byline">

        reverse</td>
  </tr>
</table>
<p></p>
