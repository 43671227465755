<div align="right">
  <p><img src="/assets/images/unit-3/filament.gif" alt="" height="37" width="357" align="left" border="0"><a href="u_3_2.html" target="_blank">Printable Page</a></p>
</div>
<p><br>
  <br>
  Yarns are produced from fibers that are filament or staple. Filament yarns are comprised of a single continuous fiber or group of continuous fibers that are typically twisted to hold them together as a single yarn. With the exception of silk, most filament yarns are derived from manufactured fibers. It should be noted that filament yarns make up only a very small portion of the yarns available in the marketplace. Most yarns are made from staple fibers through a process known as spinning.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr valign="top">
    <td class="byline" align="center">		Filament yarn of synthetic fibers</td>

<td class="byline" align="center"> Simple example of nylon being drawn into
  a fiber/yarn</td>
  </tr>
  <tr valign="top">
    <td align="center">
      <p class="byline"><a href="/assets/images/unit-3/u3large/3-1.jpg" target="_blank"><img src="/assets/images/unit-3/3-1.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td align="center">
      <p class="byline"><a href="/assets/images/unit-3/u3large/3-1b.jpg" target="_blank"><img src="/assets/images/unit-3/3-1b.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr valign="top">
    <td class="byline" align="center"><br>
          Silk filament yarn </td>
    <td class="byline" align="center"><br>
      Spun yarn</td>
  </tr>
  <tr valign="top">
    <td align="center">
      <p class="byline"><a href="/assets/images/unit-3/u3large/3-2.jpg" target="_blank"><img src="/assets/images/unit-3/3-2.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td align="center">
      <p class="byline"><a href="/assets/images/unit-3/u3large/3-3.jpg" target="_blank"><img src="/assets/images/unit-3/3-3.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>

<p>The yarn spinning process turns a single type of fiber or group of various
fibers into a continuous strand by twisting and pulling the fibers into an elongated
shape. The resultant strand is known as a spun yarn. In preparation for the
spinning process, short fibers are carded. Carding directs textile fibers into
relatively one direction to create a loosely assembled but somewhat uniform
rope known as a sliver. Slivers of different fibers may be twisted together
to form a blended strand in a process called roving. Strands will continue to
be drawn into tighter and narrower strands through the roving process, which
continues to redirect the fibers toward a single direction. Ultimately, the
strand will be twisted and drawn to its final stage as a spun yarn through the
spinning process. In instances where the staple fibers are longer, the fibers
may also undergo a process known as combing. Combing takes place after carding
and is intended to further align the fibers. By further aligning the fibers
and removing many of the shorter fibers, the finished yarn becomes smoother,
more uniform in diameter and stronger. Through the modern technique of open
ended spinning or rotor spinning, yarns are rapidly produced in a rotor by skipping
the roving phase and spinning yarn directly from the sliver.</p>
<table width="540" border="0" cellspacing="0" cellpadding="10">
  <tr valign="top">
    <td class="byline" align="center">
      Sliver</td>
    <td class="byline" align="center">		Yarn after roving</td>
  </tr>
  <tr valign="top">
    <td align="center">
      <p class="byline"><a href="/assets/images/unit-3/u3large/3-4.jpg" target="_blank"><img src="/assets/images/unit-3/3-4.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td align="center">
      <p class="byline"><a href="/assets/images/unit-3/u3large/3-5.jpg" target="_blank"><img src="/assets/images/unit-3/3-5.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr valign="top">
    <td class="byline" align="center"><br>
          Yarn after combing</td>
    <td class="byline" align="center"><br>
          Finished yarn</td>
  </tr>
  <tr valign="top">
    <td align="center">
      <p class="byline"><a href="/assets/images/unit-3/u3large/3-6.jpg" target="_blank"><img src="/assets/images/unit-3/3-6.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td align="center">
      <p class="byline"><a href="/assets/images/unit-3/u3large/3-7.jpg" target="_blank"><img src="/assets/images/unit-3/3-7.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
  <tr valign="top">
    <td class="byline" colspan="2" align="center"><br>
      Commercial spinning equipment</td>
  </tr>
  <tr valign="top">
    <td align="center"><a href="/assets/images/unit-3/u3large/3-7b.jpg" target="_blank"><img src="/assets/images/unit-3/3-7b.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td align="center"><a href="/assets/images/unit-3/u3large/3-7c.jpg" target="_blank"><img src="/assets/images/unit-3/3-7c.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>

<p>If the spun yarn is comprised of wool fibers or wool blends, specific terms
are used to refer to carded or combed yarns. Yarns of shorter wool fibers that
have been carded are called woolen yarns while yarns of longer wool fibers that
have been combed are termed worsted yarns.</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr valign="top">
    <td class="byline" align="center">Woolen yarn</td>
    <td class="byline" align="center">Worsted yarn</td>
  </tr>
  <tr valign="top">
    <td align="center">
      <p class="byline"><a href="/assets/images/unit-3/u3large/3-8.jpg" target="_blank"><img src="/assets/images/unit-3/3-8.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
    <td align="center">
      <p class="byline"><a href="/assets/images/unit-3/u3large/3-9.jpg" target="_blank"><img src="/assets/images/unit-3/3-9.jpg" alt="" height="188" width="250" border="0"></a></p>
    </td>
  </tr>
</table>
<p>Characteristics of carded yarns include:</p>
<ul>
  <li>Surface is fuzzier and has more fiber ends
  <li>Diameter of yarn is somewhat irregular
  <li>Twist is medium to low
  <li> Yarn is usually larger in diameter and weaker
  <li>Yarns are composed of varying fiber lengths</ul>
<p>Characteristics of combed yarns include:</p>
<ul>
  <li>Surface is smoother and has fewer fiber ends
  <li>Diameter of yarn is more regular and even<li> Twist may be medium to high
  <li>Yarn is usually finer and stronger
  <li>Yarns are composed of more uniform and longer length fibers</ul>
<p>This segment is designed to provide a general comparison of filament and spun    yarns as well as carded and combed yarns.   After completing this segment, you should be able to assess the basic differences in the formation of the sample yarns.  </p>
<h3>Objectives</h3>
<ol>
  <li>To observe filament yarns<li>To observe spun yarns
  <li>To note differences between filament and spun yarns
  <li>To observe carded yarns
  <li>To observe combed yarns
  <li>To note differences between carded and combed yarns
</ol>
<h3>Laboratory Terminology</h3>
<p>Be aware of the following terms as you view the photographs of the different yarns.</p>

<blockquote>
<p><span class="boldital">Carded Yarn: </span>Carded yarns are spun yarns where
short staple fibers have been processed by carding or loosely aligning the
fibers. Does the yarn consist of short staple fibers? Does the surface of
the yarn appear fuzzy and irregular?</p>
<p><span class="boldital">Combed Yarn:</span> Combed yarns are spun yarns that
have undergone an additional process to reduce the amount of short staple
fibers and align the remaining longer staple fibers. Does the yarn consist
of long staple fibers? Does the surface of the yarn appear smooth and uniform?</p>
<p><span class="boldital">Filament Yarn:</span> Filament yarns are produced
with a single filament fiber or combination of filament fibers. Does the yarn
consist of a single or multiple continuous strand of filament fiber?</p>
  <p><span class="boldital">Spun Yarn:</span> Spun yarns are formed by twisting and pulling short and/or long staple fibers into a continuous strand through a process known as spinning. Does the yarn consist of staple fibers?</p>

</blockquote>
<h3>Observations</h3>
<p>This exercise is designed to illustrate the difference between filament and spun yarns as well as carded and combed yarns. You will first be shown a photograph of a yarn and provided an explanation as to why it is classified as such. You will then observe photos of a variety of different yarns. While viewing the yarns describe the surface. Classify the yarn as either filament or spun. If spun, determine if the yarn is a carded or combed yarn based on your description. Record your notes on the related worksheet. Which of the yarns are filament? Which of the yarns are spun? Which of the spun yarns are the carded yarns? Which of the yarns are the combed yarns? How were you able to distinguish between the various examples?</p>
<h3>Sample Test</h3>
<p>
  <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0" width="80" height="40">
        <param name="movie" value="c3st.swf">
        <param name="quality" value="high">
        <embed src="c3st.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="80" height="40"></embed>
    </object> -->
    <video width="320" height="270" controls>
      <source src="/assets/videos/unit-3/c3st.mp4" type="video/mp4">
      Your browser does not support the video tag
    </video>
</p>
<table border="0" cellspacing="0" cellpadding="10">
  <tr valign="top">
    <td align="center"><a href="/assets/images/unit-3/u3large/3-10.jpg" target="_blank"><img src="/assets/images/unit-3/3-10.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
<h3>Tests</h3>
<table border="0" cellspacing="0" cellpadding="10">
  <tr valign="top">
    <td class="byline" align="center">Yarn 1</td>
    <td class="byline" align="center">Yarn 2</td>
  </tr>
  <tr valign="top">
    <td align="center"><a href="/assets/images/unit-3/u3large/3-11.jpg" target="_blank"><img src="/assets/images/unit-3/3-11.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td align="center"><a href="/assets/images/unit-3/u3large/3-12.jpg" target="_blank"><img src="/assets/images/unit-3/3-12.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
  <tr valign="top">
    <td class="byline" align="center"><br>Yarns 3</td>
    <td class="byline" align="center"><br>Yarns 4</td>
  </tr>
  <tr valign="top">
    <td align="center"><a href="/assets/images/unit-3/u3large/3-13.jpg" target="_blank"><img src="/assets/images/unit-3/3-13.jpg" alt="" height="188" width="250" border="0"></a></td>
    <td align="center"><a href="/assets/images/unit-3/u3large/3-14.jpg" target="_blank"><img src="/assets/images/unit-3/3-14.jpg" alt="" height="188" width="250" border="0"></a></td>
  </tr>
</table>
